import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';

export const CourseClassroomFormValidation = (T: Translator) => Yup.object().shape({
  classroom_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  classroom_type: Yup.string().required(T.t('gen_cannot_leave_empty')),
  building_id: Yup.number().required(T.t('gen_cannot_leave_empty')),
  door_order: Yup.number().required(T.t('gen_cannot_leave_empty')),
  building_floor: Yup.string().required(T.t('gen_cannot_leave_empty')),
  lecture_capacity: Yup.number().required(T.t('gen_cannot_leave_empty')),
  description: Yup.string().max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
});
