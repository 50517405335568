import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ActivityFormInitialValues,
  EducationTypeSelectOptions,
  ActivityTermLectureLocations,
  CourseTypes,
  ActivityTypes,
  ActivityTypesLabel,
  CourseTypesLabel
} from '../../store/constants/activity-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { CopyCourseFormVal } from './validations/copy-course-form-val';
import MultiSelectionTable from './multi-selection-table';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const equal = require('deep-equal');
const Logger = Log.create('CopyCourseModal');

let sectionFormatValidation: boolean = false;
let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;

let faculty_ids_selected: Array<any> = [];
let program_ids_selected: Array<any> = [];
let section_ids_selected: Array<any> = [];
let faculties_selected: Array<any> = [];
let programs_selected: Array<any> = [];
let sections_selected: Array<any> = [];

let faculty_ids_labels: Array<any> = [];
let faculties_labels: Array<any> = [];
let program_ids_labels: Array<any> = [];
let programs_labels: Array<any> = [];
let section_ids_labels: Array<any> = [];
let sections_labels: Array<any> = [];

class CopyCourseModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1,
      practical_staff: [],
      practical_staff_ids: undefined,
      lecture_staff: [],
      lecture_staff_ids: undefined
    },
    isAdded: false,
    alreadyInTheList: false,
    model: Object.assign({}, ActivityFormInitialValues),
    week: ''
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    faculty_ids_labels.splice(0, faculty_ids_labels.length);
    faculties_labels.splice(0, faculties_labels.length);
    program_ids_labels.splice(0, program_ids_labels.length);
    programs_labels.splice(0, programs_labels.length);
    section_ids_labels.splice(0, section_ids_labels.length);
    sections_labels.splice(0, sections_labels.length);

    faculty_ids_selected.splice(0, faculty_ids_selected.length);
    program_ids_selected.splice(0, program_ids_selected.length);
    section_ids_selected.splice(0, section_ids_selected.length);
    faculties_selected.splice(0, faculties_selected.length);
    programs_selected.splice(0, programs_selected.length);
    sections_selected.splice(0, sections_selected.length);
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    faculty_ids_labels.length == 0 && plusButtonClicked == false ? faculty_ids_labels = values.faculty_ids : faculty_ids_labels = faculty_ids_labels;
    faculty_ids_labels = values.faculty_id != undefined && plusButtonClicked ? faculty_ids_labels.concat(values.faculty_id) : faculty_ids_labels;
    faculties_labels = values.faculty_id != undefined && plusButtonClicked ? faculties_labels.concat(values.faculties) : values.faculties;

    program_ids_labels.length == 0 && plusButtonClicked == false ? program_ids_labels = values.program_ids : program_ids_labels = program_ids_labels;
    program_ids_labels = values.program_id != undefined && plusButtonClicked ? program_ids_labels.concat(values.program_id) : program_ids_labels;
    programs_labels = values.program_id != undefined && plusButtonClicked ? programs_labels.concat(values.programs) : values.programs.map((item: any) => ({
      label:
        item.label.split(')')[0] + ") - " + (item.label.split('-')[1].trim() == EducationTypeSelectOptions(T)[0].value ?
          EducationTypeSelectOptions(T)[0].label :
          item.label.split('-')[1].trim() === EducationTypeSelectOptions(T)[1].value ?
            EducationTypeSelectOptions(T)[1].label :
            item.label.split('-')[1].trim() === EducationTypeSelectOptions(T)[2].value ?
              EducationTypeSelectOptions(T)[2].label : item.label.split('-')[1].trim()) + ' - ' + item.label.split('-')[2]
      , value: item.value
    }));

    if ((section_ids_labels.length == 0 || sections_labels.length == 0) && plusButtonClicked == false) {
      section_ids_labels = values.sections;
      sections_labels = values.sections;
    }

    if (values.section != undefined && values.section != '' && plusButtonClicked) {
      section_ids_labels = section_ids_labels.concat(values.section);
      sections_labels = sections_labels.concat(values.section);
    } else if ((values.section == undefined || values.section == '') && plusButtonClicked) {
      section_ids_labels = section_ids_labels.concat('1');
      sections_labels = sections_labels.concat('1');
      values.section = '1'
    }

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = false;
  };

  multiSelecteds(
    faculty_ids: any, program_ids: any, faculties: any, programs: any, sections: any
  ) {
    multiSelectedRow = true;
    faculty_ids_selected = faculty_ids;
    program_ids_selected = program_ids;
    faculties_selected = faculties;
    programs_selected = programs;
    sections_selected = sections;

    faculty_ids_labels = faculty_ids;
    faculties_labels = faculties;
    program_ids_labels = program_ids;
    programs_labels = programs;
    sections_labels = sections;
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: Types.ICopyActivityFormValues, FormActions: FormikActions<Types.ICopyActivityFormValues>) => {
    if (saveButtonClicked) {
      let newModel;
      let activity_programs: any;

      activity_programs = faculty_ids_selected.map((faculty_id: any, index: any) => (
        {
          faculty_id: faculty_id,
          program_id: program_ids_selected[index],
          section: sections_selected[index]
        }));
      if (activity_programs == undefined || activity_programs == [] || activity_programs.length == 0) {
        this.props.dispatch(Actions.Notification(T.t('gen_list_isnot_left_blank'), 'gen_warning', 'danger'))
      } else {
        if (multiSelectedRow) {
          newModel = {
            activity_no: this.props.course[0].item.activityInfo.activity_no,
            activity_type: model.activity_type,
            class: model.class,
            classes: model.classes,
            course_code: model.course_code,
            course_name: model.course_name,
            course_term_status: model.course_term_status,
            course_type: model.course_type,
            education_type: model.education_type,
            faculties: faculties_selected,
            faculty_ids: activity_programs.map((i: any) => i.faculty_id),
            instructor_ids: model.instructor_ids === null ? model.lecture_staff_ids : model.instructor_ids,
            instructors: model.instructors === null ? model.instructors : model.instructors,
            lecture_location: model.lecture_location,
            lecture_location_id: model.lecture_location_id,
            lecture_locations: model.lecture_locations,
            lesson_count: model.lesson_count,
            program_ids: activity_programs.map((i: any) => i.program_id),
            programs: programs_selected,
            section: model.section,
            sections: activity_programs.map((i: any) => i.section),
            status: model.status,
            student_count: model.student_count,
            term: this.props.course[0].item.activityInfo.term,
            week: model.week,
            description: model.description,
          }
          multiSelectedRow = false;
        }

        let courseInfo: any = {};

        let courseArray: { id: number; item: Types.IExamPeriod_ActivityItem } = this.props.course[0];
        if (courseArray && courseArray.item && courseArray.item.activityInfo) {
          courseInfo = { ...newModel, term_id: this.state.term_id };
        }
        if (courseInfo && Object.keys(courseInfo).length) {
          courseInfo = Object.keys(courseInfo).reduce((object: any, key: string) => {
            if (key !== 'course_id') {
              object[key] = courseInfo[key];
            }
            return object;
          }, {});
        }

        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_activity_copy'),
            body: T.t('gen_copy_activity_question'),
            name: 'examPeriod_courses_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (status: number) => {
                if (status === 200 || status === 201) {
                  this.setCloseModal();
                  this.onUpdateListFromModal();
                }
              };

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_COURSETERM_COPY_COURSE_TO_PERIOD,
                  courseInfo,
                  'ep-copy-course',
                  resultCallback
                )
              );
            }
          })
        );
        saveButtonClicked = false;
        FormActions.setSubmitting(false);
      }
    }
  };

  static getDerivedStateFromProps(props: any, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  createFieldValues = (): Types.ICopyActivityFormValues => {
    let fields: Types.ICopyActivityFormValues = {
      course_code: '',
      course_name: '',
      student_count: -1,
      activity_type: '',
      instructors: [],
      instructor_ids: [],
      practical_staff_ids: [],
      practical_staff: [],
      faculty_ids: [],
      faculties: [],
      program_ids: [],
      programs: [],
      lecture_location_id: -1,
      week: ''
    };

    if (this.props.course && this.props.course.length) {
      let courseArray: { id: number; item: Types.IExamPeriod_ActivityItem } = this.props.course[0];

      if (courseArray && courseArray.item && courseArray.item.activityInfo) {
        let activityInfo = courseArray.item.activityInfo;
        let programs = []
        if (activityInfo.programs) {
          programs.push(activityInfo.programs)
        }

        fields = {
          course_code: activityInfo.course_code ? activityInfo.course_code : '',
          course_name: activityInfo.course_name ? activityInfo.course_name : '',
          week: activityInfo.week,
          sections: activityInfo.sections ? activityInfo.sections : [],
          section: activityInfo.section ? activityInfo.section : '',
          student_count: activityInfo.student_count ? activityInfo.student_count : 0,
          activity_type: activityInfo.activity_type ? activityInfo.activity_type : '',
          instructors: activityInfo.instructors ? activityInfo.instructors : [],
          instructor_ids: activityInfo.instructor_ids ? activityInfo.instructor_ids : [],
          practical_staff: activityInfo.practical_staff ? activityInfo.practical_staff : [],
          practical_staff_ids: activityInfo.practical_staff_ids ? activityInfo.practical_staff_ids : [],
          programs: activityInfo.programs ? activityInfo.programs : [],
          program_ids: activityInfo.program_ids ? activityInfo.program_ids : [],
          faculties: activityInfo.faculties ? activityInfo.faculties : [],
          faculty_ids: activityInfo.faculty_ids ? activityInfo.faculty_ids : [],
          lecture_location: activityInfo.lecture_location,
          lecture_location_id: activityInfo.lecture_location_id,
          education_type: activityInfo.education_type ? activityInfo.education_type : '',
          lesson_count: activityInfo.lesson_count,
          class: activityInfo.class,
          course_type: activityInfo.course_type,
          course_types: activityInfo.course_types,
          description: activityInfo.description
        };
      }
    }

    return fields;
  };

  render() {
    const formInitialValues = this.createFieldValues();
    if (faculty_ids_labels.length == 0 || faculty_ids_labels == undefined) {
      this.putToTable(formInitialValues, false);
    }
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_copy_activity')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validationSchema={CopyCourseFormVal(T)}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ICopyActivityFormValues>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      let activityArray: { id: number; item: Types.IExamPeriod_ActivityItem } = this.props.course[0];
                      let activityInfo = activityArray && activityArray.item.activityInfo
                      let programs = [], locations = [];

                      let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.results_term_week_dates.total_weeks);
                      const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];
                      this.state.week = values.week;
                      const weekOptions = this.state.week.split(",").map((item: any) => ({ label: item, value: item }));
                      let weekOptionSelector = this.state.week.split(",")[0] === "" ? true : false;

                      if (activityInfo && activityInfo.lecture_location) {
                        locations.push(activityInfo.lecture_location)
                        activityInfo.lecture_locations = locations
                      }

                      const courseLocation = allSelectOptions.find(
                        (option) => option.value == props.values.lecture_location_id
                      );

                      let table_values = faculty_ids_labels && faculty_ids_labels.map((faculty_id_label: any, index: any) => (
                        {
                          values:
                            faculty_ids_labels![index] + ',' +
                            program_ids_labels![index] + ',' +
                            section_ids_labels![index]
                        }))

                      let current_form_values = { values: values.faculty_id + ',' + values.program_id + ',' + values.section }
                      if (table_values && table_values.some((e: { values: string; }) => e.values == current_form_values.values)) {
                        this.state.alreadyInTheList = true;
                        this.state.isAdded = true;
                      } else if (this.state.isAdded) {
                        this.state.alreadyInTheList = false;
                        this.state.isAdded = false;
                      } else {
                        this.state.alreadyInTheList = false;
                        this.state.isAdded = false;
                      }

                      const regexSection = /^[a-zA-Z0-9.]*$/;
                      if (values.section) {
                        if (regexSection.test(values.section) && values.section.length < 6) {
                          sectionFormatValidation = false;
                        } else {
                          sectionFormatValidation = true;
                        }
                      }

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="course_code"
                                name="course_code"
                                value={values.course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_code">{T.t('gen_course_code')}</label>
                              {errors && errors.course_code && props.submitCount > 0 && (
                                <div className="error">{errors && errors.course_code}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="course_name"
                                name="course_name"
                                value={values.course_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_name">{T.t('gen_course_name')}</label>
                              {errors && errors.course_name && props.submitCount > 0 && (
                                <div className="error">{errors && errors.course_name}</div>
                              )}
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="student_count"
                                name="student_count"
                                value={values.student_count}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                              {errors && errors.student_count && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.student_count)}</div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_activity_type')}</label>
                                  <Select
                                    id='select_activity'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={T.t_options(ActivityTypes)}
                                    placeholder={T.t('gen_select_activity_type')}
                                    value={
                                      values.activity_type != undefined && values.activity_type != null
                                        ? T.t_options(ActivityTypes).find((option) => option.value == values.activity_type)
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('activity_type', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                  />
                                </div>
                                {errors && errors.activity_type && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.activity_type}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_week')}</label>
                                  <Select
                                    id='select_week'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={WeekSelectOptions}
                                    placeholder={T.t('gen_select_week')}
                                    value={weekOptionSelector ? props.values.weeks : weekOptions}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('weeks',
                                        list.map(item => item.value).includes(-1) ? Weeks : list
                                      );
                                      props.setFieldValue(
                                        'week',
                                        list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                      );
                                      weekOptionSelector = true;
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                  />
                                </div>
                                {errors && errors.week && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.week}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_lecture_location')}</label>
                                  <Select
                                    id='select_location'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={activityInfo && activityInfo.lecture_locations
                                      ? activityInfo.lecture_locations
                                      : []}
                                    placeholder={T.t('gen_select_location')}
                                    value={
                                      values.lecture_location
                                        ? values.lecture_location
                                        : courseLocation
                                          ? courseLocation
                                          : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('lecture_location', option);
                                      props.setFieldValue('lecture_location_id', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                                {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.lecture_location_id}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_instructors')}</label>
                                  <Select
                                    id='select_instructor'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_instructor')}
                                    value={values.instructors}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('instructors', list);
                                      props.setFieldValue(
                                        'instructor_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                  />
                                </div>
                                {errors && errors.instructor_ids && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.instructor_ids}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_education_type')}</label>
                                  <Select
                                    id='select_education_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={EducationTypeSelectOptions(T)}
                                    placeholder={T.t('gen_select_education_type')}
                                    value={
                                      values.education_type != undefined && values.education_type != null
                                        ? T.t_options(EducationTypeSelectOptions(T)).find((option) => option.value == values.education_type)
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('education_type', option.value);
                                      props.setFieldValue('educationType_object', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                  />
                                </div>
                                {errors && errors.education_type && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.education_type}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_type')}</label>
                                  <Select
                                    id='select_course_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={T.t_options(CourseTypes)}
                                    placeholder={T.t('gen_select_course_type')}
                                    value={
                                      values.course_type != undefined && values.course_type != null
                                        ? T.t_options(CourseTypes).find((option) => option.value == values.course_type)
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('course_types', option);
                                      props.setFieldValue('course_type', option && option.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-input form-group date-picker mt-3">
                                <input
                                  id="lesson_count"
                                  name="lesson_count"
                                  value={values.lesson_count}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                                {errors && errors.lesson_count && props.submitCount > 0 && (
                                  <div className="error">{errors && errors.lesson_count}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 form-input form-group with-icon">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              <ErrorMessage component="div" className="error" name="description" />
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_faculty_college_institute')}</label>
                                  <Select
                                    id='select_faculty'
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.faculties
                                        ? this.props.selectOptions.faculties
                                        : []
                                    }
                                    placeholder={T.t('gen_select_faculty_college_institute')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('faculty_id', option && option.value);
                                      props.setFieldValue('faculties', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                  />
                                </div>
                                {errors && errors.faculty_ids && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_program_department')}</label>
                                  <Select
                                    id='select_program'
                                    className="react-select"
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 ? true : false}
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.programs
                                        ? this.props.selectOptions.programs
                                        : []
                                    }
                                    placeholder={T.t('gen_select_program_department')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('program_id', option && option.value);
                                      props.setFieldValue('programs', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                  />
                                </div>
                                {errors && errors.program_ids && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 form-input form-group with-icon mt-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <input
                                    id="section"
                                    name="section"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined ? true : false}
                                    type="text"
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="gen_section">{T.t('gen_section')}</label>
                                  {sectionFormatValidation && (
                                    <div className="error">{T.t('gen_cannot_enter_special_more_than_characters')}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md text-md-right mb-3">
                              <button
                                id='button_add_circle_outline'
                                type="button"
                                disabled={
                                  ((values.program_id !== undefined && values.program_id !== null) ? false : true)
                                  || (this.state.alreadyInTheList ? true : false)
                                  || sectionFormatValidation
                                }
                                data-dismiss="modal"
                                onClick={
                                  this.state.alreadyInTheList ?
                                    () => { } :
                                    () => { this.putToTable(values, true) }
                                }
                                className="btn btn-green"
                                aria-label="alert-success"
                              >
                                <i className="material-icons mr-2">add_circle_outline</i>
                              </button>
                            </div>
                            <div className="col-md-12 list-error">
                              {
                                program_ids_labels && program_ids_labels.length == 0 ?
                                  <span>{T.t('gen_program_cannot_leave_empty')}</span>
                                  : null
                              }
                            </div>
                            <div className="col-md-12 list-error">
                              {
                                faculty_ids_labels && faculty_ids_labels.length == 0 ?
                                  <span>{T.t('gen_faculty_cannot_leave_empty')}</span>
                                  : null
                              }
                            </div>
                            <MultiSelectionTable
                              faculty_ids_labels={faculty_ids_labels}
                              faculties_labels={faculties_labels}
                              program_ids_labels={program_ids_labels}
                              programs_labels={programs_labels}
                              sections_labels={sections_labels}
                              multiSelectionObjectFunction={this.multiSelecteds}
                            />
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                id='button_save'
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => {
                                  props.handleSubmit()
                                  saveButtonClicked = true
                                }}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <h6>{T.t('gen_copied_activity')}</h6>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">{T.t('gen_status')}</th>
                        <th scope="col" className="text-center">{T.t('gen_activity_no')}</th>
                        <th scope="col" className="text-center">{T.t('gen_code')}</th>
                        <th scope="col" className="text-center">{T.t('gen_name')}</th>
                        <th scope="col" className="text-center">{T.t('gen_activity')}</th>
                        <th scope="col" className="text-center">{T.t('gen_lesson_count')}</th>
                        <th scope="col" className="text-center">{T.t('gen_lesson_location')}</th>
                        <th scope="col" className="text-center">{T.t('gen_education_type')}</th>
                        <th scope="col" className="text-center">{T.t('gen_faculty')}</th>
                        <th scope="col" className="text-center">{T.t('gen_program')}</th>
                        <th scope="col" className="text-center">{T.t('gen_grade')}</th>
                        <th scope="col" className="text-center">{T.t('gen_section')}</th>
                        <th scope="col" className="text-center">{T.t('gen_instructors')}</th>
                        <th scope="col" className="text-center">{T.t('gen_student_count')}</th>
                        <th scope="col" className="text-center">{T.t('gen_week')}</th>
                        <th scope="col" className="text-center">{T.t('gen_course_type')}</th>
                        <th scope="col" className="text-center">{T.t('gen_description')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.course ? (
                        this.props.course.length ?
                          (this.props.course.map((courseObject: { id: number; item: Types.IExamPeriod_ActivityItem }) => {
                            let courseItem = courseObject.item.activityInfo;
                            const courseLocation = allSelectOptions.find(
                              (option) => option.value == (courseItem && courseItem.lecture_location_id)
                            );
                            let keyId = 'course-' + (courseItem && courseItem.activity_no) + (Math.random() * 1000)
                            return (
                              <tr
                                key={keyId}
                                data-title={courseItem && courseItem.activity_no}
                              >
                                <td scope="row" data-label={T.t('gen_status')}>
                                  <div className="tags ml-1 mr-4">
                                    <button
                                      id='button_status'
                                      className={
                                        `small-tag text-uppercase` +
                                        (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                      }
                                    >
                                      {GT.GetActiveStatus(courseItem && courseItem.status)}
                                    </button>
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_activity_no')} className="text-center">{courseItem && courseItem.activity_no}</td>
                                <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                  <div className="table-scrollable-td">{courseItem && courseItem.course_code ? courseItem.course_code.split(';').join(', ') : '-'}</div>
                                </td>
                                <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                  <div className="table-scrollable-td">{courseItem!.course_name ? courseItem && courseItem.course_name.split(';').join(', ') : '-'}</div>
                                </td>
                                <td data-label={T.t('gen_activity')} className="text-center">{courseItem && courseItem.activity_type ? ActivityTypesLabel(courseItem && courseItem.activity_type, T) : '-'}</td>
                                <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{courseItem && courseItem.lesson_count}</td>
                                <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                  {courseItem && courseItem.lecture_location && courseItem.lecture_location.label}
                                </td>
                                <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                  {courseItem && courseItem.education_type === EducationTypeSelectOptions(T)[0].value ?
                                    EducationTypeSelectOptions(T)[0].label :
                                    courseItem && courseItem.education_type === EducationTypeSelectOptions(T)[1].value ?
                                      EducationTypeSelectOptions(T)[1].label :
                                      courseItem && courseItem.education_type === EducationTypeSelectOptions(T)[2].value ?
                                        EducationTypeSelectOptions(T)[2].label : courseItem && courseItem.education_type
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.faculties && courseItem.faculties.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem && courseItem.faculties && courseItem.faculties.map((i: any, index: any) => (index == courseItem!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.programs && courseItem.programs.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem.programs && courseItem.programs.map((i: any, index: any) => (index == courseItem!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.classes && courseItem.classes.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem.classes && courseItem.classes.map((i: any, index: any) => (index == courseItem!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.sections && courseItem.sections.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem && courseItem.sections && courseItem.sections.map((i: any, index: any) => (index == courseItem!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.instructors && courseItem.instructors.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem && courseItem.instructors && courseItem.instructors.map((i: any, index: any) => (index == courseItem!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                  {courseItem && courseItem.student_count}
                                </td>
                                <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                  <div className="table-scrollable-td">{courseItem && courseItem.week ? courseItem!.week.split(',').join(', ') : '-'}</div>
                                </td>
                                <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                                  {courseItem && courseItem.course_type ? CourseTypesLabel(courseItem && courseItem.course_type, T) : '-'}
                                </td>
                                <td data-label={T.t('gen_description')} className="text-center">
                                  {courseItem && courseItem.description ? courseItem.description : '-'}
                                </td>
                              </tr>
                            );
                          })) : null
                      ) : null
                      }</tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CopyCourseModal);

export default container;
