import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { CoursePeriodSearchInitialValues, TermTypes } from '../../store/constants/course-period-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import CoursePeriodForm from './course-period-form';
import CopyPeriodForm from './copy-period-form';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import CalendarModal from './calendar-modal';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('CoursePeriodListPage');

class CoursePeriodTable extends Component<Types.ICoursePeriodPageProps, Types.ICoursePeriodPageState> {
  state: Types.ICoursePeriodPageState = {
    filters: CoursePeriodSearchInitialValues,
    filterIsOpen: false,
    coursePeriodFormIsOpen: false,
    calendarFormIsOpen: false,
    coursePeriodId: undefined,
    copyFormIsOpen: false,
    copyFormTermId: undefined
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.searchCoursePeriods();
    this.updateTermListForTermSelector();

    if (this.props.user && this.props.user.role === 'e') {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_welcome'),
          body: (
            <div className="form-input form-group">
              <p className="text-center">Sn. {this.props.user.name + ' ' + this.props.user.surname}</p>
              <p>
                {T.t('gen_course_term_hours_info')}
              </p>
            </div>
          ),
          name: 'welcome_message',
          icon: '',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  updateTermListForTermSelector() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.course_period.COURSE_PERIOD_GET_LIST, null, 'term-selector-spinner')
    );
  }

  copyFormIsOpen = (item: any) => {
    this.state.copyFormIsOpen = true;
    this.state.copyFormTermId = item.term_id;
    this.setState(this.state)
  };

  copyFormOnClose = (refresh: boolean) => {
    if (this.state.copyFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS)));
    }
    if (refresh) {
      this.searchCoursePeriods();
    }
    this.state.copyFormIsOpen = false;
  };

  searchCoursePeriods() {
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.course_period.COURSE_PERIOD_LIST_SEARCH,
        this.state.filters,
        'coursePeriod-list-spin'
      )
    );
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchCoursePeriods();
  };

  coursePeriodFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS, '/create')));
  };

  coursePeriodFormOnClose = (refresh: boolean) => {
    if (this.state.coursePeriodFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS)));
    }
    if (refresh) {
      this.searchCoursePeriods();
    }
  };

  calendarFormIsOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.setState(this.state);
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS, '/calendar/' + id)));
    }
  };

  calendarFormOnClose = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS)));
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchCoursePeriods();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CoursePeriodSearchInitialValues);
    this.setState(this.state);
    this.searchCoursePeriods();
  };

  onFilterCoursePeriod(model: Types.IFilterCoursePeriod, FormActions: FormikActions<Types.IFilterCoursePeriod>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchCoursePeriods();
    FormActions.setSubmitting(false);
  }

  onCoursePeriodEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';

      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS, '/' + id)));
    }
  };

  onCoursePeriodDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.currentTarget.dataset && e.currentTarget.dataset.id) {
      let id = e.currentTarget.dataset.id;
      let name = e.currentTarget.dataset.name;
      const resultCallBack = () => {
        this.updateTermListForTermSelector();
      };
      const onConfirmDelete = () => {
        this.props.dispatch(
          Actions.ApiRequest(Constants.course_period.COURSE_PERIOD_DELETE, id, undefined, resultCallBack)
        );
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_course_term_will_be_deleted'),
          body: T.t('gen_course_term_will_be_deleted_question') + ` (${name})`,
          name: 'coursePeriod_delete',
          icon: 'error_outline',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirmDelete
        })
      );
    }
  };

  static getDerivedStateFromProps(props: Types.ICoursePeriodPageProps, state: Types.ICoursePeriodPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      let regexObject = props.location.pathname.match(/[^/]+$/);
      hasNewState = true;

      if (props.location && props.location.pathname.includes('calendar')) {
        state.calendarFormIsOpen = true;
        // regexObject = props.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
        state.coursePeriodId = parseInt(regexObject[0], 10);
      } else if (props.location && regexObject[0] === 'create') {
        state.coursePeriodFormIsOpen = true;
      } else if (props.location && regexObject[0] !== 'create') {
        state.coursePeriodId = props.match.params.id;
        state.coursePeriodFormIsOpen = true;
      }
    } else {
      hasNewState = true;
      state.coursePeriodFormIsOpen = false;
      state.calendarFormIsOpen = false;
      state.coursePeriodId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onTermSelect = (term_id: any, term_type: any) => {
    this.props.synchronization && (this.props.synchronization.synctype_campus_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_building_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_classroom_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_faculty_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_program_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_instructor_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_course_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_student_clicked = false);

    this.props.dispatch(Actions.SetTermInfo(term_id, term_type));
    this.props.dispatch(
      Actions.Navigation(GT.Route(term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS))
    );
    this.props.dispatch(
      Actions.Navigation(
        GT.Route(
          term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS
        )
      )
    );
  };

  render() {
    let calendarModal = null;

    if (this.props.location && this.props.location.pathname.includes('calendar')) {
      calendarModal = (
        <CalendarModal
          coursePeriodId={this.state.coursePeriodId}
          formIsOpen={this.state.calendarFormIsOpen}
          onClose={this.calendarFormOnClose}
        />
      );
    }

    let coursePeriodList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <div className="main list-campus" style={{ display: this.state.calendarFormIsOpen ? 'none' : 'block' }}>
          <div className="container-fluid">
            <div className="row align-items-center mb-sm-4 mb-2">
              <div className="col-md-6 col-sm-4 col-12">
                <h4 className="mb-4 mb-sm-0">{T.t('gen_course_terms')}</h4>
              </div>
              <div className="col-md-6 col-sm-8 col-12 text-right">
                <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                  <button
                    id='button_filter'
                    className="category-tag-square tag-glass float-right ml-3 mr-3"
                    style={{ margin: '5px' }}
                    onClick={() => {
                      this.state.filterIsOpen = !this.state.filterIsOpen;
                      this.setState(this.state);
                    }}
                  >
                    <i className="material-icons mr-2">filter_list</i>
                    <span>{T.t('gen_filter')}</span>
                  </button>
                  {this.props.user && this.props.user.role === 's' ? (
                    <button id='button_add_course_term' className="ml-3 category-tag-square tag-green" onClick={this.coursePeriodFormIsOpen}>
                      <i className="material-icons mr-2">add</i>{T.t('gen_add_course_term')}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div
              className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
              id="advance-search"
            >
              <div className="advance-search d-block mt-3">
                <Formik
                  initialValues={CoursePeriodSearchInitialValues}
                  enableReinitialize={true}
                  onSubmit={(values, actions) => {
                    this.onFilterCoursePeriod(values, actions);
                  }}
                  onReset={this.onFormReset}
                >
                  {(props: FormikProps<Types.IFilterCoursePeriod>) => {
                    return (
                      <form onSubmit={props.handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <h6>{T.t('gen_filter_parameters')}</h6>
                          </div>
                          <div className="col-md-4">
                            <div className="form-input form-group date-picker">
                              <input
                                id="year"
                                name="year"
                                value={props.values.year}
                                onChange={props.handleChange}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="year">{T.t('gen_year')}</label>
                            </div>
                          </div>
                          <div className="col-md-4 form-input">
                            <div className="react-select-container">
                              <h6>{T.t('gen_term')}</h6>
                              <Select
                                id='select_term'
                                className="react-select"
                                isMulti={true}
                                filterOption={(option: any, query: any) =>
                                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                }
                                closeMenuOnSelect={false}
                                options={TermTypes(T)}
                                placeholder={T.t('gen_select_term')}
                                value={props.values.terms ? props.values.terms : null}
                                onChange={(
                                  options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                ) => {
                                  const list: Array<Types.ISelectOption> = options
                                    ? (options as Array<Types.ISelectOption>)
                                    : [];
                                  props.setFieldValue('terms', list);
                                  props.setFieldValue(
                                    'term_ids',
                                    list.map((item) => item.value)
                                  );
                                }}
                                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-input form-group date-picker">
                              <input
                                id="name"
                                name="name"
                                value={props.values.name}
                                onChange={props.handleChange}
                                type="text"
                                required={false}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">{T.t('gen_term_name')}</label>
                              <i className="material-icons">title</i>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-6">
                            <button
                              id='button_arrow_upward'
                              type="button"
                              onClick={() => {
                                this.state.filterIsOpen = false;
                                this.setState(this.state);
                              }}
                              className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn mr-2"
                            >
                              <i className="material-icons">arrow_upward</i>
                            </button>
                            <button
                              id='button_delete_sweep'
                              type="reset"
                              onClick={props.handleReset}
                              className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                            >
                              <i className="material-icons">delete_sweep</i>
                            </button>
                          </div>
                          <div className="col-6 text-right">
                            <button
                              id='button_search'
                              type="button"
                              className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                              onClick={() => props.handleSubmit()}
                              disabled={props.isSubmitting}
                            >
                              <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                            </button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <Spinner name="coursePeriod-list-spin" />
            <div className="white-container mt-4">
              <div className="row">
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <SortedColumn
                          datacell="status"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="id"
                          title="ID"
                          sortkey="id"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="year"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title={T.t('gen_year')}
                          sortkey="year"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="academic_term"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title={T.t('gen_term')}
                          sortkey="academic_term"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_course_calendar')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_select_term')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_copy_term')}
                        </th>
                        {this.props.user && this.props.user.role === 's' ? (
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {coursePeriodList && coursePeriodList.length
                        ? coursePeriodList.map((item: Types.ITermItem) => {
                          let termObject = TermTypes(T).find((object) => object.value == item.academic_term);
                          return (
                            <tr key={'coursePeriod-' + item.term_id} data-title={item.name}>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id='button_status'
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label="ID">
                                {item.term_id}
                              </td>
                              <td data-label={T.t('gen_year')}>{item.year}</td>
                              <td data-label={T.t('gen_term')}>{termObject && termObject.label}</td>
                              <td data-label={T.t('gen_name')}>{item.name}</td>
                              <td data-label={T.t('gen_course_calendar')} className="text-center">
                                {item.calendar_status == 1 ? (
                                  <span
                                    className="badge badge-success"
                                    style={{ fontSize: '95%', textAlign: 'center' }}
                                  >
                                    {T.t('gen_badge_created')}
                                  </span>
                                ) : (
                                  <span className="badge badge-info" style={{ fontSize: '95%', textAlign: 'center' }}>
                                    {T.t('gen_badge_not_created')}
                                  </span>
                                )}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item && item.description ? item.description : '-'}
                              </td>
                              <td data-cell="exam-periods" className="text-center">
                                <button
                                  id='button_term'
                                  className="period-select-button"
                                  onClick={() => this.onTermSelect(item.term_id, item.term_type)}
                                >
                                  {item.name}
                                </button>
                              </td>
                              <td className="text-center">
                                <button
                                  id='button_copy_term'
                                  className="ml-3 category-tag-square tag-green"
                                  onClick={() => this.copyFormIsOpen(item)}>
                                  <i className="material-icons mr-2">add</i>
                                  {T.t('gen_copy_term')}
                                </button>
                              </td>
                              {this.props.user && this.props.user.role === 's' ? (
                                <td data-label={T.t('gen_actions')} className="table-buttons">
                                  <div className="table-buttons-wrapper">
                                    <button
                                      id='button_edit'
                                      data-toggle="tooltip"
                                      data-id={item.term_id}
                                      onClick={this.onCoursePeriodEdit}
                                      title={T.t('gen_edit')}
                                      className="btn btn-light btn-sm table-button"
                                    >
                                      <span className="d-block" data-toggle="modal" data-target="#addNew">
                                        <i className="material-icons">edit</i>
                                      </span>
                                    </button>
                                    <button
                                      id='button_delete'
                                      data-toggle="tooltip"
                                      data-id={item.term_id}
                                      data-name={item.name}
                                      onClick={this.onCoursePeriodDelete}
                                      title={T.t('gen_delete')}
                                      className="btn btn-light btn-sm table-button"
                                    >
                                      <span className="d-block" data-toggle="modal" data-target="#coursepleModal">
                                        <i className="material-icons">delete</i>
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 && (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {calendarModal}
        {
          this.state.copyFormIsOpen &&
          <CopyPeriodForm
            coursePeriodId={this.state.copyFormTermId}
            formIsOpen={this.state.copyFormIsOpen!}
            onClose={this.copyFormOnClose}
          />
        }
        <CoursePeriodForm
          coursePeriodId={this.state.coursePeriodId}
          formIsOpen={this.state.coursePeriodFormIsOpen}
          onClose={this.coursePeriodFormOnClose}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICoursePeriodPageProps
): Types.ICoursePeriodPageProps => {
  if (!store) {
    return ownProps;
  }

  const newProps: Types.ICoursePeriodPageProps = Object.assign({}, ownProps, {
    results: store.state.course_period_page && store.state.course_period_page.results,
    filters: store.state.course_period_page && store.state.course_period_page.filters,
    user: store.state.user,
    synchronization: store.state.synchronization && store.state.synchronization
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  let nextResults =
    next.state.course_period_page && next.state.course_period_page.results ? next.state.course_period_page.results : [];
  let prevResults =
    prev.state.course_period_page && prev.state.course_period_page.results ? prev.state.course_period_page.results : [];
  if (nextResults) {
    return !!equal(prevResults, nextResults);
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CoursePeriodTable);

export default container;
