import React from 'react';
import { Log } from 'ng2-logger';
import { Pagination, PaginationItem, PaginationLink, UncontrolledTooltip } from 'reactstrap';
import { IPaginateProps } from '../../store/types';

import Translator from '../../services/translate-factory';

import * as Constants from '../../store/constants/all';
const T = Translator.create();
const L = Log.create('Paginate');

export default class Paginate extends React.Component<IPaginateProps, any> {
  constructor(props?: any) {
    super(props);
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  onChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
    let page: number = 1;
    if (e && e.currentTarget && e.currentTarget.dataset.page) {
      page = parseInt(e.currentTarget.dataset.page);
    }

    if (this.props.onPageChange) {
      this.props.onPageChange(page);
    }
  };

  render() {
    const F = this.props.filters;
    const max: number = F ? Math.floor(F.total / F.size) + (F.total % F.size === 0 ? 0 : 1) : 1;
    const disableNext: boolean = F && F.page < max ? false : true;
    const disablePrev: boolean = F && F.page > 1 ? false : true;
    return (
      <Pagination>
        <PaginationItem id="t-first" disabled={disablePrev}>
          <PaginationLink tag="button" first={true} data-page={1} onClick={this.onChange} disabled={disablePrev} />
        </PaginationItem>
        <UncontrolledTooltip placement="top" target="t-first">
          {T.t('gen_first_page')}
        </UncontrolledTooltip>
        <PaginationItem id="t-prev" disabled={disablePrev}>
          <PaginationLink
            tag="button"
            previous={true}
            data-page={F ? F.page - 1 : -1}
            onClick={this.onChange}
            disabled={disablePrev}
          />
        </PaginationItem>
        <UncontrolledTooltip placement="top" target="t-prev">
          {T.t('gen_prev_page')}
        </UncontrolledTooltip>
        {this.props.filters && F ? (
          <PaginationItem id="t-current" disabled={true} active={true}>
            <PaginationLink tag="button" disabled={true}>
              {(F.page - 1) * F.size + 1} - {F.total < F.page * F.size ? F.total : F.page * F.size} / {F.total}
            </PaginationLink>
            <UncontrolledTooltip placement="top" target="t-current">
              {T.t('gen_showing')} / {T.t('gen_total_records')}
            </UncontrolledTooltip>
          </PaginationItem>
        ) : null}
        <PaginationItem id="t-next" disabled={disableNext}>
          <PaginationLink
            tag="button"
            next={true}
            data-page={F ? F.page + 1 : -1}
            onClick={this.onChange}
            disabled={disableNext}
          />
        </PaginationItem>
        <UncontrolledTooltip placement="top" target="t-next">
          {T.t('gen_next_page')}
        </UncontrolledTooltip>
        <PaginationItem id="t-last" disabled={disableNext}>
          <PaginationLink tag="button" last={true} data-page={max} onClick={this.onChange} disabled={disableNext} />
        </PaginationItem>
        <UncontrolledTooltip placement="top" target="t-last">
          {T.t('gen_last_page')}
        </UncontrolledTooltip>
      </Pagination>
    );
  }
}
