import { Log } from 'ng2-logger';
import React from 'react';
import { connect } from 'react-redux';
import APlanHeader from '../../components/templates/aplan-header';
import * as Actions from '../../store/actions/general';
import { routes as Routes } from '../../store/constants/routes';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import MainLoyout from '../layouts/main-layout';

const L = Log.create('DashboardPage');

class DashboardPage extends React.Component<Types.IDashboardPageProps, Types.IDashboardPageState> {
  componentWillMount() {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.EXAM_PERIODS), 0));
  }

  render() {
    return <MainLoyout header={<APlanHeader />}></MainLoyout>;
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDashboardPageProps): Types.IDashboardPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    authUser: store.state.user
  });

  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({
  dispatch
});

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(DashboardPage);

export default container;
