import { Log } from 'ng2-logger';
import React from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { routes as Routes } from '../../store/constants/routes';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import TermSelectorModal from './term-selector-modal';
import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('TermSelector');

class TermSelector extends React.Component<Types.ITermSelectorProps, Types.ITermSelectorState> {
  state: Types.ITermSelectorState = {
    term_id: -1,
    term_name: '',
    term_type: -1,
    synchronization: Object.assign({}),
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    if (this.props.term_id === -1) {
      this.getTermList();
      this.showModal(true);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    if (
      this.props.term_id !== -1 &&
      (!this.props.terms || !this.props.terms.find((t) => t.term_id === this.props.term_id))
    ) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
      this.getTermList();
      this.showModal(true, true);
    }
  }

  getSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_SETTINGS, 'course-list-spin'));
  }

  getTermList = () => {
    this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_LIST, null, 'term-selector-spinner'));
  };

  getTermWeekDates() {
    let model: Types.IFilterExamPeriodCalendarModal = {
      term_id: this.state.term_id,
      page: 1,
      total: -1,
      size: 10,
      status: [1]
    };
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES, model, 'exam-period-modal-tab-spin')
    );
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
        this.state.term_id,
        'exam-period-modal-tab-spin'
      )
    );
  }

  logOff = () => {
    if (this.props.onLogOut) {
      // this.props.onLogOut();
    }
  };

  showModal = (confirmDisabled: boolean = false, noCloseButton: boolean | undefined = undefined) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_please_select_term'),
        body: <TermSelectorModal onTermChange={this.onTermChange} />,
        name: 'term_select',
        icon: '',
        iconColor: 'red',
        confirm: T.t('gen_select'),
        cancel: T.t('gen_cancel'),
        onConfirm: this.onTermSelect,
        confirmDisabled: confirmDisabled,
        noCloseButton: false,
        onCancel: this.props.term_id === -1 ? this.logOff : undefined
      })
    );
  };

  onTermSelect = () => {
    this.props.synchronization && (this.props.synchronization.synctype_campus_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_building_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_classroom_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_faculty_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_program_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_instructor_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_course_clicked = false);
    this.props.synchronization && (this.props.synchronization.synctype_student_clicked = false);

    this.props.dispatch(Actions.SetTermInfo(this.state.term_id, this.state.term_type));

    this.props.dispatch(
      Actions.Navigation(GT.Route(this.state.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS))
    );

    this.props.dispatch(
      Actions.Navigation(
        GT.Route(
          this.state.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS
        )
      )
    );

    this.getSettings();
    this.getTermWeekDates();
  };

  onTermChange = (term_id: number, term_type: number) => {
    if (this.props.term_id === -1 && term_id > 0) {
      this.showModal(false);
    }
    this.state.term_id = term_id;
    this.state.term_type = term_type;
    this.setState(this.state);
  };

  ShowTermSelector = () => {
    this.getTermList();
    if (!this.props.terms || this.props.terms.length === 0) {
      this.showModal(true);
    } else {
      this.showModal();
    }
  };

  render() {
    let term_info: string | undefined;

    if (this.props.terms) {
      let selected_term = this.props.terms.find((t) => t.term_id === this.props.term_id);
      if (selected_term) {
        term_info = `${selected_term.year} ${GT.GetAcademicTermText(selected_term.academic_term)} ${GT.GetTermTypeText(
          selected_term.term_type
        )} / ${selected_term.name} (ID: ${selected_term.term_id})`;
      }
    }

    return (
      <React.Fragment>
        <h6
          onClick={this.ShowTermSelector}
          style={{
            color: 'white',
            cursor: 'pointer',
            marginInlineStart: 'auto',
            paddingRight: '16px',
            paddingTop: '5px',
            fontSize: '0.9rem',
            textShadow: '4px 4px 5px rgba(150, 150, 150, 1)',
            fontStyle: 'italic'
          }}
        >
          {term_info ? term_info : T.t('gen_no_term_selected')}
        </h6>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ITermSelectorProps = Object.assign({}, ownProps, {
    terms: store.state.term_list,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    synchronization: store.state.synchronization && store.state.synchronization
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(TermSelector);

export default container;
