import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, CoursePeriodSearchInitialValues } from '../constants/course-period-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getCoursePeriods(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCoursePeriodData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCoursePeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_cours_term_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCoursePeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_cours_term_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteCoursePeriod(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_cours_term_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.COURSE_PERIOD_LIST_SEARCH, CoursePeriodSearchInitialValues));
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getActivityRelations(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodActivityRelationSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* UpdateActivityRelation(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.UpdateActivityRelation(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
    yield put(Actions.Notification('notification_selected_activities_saved_releted_activities', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* postActivityRelation(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodPostActivityRelation(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    if (content) {
      yield put(Actions.Notification(content, 'gen_warning', 'danger'));
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteActivityRelation(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodDeleteActivityRelation(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* activityDeleteFromActivityRelation(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CoursePeriodActivityDeleteFromActivityRelation(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* courseTermHoursUpdate(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseTermHoursUpdate(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
    yield put(Actions.Notification('notification_selected_classrooms_assigned_hours_saved', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCourseTermAttachedClassrooms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetCourseTermAttachedClassrooms(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteCourseTermAttachedClassrooms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseTermDeleteAttachedClassrooms(action.body));
    if (response && response.status === 200) {
      yield put(Actions.Notification('notification_selected_classrooms_deleted', 'gen_success'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchCoursePeriodSearch() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_LIST_SEARCH, getCoursePeriods);
}

export function* watchCoursePeriodGetById() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_GET_BY_ID, getCoursePeriodData);
}

export function* watchCoursePeriodUpdate() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_UPDATE, updateCoursePeriod);
}

export function* watchCoursePeriodCreate() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_CREATE, createCoursePeriod);
}
export function* watchCoursePeriodDelete() {
  yield takeEvery((action: any) => action.target === constants.COURSE_PERIOD_DELETE, deleteCoursePeriod);
}

export function* watchCourseActivityRelationSearch() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_RELATION_SEARCH, getActivityRelations);
}

export function* watchCourseUpdateActivityRelation() {
  yield takeEvery((action: any) => action.target === constants.UPDATE_ACTIVITY_RELATION, UpdateActivityRelation)
}

export function* watchCoursePostActivityRelation() {
  yield takeEvery((action: any) => action.target === constants.POST_ACTIVITY_RELATION, postActivityRelation);
}

export function* watchCourseDeleteActivityRelation() {
  yield takeEvery((action: any) => action.target === constants.DELETE_ACTIVITY_RELATION, deleteActivityRelation);
}

export function* watchCourseDeleteFromActivityRelation() {
  yield takeEvery((action: any) => action.target === constants.ACTIVITY_DELETE_FROM_ACTIVITY_RELATION, activityDeleteFromActivityRelation);
}

export function* watchCourseTermHoursUpdate() {
  yield takeEvery((action: any) => action.target === constants.COURSE_TERM_HOURS_UPDATE, courseTermHoursUpdate)
}

export function* watchCourseTermAttachedClassrooms() {
  yield takeEvery((action: any) => action.target === constants.COURSE_TERM_ATTACHED_CLASSROOM, getCourseTermAttachedClassrooms)
}

export function* watchCourseTermDeleteAttachedClassrooms() {
  yield takeEvery((action: any) => action.target === constants.COURSE_TERM_DELETE_ATTACHED_CLASSROOM, deleteCourseTermAttachedClassrooms)
}