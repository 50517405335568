import { combineReducers, ReducersMapObject, Action } from 'redux';
import { IAction, IAppState } from '../types';
import * as GT from '../../tools/general-tools';
import AppFactory from '../../services/app-factory';
import CoreReducer from './core-reducer';
import UserReducer from './user-reducer';
import LoginReducer from './login-reducer';
import DashboardReducer from './dashboard-reducer';
// APlan Reducers
import CampusReducer from './campus-reducer';
import BuildingReducer from './building-reducer';
import FacultyReducer from './faculty-reducer';
import ClassroomReducer from './classroom-reducer';
import ProgramReducer from './program-reducer';
import CourseReducer from './course-reducer';
import ActivityReducer from './activity-reducer';
import InstructorReducer from './instructor-reducer';
import CoursePeriodReducer from './course-period-reducer';
import ExamPeriodReducer from './exam-period-reducer';
import SolutionReducer from './solution-reducer';
import IntegrationReducer from './integration-reducer';
import SettingsReducer from './settings-reducer';
import SynchronizationReducer from './synchronization-reducer';

import { Log } from 'ng2-logger';
//const L = Log.create('Reducers');
const AF = AppFactory.create();

const moduleReducers: any = {
  core: CoreReducer,
  user: UserReducer,
  login: LoginReducer,
  dashboard: DashboardReducer,
  campus: CampusReducer,
  building: BuildingReducer,
  faculty: FacultyReducer,
  classroom: ClassroomReducer,
  program: ProgramReducer,
  course: CourseReducer,
  activity: ActivityReducer,
  instructor: InstructorReducer,
  coursePeriod: CoursePeriodReducer,
  examPeriod: ExamPeriodReducer,
  solution: SolutionReducer,
  integration: IntegrationReducer,
  settings: SettingsReducer,
  synchronization: SynchronizationReducer
};

const MainReducer = (state: IAppState = GT.GetInitialState(), action: IAction): IAppState => {
  let newState = state;

  if (!action.timestamp) {
    action.timestamp = Date.now();
  }

  for (let modName in moduleReducers) {
    if (moduleReducers.hasOwnProperty(modName)) {
      let fnReducer = moduleReducers[modName];
      newState = fnReducer(newState, action);
    }
  }

  AF.setAcceptableState(newState);
  return newState;
};

let reducers = combineReducers({
  state: MainReducer
});

export default reducers;
