import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import { ExamPeriodCourseTermLectureLocations, ClassroomCombineTypes, ExamOverlapTypes, ExamTypes, ExamEnvironments } from '../../../store/constants/course-const';
import { ChangeAlldataModalInitialValues } from '../../../store/constants/activity-const';
import Translator from '../../../services/translate-factory';

const T = Translator.create();
const L = Log.create('ChangeAllDataModal');

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: []
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IFilterChangeAllDataModal>) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
    };

    let model;
    if (values.selected_type_ids == 0) {
      model = {
        selected_type: 0,
        term_id: this.props.term_id,
        campus: values.campuses.value,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 1) {
      model = {
        selected_type: 1,
        term_id: this.props.term_id,
        classroom_combine_status: values.classroom_combine_status.value,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 2) {
      model = {
        selected_type: 2,
        term_id: this.props.term_id,
        assistant_staff_ids: values.assistant_staff.map((i: any) => i.value),
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 3) {
      model = {
        selected_type: 3,
        term_id: this.props.term_id,
        exam_duration: values.exam_duration,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 4) {
      model = {
        selected_type: 4,
        term_id: this.props.term_id,
        exam_session: values.exam_session,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 5) {
      model = {
        selected_type: 5,
        term_id: this.props.term_id,
        exam_overlap_type: values.exam_overlap_type.value,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 6) {
      model = {
        selected_type: 6,
        term_id: this.props.term_id,
        exam_type: values.exam_type.value,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 7) {
      model = {
        selected_type: 7,
        term_id: this.props.term_id,
        exam_environment: values.exam_environment.value,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 8) {
      model = {
        selected_type: 8,
        term_id: this.props.term_id,
        invigilator_gap_before_exam: values.invigilator_gap_before_exam,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 9) {
      model = {
        selected_type: 9,
        term_id: this.props.term_id,
        invigilator_gap_after_exam: values.invigilator_gap_after_exam,
        course_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 10) {
      model = {
        selected_type: 10,
        term_id: this.props.term_id,
        section: values.section,
        course_nos: this.props.selected_ids
      }
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COURSE_CHANGE_ALL_DATA, model, 'course-list-spin', resultCallback)
    );
  }

  render() {
    let listOptions = [
      { label: T.t('gen_campus'), value: 0 },
      { label: T.t('gen_classroom_combine_status'), value: 1 },
      { label: T.t('gen_course_assistant'), value: 2 },
      { label: T.t('gen_exam_duration'), value: 3 },
      { label: T.t('gen_exam_session'), value: 4 },
      { label: T.t('gen_exam_overlap_type'), value: 5 },
      { label: T.t('gen_exam_type'), value: 6 },
      { label: T.t('gen_exam_environment'), value: 7 },
      { label: T.t('gen_invigilator_gap_before_exam'), value: 8 },
      { label: T.t('gen_invigilator_gap_after_exam'), value: 9 },
      { label: T.t('gen_section'), value: 10 }
    ]
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAlldataModalInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterChangeAllDataModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 0 ?
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_campus')}</label>
                                    <Select
                                      id='select_location'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={CampusSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.campuses ? props.values.campuses : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('campuses', list);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                    />
                                  </div>
                                </div>
                              </div> :
                              props.values.selected_type_ids === 1 ?
                                <div className="col-md-8">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_classroom_combine_status')}</label>
                                      <Select
                                        id='select_classroom_combine_status'
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={ClassroomCombineTypes(T)}
                                        placeholder={T.t('gen_classroom_combine_status')}
                                        value={props.values.classroom_combine_status ? props.values.classroom_combine_status : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('classroom_combine_status', list);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                      />
                                    </div>
                                  </div>
                                </div> :
                                props.values.selected_type_ids === 2 ?
                                  <div className="col-md-8">
                                    <div className="add-custom-tag mb-3">
                                      <div className="react-select-container">
                                        <label>{T.t('gen_assistants')}</label>
                                        <Select
                                          id='select_assistant'
                                          className="react-select"
                                          isMulti={true}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                          }
                                          closeMenuOnSelect={true}
                                          options={
                                            this.props.selectOptions && this.props.selectOptions.instructors
                                              ? this.props.selectOptions.instructors
                                              : []
                                          }
                                          placeholder={T.t('gen_select_assistant')}
                                          value={props.values.assistant_staff}
                                          onChange={(
                                            options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                          ) => {
                                            const list: Array<Types.ISelectOption> = options
                                              ? (options as Array<Types.ISelectOption>)
                                              : [];
                                            props.setFieldValue('assistant_staff', list);
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                        />
                                      </div>
                                    </div>
                                  </div> :
                                  props.values.selected_type_ids === 3 ?
                                    <div className="col-md-8 form-input form-group with-icon">
                                      <input
                                        id="exam_duration"
                                        name="exam_duration"
                                        value={props.values.exam_duration}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                      />
                                      <span className="highlight" />
                                      <span className="bar" />
                                      <label htmlFor="exam_duration">{T.t('gen_exam_duration')} ({T.t('gen_slot_count')})</label>
                                    </div> :
                                    props.values.selected_type_ids === 4 ?
                                      <div className="col-md-6 form-input form-group with-icon">
                                        <input
                                          id="exam_session"
                                          name="exam_session"
                                          value={props.values.exam_session}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="number"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                                      </div> :
                                      props.values.selected_type_ids === 5 ?
                                        <div className="col-md-8">
                                          <div className="add-custom-tag mb-3">
                                            <div className="react-select-container">
                                              <label>{T.t('gen_exam_overlap_type')}</label>
                                              <Select
                                                id='select_exam_overlap_type'
                                                className="react-select"
                                                isMulti={false}
                                                filterOption={(option: any, query: any) =>
                                                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                }
                                                closeMenuOnSelect={true}
                                                options={ExamOverlapTypes(T)}
                                                placeholder={T.t('gen_exam_overlap_type')}
                                                value={props.values.exam_overlap_type ? props.values.exam_overlap_type : null}
                                                onChange={(
                                                  options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                ) => {
                                                  const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                  props.setFieldValue('exam_overlap_type', list);
                                                }}
                                                noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                                              />
                                            </div>
                                          </div>
                                        </div> :
                                        props.values.selected_type_ids === 6 ?
                                          <div className="col-md-8">
                                            <div className="add-custom-tag mb-3">
                                              <div className="react-select-container">
                                                <label>{T.t('gen_exam_type')}</label>
                                                <Select
                                                  id='select_exam_type'
                                                  className="react-select"
                                                  isMulti={false}
                                                  filterOption={(option: any, query: any) =>
                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                  }
                                                  closeMenuOnSelect={true}
                                                  options={ExamTypes(T)}
                                                  placeholder={T.t('gen_select_exam_type')}
                                                  value={props.values.exam_type ? props.values.exam_type : null}
                                                  onChange={(
                                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                  ) => {
                                                    const list: Array<Types.ISelectOption> = options
                                                      ? (options as Array<Types.ISelectOption>)
                                                      : [];
                                                    props.setFieldValue('exam_type', list);
                                                  }}
                                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                                />
                                              </div>
                                            </div>
                                          </div> : props.values.selected_type_ids === 7 ?
                                            <div className="col-md-8">
                                              <div className="add-custom-tag mb-3">
                                                <div className="react-select-container">
                                                  <label>{T.t('gen_exam_environment')}</label>
                                                  <Select
                                                    id='select_exam_environment'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={ExamEnvironments(T)}
                                                    placeholder={T.t('gen_select_exam_environment')}
                                                    value={props.values.exam_environment ? props.values.exam_environment : null}
                                                    onChange={(
                                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                    ) => {
                                                      const list: Array<Types.ISelectOption> = options
                                                        ? (options as Array<Types.ISelectOption>)
                                                        : [];
                                                      props.setFieldValue('exam_environment', list);
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                                  />
                                                </div>
                                              </div>
                                            </div> :
                                            props.values.selected_type_ids === 8 ?
                                              <div className="col-md-6 form-input form-group with-icon">
                                                <input
                                                  id="invigilator_gap_before_exam"
                                                  name="invigilator_gap_before_exam"
                                                  value={props.values.invigilator_gap_before_exam}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  type="number"
                                                />
                                                <span className="highlight" />
                                                <span className="bar" />
                                                <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')}</label>
                                              </div> : props.values.selected_type_ids === 9 ?
                                                <div className="col-md-6 form-input form-group with-icon">
                                                  <input
                                                    id="invigilator_gap_after_exam"
                                                    name="invigilator_gap_after_exam"
                                                    value={props.values.invigilator_gap_after_exam}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="number"
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')}</label>
                                                </div> :
                                                props.values.selected_type_ids === 10 ?
                                                  <div className="col-md-6 form-input form-group with-icon">
                                                    <input
                                                      id="section"
                                                      name="section"
                                                      value={props.values.section}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      type="text"
                                                    />
                                                    <span className="highlight" />
                                                    <span className="bar" />
                                                    <label htmlFor="section">{T.t('gen_section')}</label>
                                                  </div> : null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  id='button_change'
                                  type="button"
                                  className="btn btn-green"
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.course_page) {
    return (
      !!equal(
        prev.state.course_page && prev.state.course_page.results,
        next.state.course_page && next.state.course_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.coursePage,
        next.state.select_options && next.state.select_options.coursePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;