import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import { routes as Routes } from '../../../store/constants/routes';
import { EducationTypeSelectOptions, CourseTypes, ChangeAlldataModalInitialValues, ActivityTermLectureLocations } from '../../../store/constants/activity-const';

import Translator from '../../../services/translate-factory';
const T = Translator.create();

const L = Log.create('ChangeAllDataModal');

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: []
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IFilterChangeAllDataModal>) {
    let model;
    const resultCallback = (status: number) => {
      if (status == 200) {
        this.props.dispatch(Actions.Notification('notification_all_data_for_courses_changed', 'gen_success'))
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    };
    if (values.selected_type_ids == 0) {
      model = {
        selected_type_id: 0,
        term_id: this.props.term_id,
        lecture_location_id: values.lecture_locations.value,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 1) {
      model = {
        selected_type_id: 1,
        term_id: this.props.term_id,
        course_type: values.course_type.value,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 2) {
      model = {
        selected_type_id: 2,
        term_id: this.props.term_id,
        education_type: values.education_type.value,
        activity_nos: this.props.selected_ids
      }
    } 
    
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_CHANGE_ALL_DATA, model, 'activity-list-spin', resultCallback)
    );
  }

  render() {
    let listOptions = [
      { label: T.t('gen_lecture_location'), value: 0 },
      { label: T.t('gen_course_type'), value: 1 },
      { label: T.t('gen_education_type'), value: 2 },
    ]
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions];
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAlldataModalInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterChangeAllDataModal>) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 0 ?
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lecture_location')}</label>
                                    <Select
                                      id='select_location'
                                      className="react-select"
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      options={allSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.lecture_locations ? props.values.lecture_locations : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_locations', list);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                    />
                                  </div>
                                </div>
                              </div> :
                              props.values.selected_type_ids === 1 ?
                                <div className="col-md-8">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_course_type')}</label>
                                      <Select
                                        id='select_course'
                                        className="react-select"
                                        isMulti={false}
                                        closeMenuOnSelect={true}
                                        options={CourseTypes}
                                        placeholder={T.t('gen_select_type')}
                                        value={props.values.course_type ? props.values.course_type : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('course_type', list);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                      />
                                    </div>
                                  </div>
                                </div> :
                                props.values.selected_type_ids === 2 ?
                                  <div className="col-md-8">
                                    <div className="add-custom-tag mb-3">
                                      <div className="react-select-container">
                                        <label>{T.t('gen_education_type')}</label>
                                        <Select
                                          id='select_education_type'
                                          className="react-select"
                                          isMulti={false}
                                          closeMenuOnSelect={true}
                                          options={EducationTypeSelectOptions(T)}
                                          placeholder={T.t('gen_select_education_type')}
                                          value={props.values.education_type ? props.values.education_type : null}
                                          onChange={(
                                            options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                          ) => {
                                            const list: Array<Types.ISelectOption> = options
                                              ? (options as Array<Types.ISelectOption>)
                                              : [];
                                            props.setFieldValue('education_type', list);
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                        />
                                      </div>
                                    </div>
                                  </div> : null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  id='button_change'
                                  type="button"
                                  className="btn btn-green"
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    selectOptions: store.state.select_options && store.state.select_options.activityPage
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;
