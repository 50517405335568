import { constants } from '../constants/integration-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

import { Log } from 'ng2-logger';

const Logger = Log.create('integrationReducer');

const integrationReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.GET_INTEGRATION: {
      const response: Types.IApiResponseAction = action as Types.IApiResponseAction;

      state = Object.assign({}, state, {
        integration: {
          ...state.integration,
          results: response.content ? response.content : []
        }
      });
      return state;
    }
    default: {
      return state;
    }
  }
};

export default integrationReducer;
