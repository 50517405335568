import { constants } from '../constants/exam-period-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const examPeriodReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.EXAM_PERIOD_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          exam_period_page: {
            ...state.exam_period_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters
          }
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_GET_LIST: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          term_list: result.content ? result.content.data : []
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          exam_period_page: {
            ...state.exam_period_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_SAVE_SCHEDULE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          examPeriodModal: {
            ...state.examPeriodModal,
            storedCalendar: result.content
          }
        });
      }
      return state;
    }
    // Sınav Tarihleri Sekmesi
    case constants.EXAM_PERIOD_GET_PERIOD_DATES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          examPeriodModal: {
            ...state.examPeriodModal,
            exam_dates: result.content.data,
            instructor_hours: {},
            classroom_hours: {},
            course_hours: {},
            students: {}
          }
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_COPY: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          exam_period_page: {
            ...state.exam_period_page,
            requestId: result.content
          }
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_COPY_REQUEST_STATUS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        exam_period_page: {
          ...state.exam_period_page,
          requestStatus: result.content
        }
      });
      return state;
    }
    // Öğretim elemanlarının saatleri sekmesinde apiden gelen kişileri redux store'a ekle
    case constants.EXAM_PERIOD_GET_INSTRUCTOR_HOURS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.instructor_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              instructor_hours: {
                ...state.examPeriodModal.instructor_hours,
                instructor_list: result.content.data,
                all_ids: result.content.all_ids
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    // Dersliklerin saatleri sekmesinde apiden gelenleri redux store'a ekle
    case constants.EXAM_PERIOD_GET_CLASSROOM_HOURS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.classroom_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              classroom_hours: {
                ...state.examPeriodModal.classroom_hours,
                classroom_list: result.content.data,
                classroom_hours_filters: result.content.filters,
                all_ids: result.content.all_ids
              }
            }
          });
        }
      }
      return state;
    }
    case constants.COURSE_PERIOD_GET_TERM_WEEK_DATES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          examPeriodModal: {
            ...state.examPeriodModal,
            resultsTermWeekDates: result.content ? result.content.data : [],
          }
        });
      }
      return state;
    }
    // Derslerin saatleri sekmesinde apiden gelenleri redux store'a ekle
    case constants.EXAM_PERIOD_GET_EXAM_HOURS:
    case constants.EXAM_PERIOD_GET_COURSE_HOURS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.course_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              course_hours: {
                ...state.examPeriodModal.course_hours,
                course_list: result.content.data,
                all_ids: result.content.all_ids,
                all_course_ids: result.content.all_course_ids
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    // Activity İçin Derslerin saatleri sekmesinde apiden gelenleri redux store'a ekle
    //case constants.EXAM_PERIOD_GET_EXAM_HOURS:
    case constants.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              courseterm_course_hours: {
                ...state.examPeriodModal.courseterm_course_hours,
                activity_list: result.content.data,
                all_ids: result.content.all_ids,
                all_course_ids: result.content.all_course_ids
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    // Derslere aktarılan derslikleri apiden çekip redux store'a ekleme
    case constants.EXAM_PERIOD_GET_ATTACHED_CLASSROOMS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.course_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              course_hours: {
                ...state.examPeriodModal.course_hours,
                attached_classrooms: result.content.data,
                attached_classrooms_filters: result.content.filters
              }
            }
          });
        }
      }
      return state;
    }
    // Kesişim saatlerini ekleme
    case constants.EXAM_PERIOD_GET_OVERLAPS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.course_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              course_hours: {
                ...state.examPeriodModal.course_hours,
                overlaps: result.content.data
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    case constants.EXAM_PERIOD_COURSETERM_GET_OVERLAPS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.courseterm_course_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              courseterm_course_hours: {
                ...state.examPeriodModal.courseterm_course_hours,
                overlaps: result.content.data
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    case constants.EXAM_PERIOD_GET_STUDENTS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.course_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              students: {
                ...state.examPeriodModal.students,
                student_list: result.content.data
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    case constants.EXAM_PERIOD_GET_STUDENTS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.course_hours) {
          state = Object.assign({}, state, {
            examPeriodModal: {
              ...state.examPeriodModal,
              students: {
                ...state.examPeriodModal.students,
                student_list: result.content.data
              },
              filters: result.content.filters
            }
          });
        }
      }
      return state;
    }
    case constants.EXAM_PERIOD_STUDENTS_CREATE_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          student_import_result: result.content
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_STUDENTS_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            studentPage: result.content
          }
        });
      }
      return state;
    }
    case constants.EXAM_PERIOD_STUDENTS_GET_PROGRAMS_BY_FACULTIES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            studentPage: result.content
          }
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default examPeriodReducer;
