import React from 'react';
import { Log } from 'ng2-logger';
import imgAnn from '../../assets/images/announcement.svg';

const L = Log.create('Announce');

export default class Announce extends React.Component<any, any> {
  render() {
    return (
      <div className="error-page create-announcement text-center">
        <div className="d-inline-block">
          <img src={imgAnn} />
          <h5 className="mt-4">{this.props.title}</h5>
          <p>{this.props.description}</p>
        </div>
      </div>
    );
  }
}
