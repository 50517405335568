import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  BUILDING_LIST_SEARCH: 'BUILDING:LIST_SEARCH',
  BUILDING_CREATE: 'BUILDING:CREATE',
  BUILDING_UPDATE: 'BUILDING:UPDATE',
  BUILDING_DELETE: 'BUILDING:DELETE',
  BUILDING_GET_BY_ID: 'BUILDING:GET_BY_ID',
  BUILDING_GET_SELECT_OPTIONS: 'BUILDING:GET_SELECT_OPTIONS',
  BUILDING_CREATE_EXCEL_BULK: 'BUILDING:CREATE_EXCEL_BULK',
  DOWNLOAD_ALL_DATA: 'DOWNLOAD_ALL_DATA',
};

export const BuildingSearchInitialValues: Types.IFilterBuilding = {
  building_code: '',
  order_by: '',
  name: '',
  status: [],
  campuses: [],
  select_all: false,
  campus_ids: [],
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const BuildingFormInitialValues: Types.IBuildingItem = {
  building_id: undefined,
  building_code: '',
  name: '',
  address: '',
  description: '',
  status: 1,
  loc_x: 0,
  loc_y: 0
};

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_building_code'), value: 'BUILDING_CODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];