import { constants } from '../constants/activity-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const activityReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.ACTIVITY_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          activity_page: {
            ...state.activity_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : []
          }
        };
      }
    }
    
    case constants.ACTIVITY_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          activity_page: {
            ...state.activity_page,
            form: result.content.data
          }
        });
      }
      return state;
    }

    case constants.ACTIVITY_IS_RELATED: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result) {
          state = Object.assign({}, state, {
          activity_page: {
            ...state.activity_page,
            is_related: result.content
          }
        });
      }
      return state;
    }

    case constants.ACTIVITY_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            activityPage: result.content
          }
        });
      }
      return state;
    }
    case constants.ACTIVITY_GET_PROGRAMS_BY_FACULTIES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            programs_related_faculty: result.content.programs_related_faculty
          }
        });
      }
      return state;
    }
    case constants.ACTIVITY_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          course_import_result: result.content
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default activityReducer;
