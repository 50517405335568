import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ActivityFormInitialValues,
  EducationTypeSelectOptions,
  ActivityTermLectureLocations,
  CourseTypes,
  ActivityTypes
} from '../../store/constants/activity-const';
import * as Types from '../../store/types';
import { ActivityFormValidation } from './course/validations/course-form-val';
import MultiSelectionTable from './multi-selection-table';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('ClassroomForm');

let sectionFormatValidation: boolean = false;
let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;

let faculty_ids_selected: Array<any> = [];
let program_ids_selected: Array<any> = [];
let faculties_selected: Array<any> = [];
let programs_selected: Array<any> = [];
let sections_selected: Array<any> = [];

let faculty_ids_labels: Array<any> = new Array<any>();
let faculties_labels: Array<any> = new Array<any>();
let program_ids_labels: Array<any> = new Array<any>();
let programs_labels: Array<any> = new Array<any>();
let sections_labels: Array<any> = new Array<any>();

function getInitialState(): Types.IActivityFormState {
  const initialValues: Types.IActivityFormState = {
    model: Object.assign({}, ActivityFormInitialValues),
    isAdded: false,
    alreadyInTheList: false,
    week: ''
  };
  return Object.assign({}, initialValues);
}

class CourseHourForm extends Component<Types.IActivityHoursFormProps, Types.IActivityFormState> {
  state: Types.IActivityFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
    this.getProgramsByFacultiesAtActivities([]);
    if (faculty_ids_labels.length == 0 || faculty_ids_labels == [] || faculty_ids_labels == undefined) {
      this.putToTable(this.createFieldValues(), false);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    faculty_ids_labels && faculty_ids_labels.splice(0, faculty_ids_labels.length);
    faculties_labels && faculties_labels.splice(0, faculties_labels.length);
    program_ids_labels && program_ids_labels.splice(0, program_ids_labels.length);
    programs_labels && programs_labels.splice(0, programs_labels.length);
    sections_labels && sections_labels.splice(0, sections_labels.length);

    faculty_ids_selected && faculty_ids_selected.splice(0, faculty_ids_selected.length);
    program_ids_selected && program_ids_selected.splice(0, program_ids_selected.length);
    faculties_selected && faculties_selected.splice(0, faculties_selected.length);
    programs_selected && programs_selected.splice(0, programs_selected.length);
    sections_selected && sections_selected.splice(0, sections_selected.length);
  }

  getProgramsByFacultiesAtActivities = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    this.setState(this.state);
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IActivityItem, FormActions: FormikActions<Types.IActivityItem>) => {
    if (saveButtonClicked) {
      const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
        if (result && result.code) {
          let errors: any = {};
          if (result.details) {
            const validations: Array<Types.IValidationResponse> = result.details;
            validations.forEach((m: Types.IValidationResponse) => {
              errors[m.field] = m.message[0];
            });
          }
          FormActions.setErrors(errors);
        }
        if (status === 200 || status === 201) {
          this.setClose(true);
        }
      };

      this.props.activity.activityInfo = model;
      const dataModel = this.props.activity;

      let newModel;
      let course_programs: any;

      course_programs = faculty_ids_selected.map((faculty_id: any, index: any) => (
        {
          faculty_id: faculty_id,
          program_id: program_ids_selected[index],
          section: model.section ? model.section : '1'
        }));

      if (course_programs == undefined || course_programs == [] || course_programs.length == 0) {
        this.props.dispatch(Actions.Notification(T.t("gen_list_isnot_left_blank"), 'gen_warning', 'danger'))
      } else {
        if (multiSelectedRow) {
          newModel = {
            activityInfo: {
              activity_no: dataModel.activityInfo.activity_no,
              activity_programs: dataModel.activityInfo.activity_programs,
              activity_type: dataModel.activityInfo.activity_type,
              class: dataModel.activityInfo.class,
              classes: dataModel.activityInfo.classes,
              course_code: dataModel.activityInfo.course_code,
              course_name: dataModel.activityInfo.course_name,
              course_term_status: dataModel.activityInfo.course_term_status,
              course_type: dataModel.activityInfo.course_type,
              description: dataModel.activityInfo.description,
              educationType_object: dataModel.activityInfo.educationType_object,
              education_type: dataModel.activityInfo.education_type,
              label: dataModel.activityInfo.label,
              value: dataModel.activityInfo.value,
              faculty_id: dataModel.activityInfo.faculty_id,
              faculty_ids: faculty_ids_selected,
              faculties: faculties_selected,
              instructor_ids: dataModel.activityInfo.instructor_ids,
              instructors: dataModel.activityInfo.instructors,
              lecture_location: dataModel.activityInfo.lecture_location,
              lecture_location_id: dataModel.activityInfo.lecture_location_id,
              lesson_count: dataModel.activityInfo.lesson_count,
              program_id: dataModel.activityInfo.program_id,
              program_ids: program_ids_selected,
              programs: programs_selected,
              section: dataModel.activityInfo.section,
              sections: sections_selected,
              status: dataModel.activityInfo.status,
              student_count: dataModel.activityInfo.student_count,
              term: dataModel.activityInfo.term,
              term_id: dataModel.activityInfo.term_id,
              week: dataModel.activityInfo.week
            },
            activity_noes: dataModel.activity_noes,
            course_hour_id: dataModel.course_hour_id,
            course_id: dataModel.course_id,
            course_ids: dataModel.course_ids,
            status: dataModel.status,
            term_id: dataModel.term_id,
            time_slots: dataModel.time_slots
          }
          multiSelectedRow = false;
        }
        this.props.dispatch(
          Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COURSETERM_EDIT_COURSE, newModel, 'course-form-spin', resultCallback)
        );
        saveButtonClicked = false;
        FormActions.setSubmitting(false);
      }
    }
  };

  static getDerivedStateFromProps(props: Types.IActivityHoursFormProps, state: Types.IActivityFormState) {
    let hasNewState: boolean = false;

    if (props.activity && props.activity.course_hour_id) {
      state.model = props.activity.activityInfo;
      state.week = props.activity.activityInfo.week ? props.activity.activityInfo.week : '';
      const educationTypesArray = [props.activity.activityInfo.education_type];
      const educationTypeObject = EducationTypeSelectOptions(T).find((item) => educationTypesArray.includes(item.value));
      state.model.educationType_object = educationTypeObject;

      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    faculty_ids_labels.length == 0 && plusButtonClicked == false ? faculty_ids_labels = values.faculty_ids : faculty_ids_labels = faculty_ids_labels;
    faculty_ids_labels = values.faculty_id != undefined && plusButtonClicked ? faculty_ids_labels.concat(values.faculty_id) : faculty_ids_labels;
    faculties_labels = values.faculty_id != undefined && plusButtonClicked ? faculties_labels.concat(values.faculties) : values.faculties;

    program_ids_labels.length == 0 && plusButtonClicked == false ? program_ids_labels = values.program_ids : program_ids_labels = program_ids_labels;
    program_ids_labels = values.program_id != undefined && plusButtonClicked ? program_ids_labels.concat(values.program_id) : program_ids_labels;
    programs_labels = values.program_id != undefined && plusButtonClicked ? programs_labels.concat(values.programs) : values.programs.map((item: any) => ({
      label:
        item.label.split(')')[0] + ") - " + (item.label.split('-')[1].trim() == EducationTypeSelectOptions(T)[0].value ?
          EducationTypeSelectOptions(T)[0].label :
          item.label.split('-')[1].trim() === EducationTypeSelectOptions(T)[1].value ?
            EducationTypeSelectOptions(T)[1].label :
            item.label.split('-')[1].trim() === EducationTypeSelectOptions(T)[2].value ?
              EducationTypeSelectOptions(T)[2].label : item.label.split('-')[1].trim()) + ' - ' + item.label.split('-')[2]
      , value: item.value
    }));

    values.section = values.section == '' ? '1' : values.section;
    sections_labels = plusButtonClicked ? sections_labels.concat(values.section) : sections_labels = values.sections;
    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  isIncludedInCurrentForm = (values: any) => {
    if (faculty_ids_labels == undefined) {
      faculty_ids_labels = new Array<any>();
      faculties_labels = new Array<any>();
      program_ids_labels = new Array<any>();
      programs_labels = new Array<any>();
      sections_labels = new Array<any>();
    }

    let table_values = faculty_ids_labels && faculty_ids_labels.map((faculty_id_label: any, index: any) => (
      {
        values:
          faculty_ids_labels![index] + ',' +
          program_ids_labels![index] + ',' +
          sections_labels![index]
      }));

    if (table_values !== undefined) {
      let current_form_values = { values: values.faculty_id + ',' + values.program_id + ',' + values.section }
      if (table_values!.some((e: { values: string; }) => e.values == current_form_values.values)) {
        this.state.alreadyInTheList = true;
        this.state.isAdded = true;
      } else if (this.state.isAdded) {
        this.state.alreadyInTheList = false;
        this.state.isAdded = false;
      } else {
        this.state.alreadyInTheList = false;
        this.state.isAdded = false;
      }
    }

    const regexSection = /^[a-zA-Z0-9.]*$/;
    if (values.section) {
      if (regexSection.test(values.section) && values.section.length < 6) {
        sectionFormatValidation = false;
      } else {
        sectionFormatValidation = true;
      }
    }
  };

  multiSelecteds(
    faculty_ids: any, program_ids: any, faculties: any, programs: any, sections: any
  ) {
    multiSelectedRow = true;
    faculty_ids_selected = faculty_ids;
    program_ids_selected = program_ids;

    faculties_selected = faculties;
    programs_selected = programs;
    sections_selected = sections;

    faculty_ids_labels = faculty_ids;
    faculties_labels = faculties;
    program_ids_labels = program_ids;
    programs_labels = programs;
    sections_labels = sections;
  }

  createFieldValues = (): Types.IActivityItem => {
    let fields: Types.IActivityItem = {
      course_code: '',
      activity_no: 0,
      name: '',
      student_count: -1,
      programs: [],
      program_ids: [],
      faculties: [],
      faculty_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty_name: undefined,
      section: '',
      sections: [],
      description: '',
      program_id: 0,
      faculty_id: 0,
    };
    let courseInfo = this.props.activity.activityInfo ? this.props.activity.activityInfo : this.state.model;
    fields = {
      course_code: courseInfo && courseInfo.course_code ? courseInfo.course_code : '',
      activity_no: courseInfo && courseInfo.activity_no ? courseInfo.activity_no : 0,
      name: courseInfo && courseInfo.name ? courseInfo.name : '',
      campus: courseInfo && courseInfo.campus ? courseInfo.campus : undefined,
      campus_id: courseInfo && courseInfo.campus_id ? courseInfo.campus_id : undefined,
      student_count: courseInfo && courseInfo.student_count ? courseInfo.student_count : 0,
      programs: courseInfo && courseInfo.programs ? courseInfo.programs : [],
      program_ids: courseInfo && courseInfo.program_ids ? courseInfo.program_ids : [],
      faculties: courseInfo && courseInfo.faculties ? courseInfo.faculties : [],
      faculty_ids: courseInfo && courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
      sections: courseInfo && courseInfo.sections ? courseInfo.sections : undefined,
      description: courseInfo && courseInfo.description ? courseInfo.description : undefined,
    };
    return fields;
  };

  render() {
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions];

    let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.results_term_week_dates.total_weeks);
    const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];
    const weekOptions = this.state.week.split(",").map((item: any) => ({ label: item, value: item }));
    let weekOptionSelector = this.state.week.split(",")[0] === "" ? true : false;

    if (faculty_ids_labels == undefined) {
      faculty_ids_labels = new Array<any>();
      faculties_labels = new Array<any>();
      program_ids_labels = new Array<any>();
      programs_labels = new Array<any>();
      sections_labels = new Array<any>();
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={ActivityFormValidation(T)}
        >
          {(props: FormikProps<Types.IActivityItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;

            const courseLocation = allSelectOptions.find((option) => option.value == props.values.lecture_location_id);
            this.isIncludedInCurrentForm(values);

            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{T.t('gen_update_course')}</span>
                        </h5>
                        <button
                          id='close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="course_code"
                              name="course_code"
                              value={values.course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="course_code">{T.t("gen_code")}</label>
                            {errors && errors.course_code && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.course_code)}</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="course_name"
                              name="course_name"
                              value={values.course_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.course_name && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.course_name)}</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="student_count"
                              name="student_count"
                              value={values.student_count}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                            {errors && errors.student_count && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.student_count)}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_course_type')}</label>
                                <Select
                                  id='select_course_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={T.t_options(CourseTypes)}
                                  placeholder={T.t('gen_select_course_type')}
                                  value={
                                    values.course_type != undefined && values.course_type != null
                                      ? T.t_options(CourseTypes).find((option) => option.value == values.course_type)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('course_types', option);
                                    props.setFieldValue('course_type', option && option.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_activity_type')}</label>
                                <Select
                                  id='select_activity'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={T.t_options(ActivityTypes)}
                                  placeholder={T.t('gen_select_activity_type')}
                                  value={
                                    values.activity_type != undefined && values.activity_type != null
                                      ? T.t_options(ActivityTypes).find((option) => option.value == values.activity_type)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('activity_type', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                />
                              </div>
                              {errors && errors.activity_type && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.activity_type)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="lesson_count"
                              name="lesson_count"
                              value={values.lesson_count}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                            {errors && errors.lesson_count && props.submitCount > 0 && (
                              <div className="error">{T.t(errors && errors.lesson_count)}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_week')}</label>
                                <Select
                                  id='select_week'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={WeekSelectOptions}
                                  placeholder={T.t('gen_select_week')}
                                  value={weekOptionSelector ? props.values.weeks : weekOptions}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('weeks',
                                      list.map(item => item.value).includes(-1) ? Weeks : list
                                    );
                                    props.setFieldValue(
                                      'week',
                                      list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                    );
                                    weekOptionSelector = true;
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                />
                              </div>
                              {errors && errors.week && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.week)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_education_type')}</label>
                                <Select
                                  id='select_education_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={EducationTypeSelectOptions(T)}
                                  placeholder={T.t('gen_select_education_type')}
                                  value={
                                    values.education_type != undefined && values.education_type != null
                                      ? T.t_options(EducationTypeSelectOptions(T)).find((option) => option.value == values.education_type)
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('education_type', option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                              {errors && errors.education_type && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.education_type)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_lecture_location')}</label>
                                <Select
                                  id='select_location'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={allSelectOptions}
                                  placeholder={T.t('gen_select_location')}
                                  value={
                                    props.values.lecture_location
                                      ? props.values.lecture_location
                                      : courseLocation
                                        ? courseLocation
                                        : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('lecture_location', option);
                                    props.setFieldValue('lecture_location_id', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                              {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.lecture_location_id)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_instructors')}</label>
                                <Select
                                  id='select_instructor'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.instructors
                                      ? this.props.selectOptions.instructors
                                      : []
                                  }
                                  placeholder={T.t('gen_select_instructor')}
                                  value={props.values.instructors}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('instructors', list);
                                    props.setFieldValue(
                                      'instructor_ids',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                />
                              </div>
                              {errors && errors.instructor_ids && props.submitCount > 0 && (
                                <div className="error">{T.t(errors && errors.instructor_ids)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_faculty_college_institute')}</label>
                                <Select
                                  id='select_faculty'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.faculties
                                      ? this.props.selectOptions.faculties
                                      : []
                                  }
                                  placeholder={T.t('gen_select_faculty_college_institute')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('faculty_id', option && option.value);
                                    props.setFieldValue('faculties', option);
                                    this.getProgramsByFacultiesAtActivities([Number(option && option.value)])
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_connected_programs_departments')}</label>
                                <Select
                                  id='select_program'
                                  className="react-select"
                                  isDisabled={values.faculty_id == undefined || values.faculty_id == 0 ? true : false}
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.programs_related_faculty}
                                  placeholder={T.t('gen_select_program_department')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('program_id', option && option.value);
                                    props.setFieldValue('programs', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                />
                              </div>
                              {errors && errors.program_ids && props.submitCount > 0 && (
                                <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <input
                              id="section"
                              name="section"
                              value={values.section}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="section">{T.t('gen_section')}</label>
                            {sectionFormatValidation && (
                              <div className="error">{T.t('gen_cannot_enter_special_more_than_characters')}</div>
                            )}
                          </div>
                          <div className="col-md text-md-right mb-3">
                            <button
                              id='button_add_circle_outline'
                              type="button"
                              disabled={
                                ((values.program_id != undefined && values.program_id != 0) ? false : true)
                                || (this.state.alreadyInTheList ? true : false)
                                || sectionFormatValidation
                              }
                              data-dismiss="modal"
                              onClick={
                                this.state.alreadyInTheList ?
                                  () => { } :
                                  () => { this.putToTable(values, true) }
                              }
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">add_circle_outline</i>
                            </button>
                          </div>
                          <MultiSelectionTable
                            faculty_ids_labels={faculty_ids_labels}
                            faculties_labels={faculties_labels}
                            program_ids_labels={program_ids_labels}
                            programs_labels={programs_labels}
                            sections_labels={sections_labels}
                            multiSelectionObjectFunction={this.multiSelecteds}
                          />
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <textarea
                            name="description"
                            className="form-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={2}
                            id="description"
                            value={values.description}
                            placeholder={T.t('gen_description')}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description" />
                          <ErrorMessage component="div" className="error" name="description" />
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_close'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={() => {
                                props.handleSubmit()
                                saveButtonClicked = true
                              }}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IActivityHoursFormProps
): Types.IActivityHoursFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IActivityHoursFormProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    term_id: store.state.term_id,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.select_options) {
    return (
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.resultsTermWeekDates,
        next.state.examPeriodModal && next.state.examPeriodModal.resultsTermWeekDates
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseHourForm);

export default container;
