import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import { connect } from 'react-redux';
import Announcement from '../../assets_aplan/images/announcement.svg';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ExamPeriodSearchInitialValues,
  ExamDatesDataModelValues,
  ExamDatesInitialValues
} from '../../store/constants/exam-period-const';
import {
  CoursePeriodSearchInitialValues,
  Days
} from '../../store/constants/course-period-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import { Collapse } from 'reactstrap';
import DynamicTimeInput from '../../components/schedule/dynamic-time-input';
import { IDynamicTimeDurationInput } from '../../store/types';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('ExamDates');

function getInitialState(): Types.IExamDatesState {
  const initialValues: Types.IExamDatesState = {
    model: Object.assign({}, ExamDatesInitialValues),
    dataModel: Object.assign({}, ExamDatesDataModelValues)
  };
  return Object.assign({}, initialValues);
}

let calendar_created: boolean = true;

class Schedule extends Component<Types.IExamDatesProps, Types.IExamDatesState> {
  state: Types.IExamDatesState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };
  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    this.setState({
      ...this.state,
      model: {
        ...this.state.model,
        start_date: '2020-11-20',
        end_date: '2020-11-30',
        calendar: {
          ...this.state.model.calendar,
          days: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar']
        }
      }
    });
    calendar_created = true;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    if (!this.state.model.is_calendar_created && this.state.dataModel.calendar_status) {
      let { start_date, end_date, start_hour, end_hour, slot_duration, empty_durations } = this.state.dataModel;
      let emptyDurations = empty_durations ? JSON.parse(empty_durations) : [];
      this.createHours(start_hour, end_hour, slot_duration, emptyDurations);
    }
  }

  // createDates = (_startDate: string, _endDate: string) => {
  //   var dateList = [];
  //   var dateMove = new Date(_startDate);
  //   var strDate = _startDate;
  //   while (strDate < _endDate) {
  //     var strDate = dateMove.toISOString().slice(0, 10);
  //     dateList.push(strDate);
  //     dateMove.setDate(dateMove.getDate() + 1);
  //   }
  //   let days = dateList.map(date => moment(date).format('YYYY-MM-DD'));
  //   this.state.model.calendar.days = days;
  //   this.state.model.start_date = _startDate;
  //   this.state.model.end_date = _endDate;
  //   this.setState(this.state);
  // };

  // Function was taken from here and edited: https://stackoverflow.com/a/43670801
  createHours = (startTime: any, endTime: any, interval: any, emptyDurations: Array<IDynamicTimeDurationInput>) => {
    let intervalMinutes = moment.duration(interval).asMinutes();
    const periodsInADay = moment.duration(1, 'day').as('minutes');
    const timeLabels = [];
    const startTimeMoment = moment(startTime, 'HH:mm');
    const endTimeMoment = moment(endTime, 'HH:mm');

    for (let i = 0; i <= periodsInADay; i += intervalMinutes) {
      startTimeMoment.add(i === 0 ? 0 : intervalMinutes, 'minutes');
      emptyDurations.forEach((ed) => {
        if (startTimeMoment.format('HH:mm') === ed.hour) {
          timeLabels.push(startTimeMoment.format('HH:mm'));
          let durationInMinutes = moment.duration(ed.duration).asMinutes();
          startTimeMoment.add(durationInMinutes, 'minutes');
        }
      });
      timeLabels.push(startTimeMoment.format('HH:mm'));
    }

    let endIndex = timeLabels.indexOf(endTimeMoment.format('HH:mm'));
    if (endIndex === -1) {
      while (endIndex === -1) {
        endIndex = timeLabels.indexOf(endTimeMoment.add(5).format('HH:mm'));
      }
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_clock_mismatch'),
          body: T.t('gen_end_time_match_info').replace('{0}', endTimeMoment.format('HH:mm')),
          name: 'examPeriod_delete',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
      endTime = endTimeMoment.format('HH:mm');
    }

    let times = timeLabels.slice(0, endIndex + 1);
    this.state.model.calendar.times = times;
    this.state.model.start_hour = startTime;
    this.state.model.end_hour = endTime;
    this.state.model.time_period = interval;
    this.state.model.emptyDurations = emptyDurations;
    this.setState(this.state);
    this.createSchedule();
  };

  createSchedule() {
    let schedule: any = {};
    this.state &&
      this.state.model.calendar.days.map((day: any) => {
        let newArray: any = [];
        this.state.model.calendar.times.map((time: any) => {
          let obj = { hour: time, isActive: false };
          newArray.push(obj);
        });
        schedule[day] = newArray;
      });

    if (schedule) {
      this.state.model.calendar.schedule = schedule;
      this.state.model.is_calendar_created = true;
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_SAVE_SCHEDULE,
          this.state.model.calendar,
          'exam-period-modal-tab-spin'
        )
      );
    }
    this.setState(this.state);

    if (this.state.dataModel.calendar_status == 1 && this.state.dataModel.term_slots) {
      this.convertDataToSchedule();
    }
    this.setState(this.state);
  }

  convertDataToSchedule() {
    let data: any = this.state.dataModel.term_slots;
    let dataDays = Object.keys(data);
    let dateObject: any = {};
    let result: any = {};
    let scheduleClone: any = this.state.model.calendar.schedule;

    dataDays.map((day: string) => {
      dateObject = scheduleClone[day];
      data[day].map((dataHour: string) => {
        dateObject = dateObject.map((item: { hour: string; isActive: boolean }) =>
          item.hour == dataHour ? { hour: item.hour, isActive: true } : item
        );
      });
      result = Object.assign(scheduleClone, { [day]: dateObject });
    });
    this.state.model.calendar.schedule = result;
    this.setState(this.state);
  }

  convertScheduleToData() {
    let scheduleClone: any = this.state.model.calendar.schedule;
    let helperArray: any = [];
    let helperObject: any = {};
    let result: any = {};
    let all_slots: any = {};

    this.state &&
      this.state.model.calendar.days.map((day: string) => {
        scheduleClone[day].map((item: { hour: string; isActive: boolean }) => {
          if (item.isActive == true) {
            helperArray.push(item.hour);
          }
        });
        result = Object.assign(helperObject, helperArray.length ? { [day]: helperArray } : {});
        helperArray = [];
      });

    helperArray = [];
    helperObject = {};
    this.state &&
      this.state.model.calendar.days.map((day: string) => {
        scheduleClone[day].map((item: { hour: string; isActive: boolean }) => {
          helperArray.push(item.hour);
        });
        all_slots = Object.assign(helperObject, helperArray.length ? { [day]: helperArray } : {});
        helperArray = [];
      });

    this.state.dataModel.term_slots = result;
    this.state.dataModel.calendar_status = 1;
    this.state.dataModel.term_id = this.state.model.id;
    this.state.dataModel.name = this.state.model.name;
    this.state.dataModel.start_date = this.state.model.start_date;
    this.state.dataModel.start_hour = this.state.model.start_hour;
    this.state.dataModel.end_date = this.state.model.end_date;
    this.state.dataModel.end_hour = this.state.model.end_hour;
    this.state.dataModel.slot_duration = this.state.model.time_period;
    this.state.dataModel.empty_durations = JSON.stringify(this.state.model.emptyDurations);
    this.state.dataModel.all_slots = all_slots;
    this.setState(this.state);
  }

  chooseHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget && this.state.model.allowCalendarEdit) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const hour: string = e.currentTarget.dataset.hour || '';
      const day: string = e.currentTarget.dataset.day || '';
      if (this.state.model.emptyDurations.findIndex((ed) => ed.hour == hour) !== -1) return;
      let newSchedule = scheduleClone[day].map((item: any) =>
        item.hour == hour ? { ...item, isActive: !item.isActive } : item
      );
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllHoursOfDay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget && this.state.model.allowCalendarEdit) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      const day: string = e.currentTarget.dataset.day || '';
      let dayHasAnyTrueValues = scheduleClone[day].map((item: any) => (item.isActive == true ? 1 : 0)).includes(1);

      let allValuesAreTrue = scheduleClone[day].every(
        (item: any) =>
          item.isActive == true || this.state.model.emptyDurations.findIndex((ed) => ed.hour == item.hour) !== -1
      );

      let newSchedule = scheduleClone[day].map((item: any) => {
        if (this.state.model.emptyDurations.findIndex((ed) => ed.hour == item.hour) !== -1) {
          return item;
        }
        if (allValuesAreTrue) {
          return Object.assign({}, item, { isActive: !item.isActive });
        } else if (dayHasAnyTrueValues) {
          return item.isActive == false ? Object.assign({}, item, { isActive: !item.isActive }) : item;
        } else if (!dayHasAnyTrueValues) {
          return Object.assign({}, item, { isActive: !item.isActive });
        }
      });

      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, {
        [day]: newSchedule
      });
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  chooseAllDaysofHour = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.currentTarget && this.state.model.allowCalendarEdit) {
      let newSchedule = {};
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      const time: string = e.currentTarget.dataset.time || '';

      if (this.state.model.emptyDurations.findIndex((ed) => ed.hour == time) !== -1) return;

      let allValuesAreTrue = daysClone
        .map((day: string) =>
          scheduleClone[day].map((item: any) => item.hour == time && item.isActive == true).includes(true)
        )
        .every((item: any) => item == true);
      daysClone.map((day: string) => {
        let dayHasAnyTrueValues = scheduleClone[day].map((item: any) => (item.isActive == true ? 1 : 0)).includes(1);
        let dayObject = scheduleClone[day].map((item: any) => {
          if (allValuesAreTrue) {
            return item.hour == time ? { ...item, isActive: !item.isActive } : item;
          } else if (dayHasAnyTrueValues) {
            return item.hour == time && item.isActive == false ? { ...item, isActive: !item.isActive } : item;
          } else if (!dayHasAnyTrueValues) {
            return item.hour == time ? { ...item, isActive: !item.isActive } : item;
          }
        });
        newSchedule = Object.assign(newSchedule, { [day]: dayObject });
      });
      let schedule = newSchedule;
      let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, schedule);
      this.state.model.calendar.schedule = updatedSchedule;
      this.setState(this.state);
    }
  };

  clearAllHours = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_tablo_clear_info'),
        body: T.t('gen_clear_hours_warning'),
        name: 'examPeriod_delete',
        icon: 'error_outline',
        iconColor: 'red',
        confirm: T.t('gen_ok'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          if (e && e.currentTarget) {
            let newSchedule = {};
            let scheduleClone: any = this.state.model.calendar.schedule;
            let daysClone: any = this.state.model.calendar.days;
            daysClone.map((day: string) => {
              let dayObject = scheduleClone[day].map((item: any) => Object.assign({}, item, { isActive: false }));
              newSchedule = Object.assign(newSchedule, { [day]: dayObject });
            });
            let schedule = newSchedule;
            let updatedSchedule = Object.assign({}, this.state.model.calendar.schedule, schedule);
            this.state.model.calendar.schedule = updatedSchedule;
            this.setState(this.state);
          }
        }
      })
    );
  };

  checkScheduleAndSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    let dayHasAnyTrueValues: any = [];
    if (e && e.currentTarget) {
      let scheduleClone: any = this.state.model.calendar.schedule;
      let daysClone: any = this.state.model.calendar.days;
      dayHasAnyTrueValues = daysClone.map((day: string) => {
        return scheduleClone[day].map((item: any) => (item.isActive == true ? 1 : 0)).includes(1);
      });
      if (dayHasAnyTrueValues.includes(true)) {
        this.state.model.scheduleHasTrueValues = true;
        this.setState(this.state);
      } else if (!dayHasAnyTrueValues.includes(true)) this.state.model.scheduleHasTrueValues = false;
      this.setState(this.state);
    }
    if (!this.state.model.scheduleHasTrueValues) {
      this.props.dispatch(
        Actions.ShowModal({
          title: 'Saat seçmediniz!',
          body: T.t('gen_select_hours_before_saving'),
          name: 'examPeriod_delete',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else if (this.state.model.scheduleHasTrueValues) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_important_warning'),
          body: T.t('gen_delete_term_warning'),
          name: 'examPeriod_delete',
          icon: 'warning',
          iconColor: 'yellow',
          confirm: T.t('gen_save'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            this.onScheduleSave(this.state.model);
          }
        })
      );
    }
  };

  onClickCreate = (formikValues: any) => {
    this.state.model.start_hour = formikValues.start_hour;
    this.state.model.end_hour = formikValues.end_hour;
    this.state.model.time_period = formikValues.time_period;
    this.state.model.id = this.state.dataModel.term_id;
    this.state.model.emptyDurations = formikValues.emptyDurations;
    let startDate: string = moment(formikValues.dates[0]).format('YYYY-MM-DD');
    let endDate: string = moment(formikValues.dates[1]).format('YYYY-MM-DD');
    this.createHours(
      formikValues.start_hour,
      formikValues.end_hour,
      formikValues.time_period,
      formikValues.emptyDurations
    );
    this.setState(this.state);
  };

  onScheduleDelete = () => {
    let IDfromURL = window.location.pathname.match(/([^\/]+$)/); // gets the id from the url
    let _id = IDfromURL ? parseInt(IDfromURL[0], 10) : undefined;
    this.state.model.id = _id;

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status == 200) {
        this.props.dispatch(
          Actions.ApiRequest(Constants.course_period.COURSE_PERIOD_GET_LIST, { reset: true }, undefined, () => {
            setTimeout(() => {
              this.setState(getInitialState());
            }, 0);
          })
        );
      }
    };

    const onConfirmDelete = () => {
      if (this.state.model.testquery === this.state.model.name.trim()) {
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_DELETE_COURSE_DATES,
            _id,
            'exam-period-modal-tab-spin',
            resultCallback
          )
        );
        this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS)));
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.course_period.COURSE_PERIOD_LIST_SEARCH,
            CoursePeriodSearchInitialValues,
            'examPeriod-list-spin'
          )
        );
        calendar_created = true;
      } else {
        this.props.dispatch(Actions.Notification(T.t('notification_term_match'), 'gen_warning', 'danger'))
      }
    };

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_term_name') + ' ' + this.state.model.name,
        body: (
          <div className="form-input form-group">
            <p>
              {T.t('gen_delete_term_warning')}
              <br /> {T.t('gen_type_course_term_name_to_continue')}
              <br />
              <strong>{T.t('gen_cannot_undo_this_action')}</strong>
              <br />
              <strong></strong>
            </p>
            <input
              id="year"
              name="year"
              placeholder={T.t('gen_input_term_name')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.state.model.testquery = e.target.value;
                this.setState(this.state);
                if (this.state.model.testquery.toLowerCase() === this.state.model.name.toLowerCase().trim()) {
                  this.props.dispatch(
                    Actions.ShowModal({
                      name: 'examPeriod_delete',
                      confirmDisabled: false,
                      confirm: T.t('gen_yes'),
                      cancel: T.t('gen_no')
                    })
                  );
                } else {
                  this.props.dispatch(
                    Actions.ShowModal({
                      name: 'examPeriod_delete',
                      confirmDisabled: true,
                      confirm: T.t('gen_yes'),
                      cancel: T.t('gen_no')
                    })
                  );
                }
              }}
              type="text"
            />
          </div>
        ),
        name: 'examPeriod_delete',
        icon: 'error_outline',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_no'),
        onConfirm: onConfirmDelete,
        confirmDisabled: true
      })
    );
  };

  onScheduleSave = (model: Types.IExamDatesItem) => {
    this.convertScheduleToData();

    let IDfromURL = window.location.pathname.match(/([^\/]+$)/);
    let _id = IDfromURL ? parseInt(IDfromURL[0], 10) : undefined;
    this.state.model.id = _id;
    this.setState(this.state);

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
      }
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.course_period.COURSE_PERIOD_LIST_SEARCH,
          CoursePeriodSearchInitialValues,
          'examPeriod-list-spin'
        )
      );
    };

    if (this.state.model.is_calendar_created) {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_UPDATE_COURSE_DATES,
          this.state.dataModel,
          'exam-period-modal-tab-spin',
          resultCallback
        )
      );
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_SAVE_SCHEDULE,
          this.state.model.calendar,
          'exam-period-modal-tab-spin'
        )
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_CREATE_COURSE_DATES,
          this.state.dataModel,
          'exam-period-modal-tab-spin',
          resultCallback
        )
      );
    }
  };

  //TODO: getDerivedStateFromProps
  static getDerivedStateFromProps(props: Types.IExamDatesProps, state: Types.IExamDatesState) {
    let hasNewState: boolean = false;

    if (props.model && props.model.term_id != state.dataModel.term_id) {
      hasNewState = true;
      state.dataModel = props.model;
      if (props.model && props.model.calendar_status) {
        state.model.id = props.model.term_id;
        state.model.start_hour = props.model.start_hour;
        state.model.start_date = props.model.start_date;
        state.model.emptyDurations = props.model.empty_durations ? JSON.parse(props.model.empty_durations) : [];
        state.model.end_hour = props.model.end_hour;
        state.model.end_date = props.model.end_date;
        state.model.time_period = props.model.slot_duration;
        state.model.allowCalendarEdit = false;
      }
    }

    if (props.model && props.model.name && !state.model.name) {
      hasNewState = true;
      state.model.name = props.model.name;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let { is_calendar_created, start_hour, end_hour, time_period } = this.state.model;
    let { times, days } = this.state.model.calendar;
    let tableColumnHeads = days.map((day) => (
      <th key={day}>
        <div data-day={day} onClick={this.chooseAllHoursOfDay}>
          {Days.map(item => item.value === day ? T.t(item.label) : '')}
        </div>
      </th>
    ));
    let timeTableRows = null;
    if (is_calendar_created) {
      if (calendar_created) {
        times.pop();
        calendar_created = false;
      }
      timeTableRows = times.map((time: string) => {
        let boxes = Array(days.length)
          .fill(undefined)
          .map((val, index) => {
            let day: string = days[index];
            let scheduleClone: any = this.state && this.state.model.calendar && this.state.model.calendar.schedule;
            return (
              <td key={index}>
                <div
                  id='div_choose_hour'
                  data-day={day}
                  data-hour={time}
                  onClick={this.chooseHour}
                  className={
                    scheduleClone[day] &&
                      scheduleClone[day].length &&
                      scheduleClone[day].find((item: any) => item.hour == time).isActive
                      ? 'on'
                      : this.state.model.emptyDurations.findIndex((ed) => ed.hour == time) !== -1
                        ? 'unavailable'
                        : ''
                  }
                ></div>
              </td>
            );
          });

        return (
          <tr key={time}>
            <td className="aplan-tm-sticky-col">
              <div data-time={time} onClick={this.chooseAllDaysofHour}>
                {time}
              </div>
            </td>
            {boxes}
          </tr>
        );
      });
    }

    return (
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-4 mb-3">
          <Formik
            initialValues={this.state.model}
            enableReinitialize={true}
            onSubmit={(values, _actions) => this.onClickCreate(values)}
            onReset={this.onScheduleDelete}
          >
            {(props: FormikProps<Types.IExamDatesItem>) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className="generic-wrapper">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="mb-3">{T.t('gen_calendar_course_hours_details')}</h6>
                      </div>
                    </div>
                    {is_calendar_created ? (
                      <div className="row">
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {this.state.model.start_hour}
                          </label>
                          <p>{T.t('gen_start_time')}</p>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {this.state.model.end_hour}
                          </label>
                          <p>{T.t('gen_end_time')}</p>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {this.state.model.time_period}
                          </label>
                          <p>{T.t('gen_slot_duration')} {T.t('gen_minute_and_hour')}</p>
                        </div>
                        {this.state.model.emptyDurations && this.state.model.emptyDurations.length > 0 && (
                          <DynamicTimeInput disabled initialValues={this.state.model.emptyDurations} />
                        )}
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-12 form-input form-group with-icon">
                          <Flatpickr
                            id='start_time'
                            value={props.values.start_hour}
                            placeholder={T.t('gen_select_time')}
                            options={{
                              enableTime: true,
                              dateFormat: 'H:i',
                              noCalendar: true,
                              time_24hr: true,
                              defaultHour: 8
                            }}
                            onClose={(value) => props.setFieldValue('start_hour', moment(value[0]).format('HH:mm'))}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="start-time-datepicker">{T.t('gen_start_time')}</label>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <Flatpickr
                            id='end_time'
                            value={props.values.end_hour}
                            placeholder={T.t('gen_select_time')}
                            options={{
                              enableTime: true,
                              dateFormat: 'H:i',
                              noCalendar: true,
                              time_24hr: true,
                              defaultHour: 22
                            }}
                            onClose={(value) => props.setFieldValue('end_hour', moment(value[0]).format('HH:mm'))}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="end-time-datepicker">{T.t('gen_end_time')}</label>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <Flatpickr
                            id='time_period'
                            value={props.values.time_period}
                            placeholder={T.t('gen_select_duration')}
                            options={{
                              enableTime: true,
                              dateFormat: 'H:i',
                              noCalendar: true,
                              time_24hr: true,
                              minTime: '00:05',
                              maxTime: '03:00',
                              defaultHour: 1
                            }}
                            onClose={(value) => {
                              props.setFieldValue('time_period', moment(value[0]).format('HH:mm'));
                            }}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="start-time-datepicker">{T.t('gen_slot_duration')} {T.t('gen_minute_and_hour')}</label>
                        </div>
                        <DynamicTimeInput
                          onSelect={(values) => {
                            props.setFieldValue('emptyDurations', values);
                          }}
                        />
                      </div>
                    )}
                    <div className="col-12 text-right">
                      {this.props.user && (this.props.user.role === 's') ? (
                        is_calendar_created ? (
                          <button
                            id='button_close'
                            type="button"
                            className="btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 float-none w-100"
                            style={{ outline: 'none ' }}
                            onClick={props.handleReset}
                          >
                            <i className="material-icons mr-2 text-left">close</i>
                            <span>{T.t('gen_delete')}</span>
                          </button>
                        ) : (
                          <div>
                            <button
                              id='button_add'
                              type="button"
                              disabled={
                                props.values.start_hour && props.values.end_hour && props.values.time_period
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                props.handleSubmit();
                              }}
                              className="btn btn-green mt-md-0 mt-3 mb-md-0 mb-2 float-none w-100"
                              style={{ outline: 'none ' }}
                            >
                              <i className="material-icons mr-2 text-left">add</i>
                              <span>{T.t('gen_create')}</span>
                            </button>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        <div className="col-xl-9 col-lg-9 col-md-8 collapse" id="aplan-time-matrix" style={{ display: 'block' }}>
          <div className="generic-wrapper">
            {is_calendar_created ? (
              <React.Fragment>
                <div className="aplan-table-matrix-wrapper">
                  <div
                    className="aplan-table-matrix-scroller"
                    style={this.state.model.isExpanded ? { height: '100%' } : { height: '400px' }}
                  >
                    <table className="table table-borderless table-striped table-hover aplan-table-matrix course-table mb-0">
                      <thead>
                        <tr>
                          <th className="aplan-tm-sticky-col">
                            <div>#</div>
                          </th>
                          {tableColumnHeads}
                        </tr>
                      </thead>
                      <tbody>{timeTableRows}</tbody>
                    </table>
                  </div>
                  {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                    <React.Fragment>
                      <button
                        id='button_delete_sweep'
                        type="button"
                        onClick={this.clearAllHours}
                        disabled={this.state.dataModel.calendar_status != 0}
                        className="btn btn-danger mt-2 mb-2 float-none"
                      >
                        <i className="material-icons mr-2">delete_sweep</i>
                        <span className="text-left">{T.t('gen_clear')}</span>
                      </button>
                      <button
                        id='button_save'
                        type="button"
                        className="btn btn-blue mt-2 mb-2 ml-2 float-none"
                        disabled={this.state.dataModel.calendar_status != 0}
                        onClick={this.checkScheduleAndSave}
                      >
                        <i className="material-icons mr-2 text-left">done</i>
                        <span>{T.t('gen_save')}</span>
                      </button>
                    </React.Fragment>
                  ) : null}
                  <button
                    id='button_expand'
                    type="button"
                    className="btn btn-gray mt-2 mb-2 float-right"
                    onClick={() => {
                      this.state.model.isExpanded = !this.state.model.isExpanded;
                      this.setState(this.state);
                    }}
                  >
                    <i className="material-icons mr-2 text-left">{this.state.model.isExpanded ? 'remove' : 'add'}</i>
                    <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                  </button>
                </div>
              </React.Fragment>
            ) : (
              <div>
                <img
                  id='img_course_hours_warning'
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '40%'
                  }}
                  src={Announcement}
                />
                <br />
                <p style={{ textAlign: 'center' }}>{T.t('gen_course_created_hours_warning')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IExamDatesProps): Types.IExamDatesProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamDatesProps = Object.assign({}, ownProps, {
    model: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    user: store.state.user
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return !!equal(
      prev.state.examPeriodModal && prev.state.examPeriodModal.exam_dates,
      next.state.examPeriodModal && next.state.examPeriodModal.exam_dates
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(Schedule);

export default container;
