import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ClassroomFormInitialValues, ClassroomFeatureOptions } from '../../store/constants/classroom-const';
import * as Types from '../../store/types';
import { ExamClassroomFormValidation } from './validations/classroom-form-val';
import { CourseClassroomFormValidation } from './validations/course-classroom-form-val';
import Translator from '../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('ClassroomForm');

function getInitialState(): Types.IClassroomFormState {
  const initialValues: Types.IClassroomFormState = {
    model: Object.assign({}, ClassroomFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class ClassroomForm extends Component<Types.IClassroomFormProps, Types.IClassroomFormState> {
  state: Types.IClassroomFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  static getDerivedStateFromProps(props: Types.IClassroomFormProps, state: Types.IClassroomFormState) {
    let hasNewState: boolean = false;
    if (props.classroomId && props.classroomId != state.model.classroom_id) {
      state.model.classroom_id = props.classroomId;
      props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BY_ID, props.classroomId, 'classroom-form-spin')
      );
      hasNewState = true;
    }
    if (props.form && props.classroomId && props.classroomId == state.model.classroom_id) {
      state = getInitialState();
      state.model = Object.assign(state.model, props.form);
      if (props.form.feature_codes) {
        state.model.feature_codes = props.form.feature_codes;
        let classroomFeatures: any = [];
        props.form.feature_codes.map((featureCode: string) => {
          let feature = ClassroomFeatureOptions(T).find((item: Types.ISelectOption) => item.value == featureCode);
          classroomFeatures.push(feature);
        });
        state.model.features = classroomFeatures;
      }
      if (props.form.building) {
        state.model.building_id = props.form.building.value;
      }
      if (props.form.classroomTypeSelect) {
        state.model.classroom_type = props.form.classroomTypeSelect.value;
      }
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.classroomId && state.model.classroom_id) {
      return getInitialState();
    } else return null;
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IClassroomItem, FormActions: FormikActions<Types.IClassroomItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.classroomId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_UPDATE, model, 'classroom-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_CREATE, model, 'classroom-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  render() {
    const validation = this.props.term_type === 0 ? CourseClassroomFormValidation(T) : ExamClassroomFormValidation(T);
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />

        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={validation}
        >
          {(props: FormikProps<Types.IClassroomItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {this.props.classroomId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_classroom')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_classroom')}</>}
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="classroom_code"
                              name="classroom_code"
                              value={values.classroom_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="classroom_code">{T.t('gen_code')}</label>
                            {errors && errors.classroom_code && props.submitCount > 0 && (
                              <div className="error">{errors && errors.classroom_code}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.name && props.submitCount > 0 && (
                              <div className="error">{errors && errors.name}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_type')}</label>
                                <Select
                                  id='classroom_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.classroom_types
                                      ? this.props.selectOptions.classroom_types
                                      : []
                                  }
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.classroomTypeSelect}
                                  onChange={(option: any) => {
                                    props.setFieldValue('classroom_type', option.value);
                                    props.setFieldValue('classroomTypeSelect', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                              {errors && errors.classroom_type && props.submitCount > 0 && (
                                <div className="error">{errors && errors.classroom_type}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_campus_and_building')}</label>
                                <Select
                                  id='select_building'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.buildings
                                      ? this.props.selectOptions.buildings
                                      : []
                                  }
                                  placeholder={T.t('gen_select_building')}
                                  value={props.values.buildingSelect}
                                  onChange={(option: any) => {
                                    props.setFieldValue('building_id', option.value);
                                    props.setFieldValue('buildingSelect', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                />
                              </div>
                              {errors && errors.building_id && props.submitCount > 0 && (
                                <div className="error">{errors && errors.building_id}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="building_floor"
                              name="building_floor"
                              value={values.building_floor}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                            {errors && errors.building_floor && props.submitCount > 0 && (
                              <div className="error">{errors && errors.building_floor}</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="door_order"
                              name="door_order"
                              value={values.door_order}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                            {errors && errors.door_order && props.submitCount > 0 && (
                              <div className="error">{errors && errors.door_order}</div>
                            )}
                          </div>
                          {this.props.term_type === 1 ? (
                            <div className="col-md-4 form-input form-group with-icon">
                              <input
                                id="exam_capacity"
                                name="exam_capacity"
                                value={values.exam_capacity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_capacity">{T.t('gen_exam_capacity')}</label>
                              {errors && errors.exam_capacity && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_capacity}</div>
                              )}
                            </div>
                          ) : null}
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="lecture_capacity"
                              name="lecture_capacity"
                              value={values.lecture_capacity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                            {errors && errors.lecture_capacity && props.submitCount > 0 && (
                              <div className="error">{errors && errors.lecture_capacity}</div>
                            )}
                          </div>
                          {this.props.term_type === 1 ? (
                            <div className="col-md-4 form-input form-group with-icon">
                              <input
                                id="invigilator_count"
                                name="invigilator_count"
                                value={values.invigilator_count}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_count">{T.t('gen_invigilator_count')}</label>
                              {errors && errors.invigilator_count && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_count}</div>
                              )}
                            </div>
                          ) : null}
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_features')}</label>
                                <Select
                                  id='feature'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={ClassroomFeatureOptions(T)}
                                  placeholder={T.t('gen_select_feature')}
                                  value={props.values.features}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('features', list);
                                    props.setFieldValue(
                                      'feature_codes',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            {errors && errors.description && props.submitCount > 0 && (
                              <div className="error">{errors && errors.description}</div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomFormProps
): Types.IClassroomFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IClassroomFormProps = Object.assign({}, ownProps, {
    form:
      store.state.classroom_page && store.state.classroom_page.form
        ? store.state.classroom_page.form
        : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    term_type: store.state.term_type
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.form,
        next.state.classroom_page && next.state.classroom_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomForm);

export default container;
