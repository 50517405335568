import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, persistCombineReducers, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootSaga from './sagas';
import * as actions from '../store/constants/all';
import * as Actions from '../store/actions/general';
import * as Types from '../store/types';
import * as Enums from '../store/constants/enums';
// reducers
import Reducers from './reducers';
// middlewares
import NotificationMiddleware from '../store/middlewares/NotificationMiddleware';
import ActionEmiterMiddleware from '../store/middlewares/ActionEmiterMiddleware';
import NavigationMiddleware from '../store/middlewares/NavigationMiddleware';
import SpinnerMiddleware from '../store/middlewares/SpinnerMiddleware';
import UploadMiddleware from '../store/middlewares/UploadMiddleware';
// services
import AppFactory from '../services/app-factory';

import { Log } from 'ng2-logger';
import { IAppState } from './types';
const L = Log.create('Stores');
const sagaMiddleware = createSagaMiddleware();
const AF = AppFactory.create();

const persistConfig: PersistConfig = {
  key: 'primary',
  version: 1,
  storage: storage,
  debug: true
};

const reducer = persistReducer(persistConfig, Reducers);
// const reducer = persistCombineReducers(persistConfig, Reducers);

export default () => {
  const store = createStore(
    reducer,
    undefined,
    compose(
      applyMiddleware(
        sagaMiddleware,
        NotificationMiddleware,
        ActionEmiterMiddleware,
        NavigationMiddleware,
        SpinnerMiddleware,
        UploadMiddleware
      )
    )
  );
  AF.dispatcher = store.dispatch;
  const persistor = persistStore(store, undefined, () => {
    const state: IAppState = AF.setStateFunctions(store.getState().state);

    AF.setAcceptableState(state);
    AF.setAppModuleReady(actions.gen.CORE_PERSISTOR_READY);
    /*
        if (state && state.account && state.account.account_id) {
            AF.setAppModuleReady(actions.gen.CORE_WELL_KNOWN_UNITS);
        } else {
            store.dispatch(Actions.ApiRequest(actions.gen.CORE_WELL_KNOWN_UNITS));
        }
        */
    if (state && state.permissions && state.user) {
      AF.setAppModuleReady(actions.gen.CORE_AUTH_USER);
    }
  });
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};
