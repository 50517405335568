import React from 'react';
import { withRouter } from 'react-router-dom';
import { INavigationAction } from '../../store/types';
// import { Log } from 'ng2-logger';
// const L = Log.create('Navigator');

let _instance: Navigation;
class Navigation extends React.Component<any, any> {
  _timer: any;

  state = {
    action: {}
  };

  constructor(props: any) {
    super(props);
    _instance = this;
  }

  static getInstance = (): Navigation => {
    return _instance;
  };

  setNavigate = (action: INavigationAction) => {
    if (action) {
      action.timestamp = Date.now();
      this.state.action = action;
      setTimeout(() => {
        this.props.history.push(action.to);
      }, action.timeout || 0);
    }
  };

  render() {
    return null;
  }
}

export default withRouter(Navigation);
export { Navigation };
