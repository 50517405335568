import * as actions from '../constants/all';
import * as Types from '../types';
import { string } from 'yup';

export function Notification(
  text: string,
  title?: string,
  color: 'info' | 'sucess' | 'danger' | 'warning' = 'sucess',
  onClick?: any,
  timeout: number = 10000,
  position: 'default' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' = 'topRight'
): Types.INotificationAction {
  return {
    type: actions.gen.CORE_NOTIFICATION,
    title,
    onClick,
    color,
    position,
    timeout,
    timestamp: Date.now(),
    text,
    visible: true
  };
}

export function ApiRequest(
  target: string,
  body: any = null,
  spinner?: string,
  callback?: Function,
  qs?: any
): Types.IApiRequestAction {
  return {
    type: actions.gen.CORE_API_REQUEST,
    body: body,
    target: target,
    spin: spinner,
    callback: callback,
    qs: qs
  };
}

export function ApiResponse(
  request: Types.IApiRequestAction,
  response: Response,
  content: any = null
): Types.IApiResponseAction {
  if (request.callback) {
    request.callback(content, response.status, response);
  }
  return {
    type: request.target,
    request: request,
    response: response,
    content: content
  };
}

export function AppReady(): Types.IAction {
  return {
    type: actions.gen.CORE_APP_READY
  };
}

export function Navigation(to: string, timeout: number | undefined = 0): Types.INavigationAction {
  return {
    type: actions.gen.CORE_NAVIGATION,
    to: to,
    timeout: timeout
  };
}

export function ShowModal(props: Types.IModalProps): Types.IModalAction {
  return {
    ...props,
    type: actions.gen.CORE_SHOW_MODAL
  };
}

export function ReloadState(): Types.IAction {
  return {
    type: actions.gen.CORE_RELOAD_STATE
  };
}

export function ClearStatus(): Types.IAction {
  return {
    type: actions.gen.CORE_SET_STATUS
  };
}

export function SetTermInfo(term_id: number, term_type: number): Types.ISetTermInfoAction {
  return {
    termId: term_id,
    termType: term_type,
    type: actions.gen.CORE_SET_TERM_INFO
  };
}

export function UploadRequest(
  options: Types.IUploadRequestOptions,
  file: Types.IFileView,
  callback?: Function
): Types.IFileUploadRequestAction {
  return { type: actions.file.FILE_UPLOAD, options, file, callback };
}

export function UploadResponse(file: Types.IFileView, status: string): Types.IFileUploadResponseAction {
  return { type: actions.file.FILE_UPLOAD_RESULT, file, status};
}
