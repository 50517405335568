import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import { constants } from '../constants/integration-const';
import { Log } from 'ng2-logger';
import AplanApiClient from '../../services/mock-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';

const L = Log.create('user-saga');

function* getIntegration(action: Types.IApiRequestAction) {
  const AAPI: AplanApiClient = new AplanApiClient();
  let response: Response;
  let content: any = null;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield AAPI.GetResponse(AAPI.GetIntegration(action.body));
    if (response && response.status === 200) {
      content = yield AAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* addMultiWithIntegration(action: Types.IApiRequestAction) {
  const AAPI: AplanApiClient = new AplanApiClient();
  const response: Response = yield AAPI.GetResponse(AAPI.AddMultiWithIntegration(action.body));
  let content: any = yield AAPI.GetContent(response);

  if (response && response.status == 200) {
    yield put(Actions.Notification('notification_selected_added', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchGetIntegration() {
  yield takeEvery((action: any) => action.target === constants.GET_INTEGRATION, getIntegration);
}

export function* watchAddMultiWithIntegration() {
  yield takeEvery((action: any) => action.target === constants.ADD_MULTI_WITH_INTEGRATION, addMultiWithIntegration);
}
