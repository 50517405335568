export enum PermissionType {
  None = 0,
  CamMic_Access_Self = 1,
  CamMic_Remote_Request = 2,
  CamMic_Reject_Response = 3,
  Session_Operations = 4,
  Session_Manage_Options = 5,
  Session_Manage_Surveys = 6,
  Session_Manage_Participants = 7,
  Session_Manage_Files = 8,
  Session_Manage_Chats = 9,
  Video_Player_Control = 10,
  Board_Sharing_Auth = 11,

  Panel_Calendar = 100,
  Panel_Session_Create = 200,
  Panel_Session_List = 201,
  Panel_Session_Delete = 202,
  Panel_Session_Update = 203,
  Panel_User_List = 300,
  Panel_User_Create = 301,
  Panel_User_Create_Excel = 302,
  Panel_User_Delete = 303,
  Panel_User_Edit = 304,
  Panel_Attendee_Insert = 401,
  Panel_Attendee_Delete = 402,
  Panel_Attendee_List = 403,
  Panel_User_Info = 600,
  Panel_Company_Info = 601,
  Panel_Common_File = 601,
  Panel_Report = 602,
  Panel_License = 603,
  Panel_Password_Change = 604,
  Panel_System_Check = 605,
  Panel_Help = 606,
  Panel_TEST = 706
}

export enum ReplayStatus {
  NotAllowed = 0,
  NotPacked = 1,
  Packed = 2,
  NoContent = 3
}

export enum SectionTypes {
  Campuses = 0,
  Buildings = 1,
  Classrooms = 2,
  Faculties = 3,
  Programs = 4,
  Courses = 5,
  Instructors = 6,
  Exam_Period_Classrooms = 7,
  Exam_Period_Courses = 8,
  Exam_Period_Instructors = 9,
  Exam_Period_Students = 10,
  Exam_Period_Solution = 11,
  Students = 15,
  SyncCampuses = 16,
  SyncBuildings = 17,
  SyncClassrooms = 18,
  SyncFaculties = 19,
  SyncPrograms = 20,
  SyncInstructors = 21,
  SyncCourses = 22,
  SyncStudents = 23,
  SyncCampusesResult = 24,
  SyncBuildingsResult = 25,
  SyncClassroomsResult = 26,
  SyncFacultiesResult = 27,
  SyncProgramsResult = 28,
  SyncInstructorsResult = 29,
  SyncCoursesResult = 30,
  SyncStudentsResult = 31,
}

export enum SyncResultIds {
  None = 0,
  FetchStarted = 1,
  Fetched = 2,
  FetchFailed = 3,
  SyncStarted = 4,
  Synched = 5,
  SyncFailed = 6
}