import * as Types from '../../store/types';

export const actions = {
  GROUP_LIST_SEARCH: 'GROUP:LIST_SEARCH',
  GROUP_GET_SELECT_OPTIONS: 'GROUP:GET_SELECT_OPTIONS',
  GROUP_GET_SESSION_ATTENDEE_SELECT_OPTIONS: 'GROUP:GET_SESSION_ATTENDEE_SELECT_OPTIONS',
  GROUP_GET_BY_ID: 'GROUP:GET_BY_ID',
  GROUP_CREATE: 'GROUP:CREATE',
  GROUP_UPDATE: 'GROUP:UPDATE',
  GROUP_DELETE: 'GROUP:DELETE',
  GROUP_USERS_UPDATE: 'GROUP:USERS_UPDATE'
};

export const GroupSearchInitialValues: Types.IGroupPagedQuery = {
  order_by: '',
  page: 1,
  size: 10,
  query: '',
  total: -1,
  name: ''
};

export const GroupFormInitialValues: Types.IGroup = {
  group_id: '',
  name: '',
  description: '',
  user_ids: []
};
