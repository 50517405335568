import * as Types from '../types';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
const L = Log.create('LoginReducer');

function LoginReducer(state: Types.IAppState, action: Types.IAction): Types.IAppState {
  if (action.type === actions.login.LOGIN_AUTH_TOKEN) {
    const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
    if (result && result.content) {
      state = Object.assign({}, state, { auth: result.content });
    }
  }

  return state;
}

export default LoginReducer;
