import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import Flatpickr from 'react-flatpickr';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { FileInitialValues } from '../../store/constants/file-const';
import { EmailServiceSelectOptions, ClassroomInvigilatorCountSettings, InvigilatorAssignTypes } from '../../store/constants/setting-const';
import { Formik, FormikActions, FormikProps } from 'formik';
import moment from 'moment';
import 'moment/locale/tr';
import Switch from 'react-switch';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import CalendarSettings from './calendar-setting';
import ImageHolder from '../../components/image/image-holder';
import imgLogo from '../../assets_aplan/images/aplan-logo.svg';
import Translator from '../../services/translate-factory';
import * as GT from '../../tools/general-tools';

const T = Translator.create();
const L = Log.create('GroupListPage');

let invigilatorCountsDueToStudentCount: Array<any> = new Array<any>();
let prevInvigilatorCountsDueToStudentCount: Array<any> = new Array<any>();
let validationInvigilatorByStudent: boolean = false;

let classroomTypes: Array<any> = new Array<any>();
let classroomTypesAddButtonIsActive: boolean = false;

let InvigilatorSettingsByFaculty: Array<any> = new Array<any>();
let InvigilatorSettingsByFacultyAddButtonIsActive: boolean = false;
let InvigilatorSettingsByFacultyFielsIsEmpty: boolean = false;

class APlanSettings extends Component<any, Types.IAplanSettingsPageState> {
  state: Types.IAplanSettingsPageState = Object.assign({
    locale: ''
  });

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.state.locale = GT.getLocaleFromLangCode();
    this.getSettings();
    this.getCourseSelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_SETTINGS, 'course-list-spin'));
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  saveSettings = (model: Types.IAplanSettingsPageState, FormActions: FormikActions<Types.IAplanSettingsPageState>) => {
    model.classroom_types = this.state.classroom_types;
    model.invigilator_settings_by_faculty = this.state.invigilator_settings_by_faculty;
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('settings_save_title'),
        body: T.t('settings_save_question'),
        name: 'courseHours_save',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_save'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.getSettings();
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.setting.UPDATE_SETTINGS, model, 'exam-period-modal-tab-spin', resultCallback)
          );
        }
      })
    );
  };

  static getDerivedStateFromProps(props: any, state: Types.IAplanSettingsPageState) {
    let hasNewState: boolean = false;

    if (props.settings && state.term_id == null) {
      state = props.settings;

      hasNewState = true;
    }

    if (InvigilatorSettingsByFaculty.length != 0) {
      state.invigilator_settings_collapse = "1";
    } else {
      state.invigilator_settings_collapse = "0";
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onUploadLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.state.institution_logo = URL.createObjectURL(img);
      this.setState(this.state);
    }
  };

  onBrandLogoUploadRequest = (imageHolder: ImageHolder) => {
    let options: Types.IUploadRequestOptions = {
      accept: 'image/*',
      maxSize: 100001
    };

    if (this.props.uploadOptions) {
      options.fileMaxSizes = this.props.uploadOptions.fileMaxSizes;
    }

    let file: Types.IFileView = Object.assign({}, FileInitialValues);
    file.shared = false;
    file.container = '_logo[brand]';

    const resultCallback = (file: Types.IFileView, status: string) => {
      L.data('resultCallback :', file, status);
      if (status === 'uploaded') {
        if (file) {
          if (imageHolder) {
            imageHolder.updateImage();
            this.props.dispatch(Actions.Notification('notification_logo_saved', 'gen_success'))
          }
        }
      }
      else {
        this.props.dispatch(Actions.Notification(file.toString(), 'gen_error', 'danger'))
      }
    };

    this.props.dispatch(Actions.UploadRequest(options, file, resultCallback));
  };

  onChangeRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      this.state.user_entry_type = Number(e.currentTarget.value);
      this.setState(this.state);
    }
  }

  onAddRow = (invigilatorCountsDueToStudentCount: Array<any>) => {
    prevInvigilatorCountsDueToStudentCount = invigilatorCountsDueToStudentCount;

    let model;
    if (invigilatorCountsDueToStudentCount.length == 0) {
      model = {
        option: 1,
        min: 1,
        max: 40,
        num_of_invigilators: 1
      }
    } else {
      let interval = Number(invigilatorCountsDueToStudentCount[invigilatorCountsDueToStudentCount.length - 1].max) - Number(invigilatorCountsDueToStudentCount[invigilatorCountsDueToStudentCount.length - 1].min);
      let min = Number(invigilatorCountsDueToStudentCount[invigilatorCountsDueToStudentCount.length - 1].max) + 1;
      model = {
        option: invigilatorCountsDueToStudentCount.length,
        min: min.toString(),
        max: (min + interval).toString(),
        num_of_invigilators: (invigilatorCountsDueToStudentCount.length + 1).toString()
      }
    }

    invigilatorCountsDueToStudentCount.push(model)
    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
      invigilatorCountsDueToStudentCount
    );
    this.setState(this.state);
  };

  onDeleteRow = (index: any) => {
    prevInvigilatorCountsDueToStudentCount = invigilatorCountsDueToStudentCount;
    invigilatorCountsDueToStudentCount.splice(index, 1);
    this.state.invigilator_counts_due_to_student_count = JSON.stringify(
      invigilatorCountsDueToStudentCount
    );
    this.setState(this.state);
  };

  onAddClassroomTypeRow = (classroom_type: string) => {
    classroomTypes = JSON.parse(this.state.classroom_types);
    let classroom_type_names = classroomTypes.map((item: any) => item.name.toLowerCase());
    if (!classroom_type_names.includes(classroom_type.toLowerCase())) {
      let model = {
        id: classroomTypes.length + 1,
        name: classroom_type
      }

      classroomTypes.push(model);
      this.state.classroom_types = JSON.stringify(
        classroomTypes
      );

      classroomTypesAddButtonIsActive = false;
    } else {
      classroomTypesAddButtonIsActive = true;
    }

    this.setState(this.state);
  };

  onDeleteClassroomTypeRow = (index: any) => {
    classroomTypes.splice(index, 1);
    this.state.classroom_types = JSON.stringify(
      classroomTypes
    );
    this.setState(this.state);
  };

  onAddInvigilatorSettingsByFacultyRow = (faculty: Types.ISelectOption, classroom_invigilator_count: number, invigilator_assign_type: number) => {
    if (!faculty) {
      InvigilatorSettingsByFacultyFielsIsEmpty = true;
    } else {
      let faculty_code = InvigilatorSettingsByFaculty.map((item: any) => item.faculty_code.toLowerCase());

      if (!faculty_code.includes(faculty.value.toLowerCase())) {
        let model = {
          faculty_code: faculty.value,
          faculty_name: faculty.label,
          classroom_invigilator_count: classroom_invigilator_count,
          invigilator_assign_type: invigilator_assign_type,
        }

        InvigilatorSettingsByFaculty.push(model);
        this.state.invigilator_settings_by_faculty = JSON.stringify(
          InvigilatorSettingsByFaculty
        );

        InvigilatorSettingsByFacultyFielsIsEmpty = false;
        InvigilatorSettingsByFacultyAddButtonIsActive = false;
      } else {
        InvigilatorSettingsByFacultyAddButtonIsActive = true;
      }
    }

    this.setState(this.state);
  };

  onDeleteInvigilatorSettingsByFacultyRow = (index: any) => {
    InvigilatorSettingsByFaculty.splice(index, 1);
    this.state.invigilator_settings_by_faculty = JSON.stringify(
      InvigilatorSettingsByFaculty
    );
    this.setState(this.state);
  };

  findDuplicates = (arr: any) => {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  render() {
    const lang = localStorage.getItem("langid");
    L.data('aplan_settings_xx_lang', lang);

    if (this.state && this.state.invigilator_counts_due_to_student_count) {
      invigilatorCountsDueToStudentCount = JSON.parse(this.state.invigilator_counts_due_to_student_count);
    }

    let numOfInvigilators: Array<number> = invigilatorCountsDueToStudentCount.map((item: any) => Number(item.num_of_invigilators));
    if (this.findDuplicates(numOfInvigilators).length != 0) {
      validationInvigilatorByStudent = true;
    } else {
      validationInvigilatorByStudent = false;
    }

    if (prevInvigilatorCountsDueToStudentCount.length == 0) {
      prevInvigilatorCountsDueToStudentCount = invigilatorCountsDueToStudentCount;
    }

    if (this.state && this.state.invigilator_settings_by_faculty) {
      InvigilatorSettingsByFaculty = JSON.parse(this.state.invigilator_settings_by_faculty);
    }

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="building-page">
          <div className="main">
            <div className="container">
              <div className="row align-items-center mb-sm-4 mb-2">
                <div className="col-12">
                  <h4 className="mb-4 mb-sm-0">{T.t('gen_settings')}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link active"
                        id="general-tab"
                        data-toggle="tab"
                        href="#general"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('settings_institution')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="planning-tab"
                        data-toggle="tab"
                        href="#term"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_term')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="calendar-tab"
                        data-toggle="tab"
                        href="#calendar"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_calendar')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="email-tab"
                        data-toggle="tab"
                        href="#emailservice"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_email')}
                      </a>
                    </div>
                  </nav>
                  <Formik
                    enableReinitialize={true}
                    initialValues={this.state}
                    onSubmit={(values, actions) => {
                      this.saveSettings(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.IAplanSettingsPageState>) => {
                      const { values, handleChange, handleBlur, handleSubmit } = props;
                      values.invigilator_counts_due_to_student_count = this.state.invigilator_counts_due_to_student_count;




                      if (!!values.classroom_type) {
                        classroomTypes = JSON.parse(this.state.classroom_types);
                        classroomTypes = classroomTypes.filter(classroomType => classroomType.name.toLowerCase().includes(values.classroom_type.toLowerCase()));
                      } else {
                        if (this.state && this.state.classroom_types) {
                          classroomTypes = JSON.parse(this.state.classroom_types);
                        }
                      }

                      return (
                        <form onSubmit={props.handleSubmit}>
                          <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general">
                              <div className="row mb-4">
                                <div className="col-md-4 form-input form-group with-icon">
                                  <h5>{T.t('settings_institution_info')}</h5>
                                  <input
                                    name="institution_name"
                                    id="institution_name"
                                    type="text"
                                    value={values.institution_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="institution_name" className="settings-label">{T.t('settings_institution_name')}</label>
                                </div>
                                <div className="col-md-4 form-input form-group with-icon">
                                  <h5 className="settings-radio-button-h5">{T.t('settings_instructor_entry_type')}</h5>
                                  <div className="tick-radio position-relative settings-radio-button">
                                    <input
                                      id='email'
                                      type="radio"
                                      name="user_entry_type"
                                      value={0}
                                      checked={values.user_entry_type == 0}
                                      onChange={handleChange}
                                      className="settings-radio-button-input"
                                    />
                                    <label className="settings-radio-button-label0">{T.t('gen_email')}</label>
                                  </div>
                                  <div className="tick-radio position-relative settings-radio-button">
                                    <input
                                      id='instructor_code'
                                      type="radio"
                                      name="user_entry_type"
                                      value={1}
                                      checked={values.user_entry_type == 1}
                                      onChange={handleChange}
                                      className="settings-radio-button-input"
                                    />
                                    <label className="settings-radio-button-label1">{T.t('gen_instructor_code')}</label>
                                  </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                  <h5 className="settings-radio-button-h5">
                                    <label className="logo-title-center">{T.t('settings_institution_logo')}</label></h5>
                                  <div className="text-gray form-group">
                                    <div className="form-input">
                                      <ImageHolder
                                        src={this.state.institution_logo == null ? '' : this.state.institution_logo}
                                        default={imgLogo}
                                        title="200 X 200 px"
                                        onClick={this.onBrandLogoUploadRequest}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <button id='button_save' type="button" className="btn btn-green" onClick={() => props.handleSubmit()}>
                                    <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="term" role="tabpanel" aria-labelledby="term">
                              <div className="row mb-4">
                                <div className="col-md-6">
                                  <h6>{T.t('settings_term_department_heads_date_range')}</h6>
                                  <div className="form-input form-group with-icon">
                                    <Flatpickr
                                      id='select_dates'
                                      placeholder={T.t('gen_select_dates')}
                                      value={this.state.department_heads_date_range}
                                      options={{
                                        dateFormat: 'Y-m-d',
                                        mode: 'range',
                                        locale: this.state.locale,
                                        defaultDate: this.state.department_heads_date_range
                                      }}
                                      onClose={(value) => {
                                        values.department_heads_date_range = value.map((item) => moment(item).format('YYYY-MM-DD'));
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <h6>{T.t('settings_term_instructors_date_range')}</h6>
                                  <div className="form-input form-group with-icon">
                                    <Flatpickr
                                      id='select_date'
                                      placeholder={T.t('gen_select_dates')}
                                      value={this.state.instructors_date_range}
                                      options={{
                                        dateFormat: 'Y-m-d',
                                        mode: 'range',
                                        locale: this.state.locale,
                                        defaultDate: this.state.instructors_date_range
                                      }}
                                      onClose={(value) => {
                                        values.instructors_date_range = value.map((item) => moment(item).format('YYYY-MM-DD'));
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <hr />
                                  <h6>{T.t('settings_system_will_be_opened_for')}</h6>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        id='settings_term_full_time_instructors'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={this.state.allowed_staff && this.state.allowed_staff.find((item: number) => item == 2) == 2 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.allowed_staff;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(2);
                                            } else {
                                              let index = selectedList.indexOf(2);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.allowed_staff = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('settings_term_full_time_instructors')}
                                    </label>
                                  </div>
                                  <div className="tick-radio position-relative d-block">
                                    <label style={{ cursor: 'pointer' }}>
                                      <input
                                        id='settings_term_part_time_instructors'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={this.state.allowed_staff && this.state.allowed_staff.find((item: number) => item == 1) == 1 ? true : false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          if (e && e.currentTarget) {
                                            let selectedList = this.state.allowed_staff;
                                            if (e.currentTarget.checked) {
                                              selectedList.push(1);
                                            } else {
                                              let index = selectedList.indexOf(1);
                                              selectedList.splice(index, 1);
                                            }
                                            this.state.allowed_staff = selectedList;
                                            this.setState(this.state);
                                          }
                                        }}
                                      />
                                      &nbsp;{T.t('settings_term_part_time_instructors')}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <hr />
                                  <h6>{T.t('settings_secondary_education_start_hour')}</h6>
                                  <div className="form-input form-group with-icon">
                                    <Flatpickr
                                      id='secondary_education_start_hour'
                                      value={this.state.secondary_education_start_hour}
                                      placeholder={T.t('gen_select_time')}
                                      options={{
                                        enableTime: true,
                                        dateFormat: 'H:i',
                                        noCalendar: true,
                                        time_24hr: true,
                                        defaultHour: 17
                                      }}
                                      onChange={(value) => {
                                        let hour = moment(value[0]).format('HH:mm');
                                        values.secondary_education_start_hour = hour;
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <hr />
                                  <h6>{T.t('gen_classroom_types')}</h6>
                                  <div className="row align-items-center">
                                    <div className="col-md-10">
                                      <div className="form-input form-group date-picker">
                                        <input
                                          id="classroom_type"
                                          name="classroom_type"
                                          value={values.classroom_type}
                                          onChange={handleChange}
                                          type="text"
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="classroom_type">{T.t('gen_enter_classroom_type')}</label>
                                        <i className="material-icons">playlist_add</i>
                                      </div>
                                      {<div className="col-md-12 list-error">
                                        {
                                          classroomTypesAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : null
                                        }
                                      </div>}
                                    </div>
                                    <div className="col-md-2">
                                      <button
                                        id='button_add_circle_outline'
                                        className="mss-table-add-button"
                                        disabled={!values.classroom_type ? true : false}
                                        onClick={() => this.onAddClassroomTypeRow(values.classroom_type)}
                                      >
                                        <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                      </button>
                                    </div>
                                    <div className="col-md-11">
                                      <div className="table-scrollable-td">
                                        <table className="aplan-table table">
                                          <tbody>
                                            {classroomTypes.map((item: any, _index: number) => (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '90%' }}>
                                                  <input
                                                    id='classroom_type'
                                                    name={'classroom_type-' + _index}
                                                    value={item.name}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.classroom_type = e.currentTarget.value;
                                                      this.state.classroom_types = JSON.stringify(
                                                        classroomTypes
                                                      );
                                                      values.classroom_types = JSON.stringify(
                                                        classroomTypes
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.onDeleteClassroomTypeRow(_index)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.props.term_type === 0 ? (
                                  <React.Fragment>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_course_duration_without_break')}</h6>
                                      <div className="form-input form-group with-icon">
                                        <Flatpickr
                                          id='course_duration'
                                          value={this.state.course_duration}
                                          placeholder={T.t('gen_select_time')}
                                          options={{
                                            enableTime: true,
                                            dateFormat: 'H:i',
                                            noCalendar: true,
                                            time_24hr: true,
                                            minTime: '00:05',
                                            maxTime: '03:00',
                                            defaultHour: 1
                                          }}
                                          onChange={(value) => {
                                            let hour = moment(value[0]).format('HH:mm');
                                            values.course_duration = hour;
                                            this.setState(this.state);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_gap_between_campuses')}</h6>
                                      <div className="tick-radio position-relative d-block">
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_check_program_campus_gap'
                                            type="checkbox"
                                            className="form-radio"
                                            checked={this.state.gap_between_campuses && this.state.gap_between_campuses.find((item: number) => item == 0) == 0 ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (e && e.currentTarget) {
                                                let selectedList = this.state.gap_between_campuses;
                                                if (e.currentTarget.checked) {
                                                  selectedList.push(0);
                                                } else {
                                                  let index = selectedList.indexOf(0);
                                                  selectedList.splice(index, 1);
                                                }
                                                this.state.gap_between_campuses = selectedList;
                                                this.setState(this.state);
                                              }
                                            }}
                                          />
                                          &nbsp;{T.t('settings_check_program_campus_gap')}
                                        </label>
                                      </div>
                                      <div className="tick-radio position-relative d-block">
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_check_instructor_campus_gap'
                                            type="checkbox"
                                            className="form-radio"
                                            checked={this.state.gap_between_campuses && this.state.gap_between_campuses.find((item: number) => item == 1) == 1 ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (e && e.currentTarget) {
                                                let selectedList = this.state.gap_between_campuses;
                                                if (e.currentTarget.checked) {
                                                  selectedList.push(1);
                                                } else {
                                                  let index = selectedList.indexOf(1);
                                                  selectedList.splice(index, 1);
                                                }
                                                this.state.gap_between_campuses = selectedList;
                                                this.setState(this.state);
                                              }
                                            }}
                                          />
                                          &nbsp;{T.t('settings_check_instructor_campus_gap')}
                                        </label>
                                      </div>
                                      <div className="tick-radio position-relative d-block">
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            type="checkbox"
                                            id="radio-active"
                                            className="form-radio"
                                            checked={this.state.gap_between_campuses && this.state.gap_between_campuses.find((item: number) => item == 2) == 2 ? true : false}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              if (e && e.currentTarget) {
                                                let selectedList = this.state.gap_between_campuses;
                                                if (e.currentTarget.checked) {
                                                  selectedList.push(2);
                                                } else {
                                                  let index = selectedList.indexOf(2);
                                                  selectedList.splice(index, 1);
                                                }
                                                this.state.gap_between_campuses = selectedList;
                                                this.setState(this.state);
                                              }
                                            }}
                                          />
                                          &nbsp;{T.t('settings_check_student_campus_gap')}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_place_duty_invigilator_assignments')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_place_duty_invigilator_assignments_opt1'
                                          type="radio"
                                          name="place_duty_invigilator_assignments"
                                          value={1}
                                          checked={values.place_duty_invigilator_assignments == "1"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_place_duty_invigilator_assignments_opt1')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_place_duty_invigilator_assignments_opt2'
                                          type="radio"
                                          name="place_duty_invigilator_assignments"
                                          value={0}
                                          checked={values.place_duty_invigilator_assignments == "0"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_place_duty_invigilator_assignments_opt2')}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h6>{T.t('settings_invigilator_settings')}</h6>
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_invigilator_settings_opt1'
                                          type="radio"
                                          name="invigilator_settings_collapse"
                                          value={0}
                                          checked={values.invigilator_settings_collapse == "0"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_assignment_to_all_faculties')}
                                      </label>
                                      &nbsp;&nbsp;&nbsp;
                                      <label style={{ cursor: 'pointer' }}>
                                        <input
                                          id='settings_invigilator_settings_opt2'
                                          type="radio"
                                          name="invigilator_settings_collapse"
                                          value={1}
                                          checked={values.invigilator_settings_collapse == "1"}
                                          onChange={handleChange}
                                        />
                                        &nbsp;{T.t('settings_appointment_according_to_certain_faculties')}
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <hr />
                                      <h5>{T.t('gen_exam_type')}</h5>
                                      <div className="row align-items-center">
                                        <div className="col-md-6">
                                          <h6>{T.t('gen_assigment_project_report_submission')}</h6>
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='assign_invigilator_opt1'
                                              type="radio"
                                              name="assigment_project_report_submission"
                                              value={1}
                                              checked={values.assigment_project_report_submission == "1"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_assign_invigilator')}
                                          </label>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='do_not_assign_invigilator_opt1'
                                              type="radio"
                                              name="assigment_project_report_submission"
                                              value={0}
                                              checked={values.assigment_project_report_submission == "0"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_do_not_assign_invigilator')}
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          <h6>{T.t('gen_jury_defense')}</h6>
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='assign_invigilator_opt2'
                                              type="radio"
                                              name="jury_defense"
                                              value={1}
                                              checked={values.jury_defense == "1"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_assign_invigilator')}
                                          </label>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <label style={{ cursor: 'pointer' }}>
                                            <input
                                              id='do_not_assign_invigilator_opt2'
                                              type="radio"
                                              name="jury_defense"
                                              value={0}
                                              checked={values.jury_defense == "0"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('gen_do_not_assign_invigilator')}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`col-md-6 collapse ` + (values.invigilator_settings_collapse == "0" ? `show` : ``)}
                                      id="settings_classroom_invigilator_count_settings"
                                    >
                                      <div className="col-md-12">
                                        <hr />
                                        <h6>{T.t('settings_classroom_invigilator_count_settings')}</h6>
                                        <div className="tick-radio position-relative settings-radio-button">
                                          <label className="classroom_invigilation_count_0" style={{ cursor: 'pointer' }}>
                                            <input
                                              id='settings_classroom_invigilator_count_settings_opt1'
                                              type="radio"
                                              name="classroom_invigilation_count"
                                              value={0}
                                              checked={values.classroom_invigilation_count == "0"}
                                              onChange={handleChange}
                                            />
                                            &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt1')}
                                          </label>
                                        </div>
                                        <label className="classroom_invigilation_count_1" style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_classroom_invigilator_count_settings_opt2'
                                            type="radio"
                                            name="classroom_invigilation_count"
                                            value={1}
                                            checked={values.classroom_invigilation_count == "1"}
                                            onChange={handleChange}
                                          />
                                          &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt2')}
                                        </label>
                                        <label className="classroom_invigilation_count_2" style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_classroom_invigilator_count_settings_opt3'
                                            type="radio"
                                            name="classroom_invigilation_count"
                                            value={2}
                                            checked={values.classroom_invigilation_count == "2"}
                                            onChange={handleChange}
                                          />
                                          &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt3')}
                                        </label>
                                      </div>
                                      <div className="col-md-12">
                                        <hr />
                                        <h6>{T.t('settings_invigilator_assign_type')}</h6>
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_invigilator_assign_type_opt1'
                                            type="radio"
                                            name="invigilator_assignment_type"
                                            value={0}
                                            checked={values.invigilator_assignment_type == "0"}
                                            onChange={handleChange}
                                          />
                                          &nbsp;{T.t('settings_invigilator_assign_type_opt1')}
                                        </label>
                                        <label style={{ cursor: 'pointer' }}>
                                          <input
                                            id='settings_invigilator_assign_type_opt2'
                                            type="radio"
                                            name="invigilator_assignment_type"
                                            value={1}
                                            checked={values.invigilator_assignment_type == "1"}
                                            onChange={handleChange}
                                          />
                                          &nbsp;{T.t('settings_invigilator_assign_type_opt2')}
                                        </label>
                                      </div>
                                      <div
                                        className={`col-md-12 collapse ` + (values.invigilator_assignment_type == '1' ? `show` : ``)}
                                        id="advance-search"
                                      >
                                        <hr />
                                        <h6>{T.t('settings_student_invigilator_count')}</h6>
                                        <div style={{ color: 'red' }}>
                                          {validationInvigilatorByStudent ? T.t('validation_invigilator_count') : null}
                                        </div>
                                        <table className="aplan-table table">
                                          <thead>
                                            <tr>
                                              <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_minimum')}</th>
                                              <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_maximum')}</th>
                                              <th scope="col" style={{ 'width': '10%' }}>{T.t('gen_invigilator_count')}</th>
                                              <th scope="col" style={{ 'width': '10%' }}>
                                                <button
                                                  id='button_add_circle_outline'
                                                  className="mss-table-add-button"
                                                  onClick={() => this.onAddRow(invigilatorCountsDueToStudentCount)}
                                                >
                                                  <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                                </button>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {invigilatorCountsDueToStudentCount.map((item: any, _index: number) => (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <input
                                                    id='student_invigilator_number_min'
                                                    name={'student-invigilator-number-min-' + _index}
                                                    min={Number(prevInvigilatorCountsDueToStudentCount[_index].min)}
                                                    max={Number(prevInvigilatorCountsDueToStudentCount[_index].max - 1)}
                                                    value={item.min}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.min = e.currentTarget.value;
                                                      this.state.invigilator_counts_due_to_student_count = JSON.stringify(
                                                        invigilatorCountsDueToStudentCount
                                                      );
                                                      values.invigilator_counts_due_to_student_count = JSON.stringify(
                                                        invigilatorCountsDueToStudentCount
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="number"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <input
                                                    id='student_invigilator_number_max'
                                                    name={'student-invigilator-number-max-' + _index}
                                                    min={Number(prevInvigilatorCountsDueToStudentCount[_index].min) + 1}
                                                    max={Number(prevInvigilatorCountsDueToStudentCount[_index + 1] ? prevInvigilatorCountsDueToStudentCount[_index + 1].min - 1 : null)}
                                                    value={item.max}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.max = e.currentTarget.value;
                                                      this.state.invigilator_counts_due_to_student_count = JSON.stringify(
                                                        invigilatorCountsDueToStudentCount
                                                      );
                                                      values.invigilator_counts_due_to_student_count = JSON.stringify(
                                                        invigilatorCountsDueToStudentCount
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="number"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <input
                                                    id='student_invigilator_number'
                                                    name={'student-invigilator-number-num_of_invigilators-' + _index}
                                                    value={item.num_of_invigilators}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                      item.num_of_invigilators = e.currentTarget.value;
                                                      this.state.invigilator_counts_due_to_student_count = JSON.stringify(
                                                        invigilatorCountsDueToStudentCount
                                                      );
                                                      values.invigilator_counts_due_to_student_count = JSON.stringify(
                                                        invigilatorCountsDueToStudentCount
                                                      );
                                                      this.setState(this.state);
                                                    }}
                                                    type="number"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_outline'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.onDeleteRow(_index)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className={`col-md-6 collapse ` + (values.invigilator_settings_collapse == "1" ? `show` : ``)}
                                      id="settings_number_invigilator_assignment_invigilator_faculties"
                                    >
                                      <hr />
                                      <h6>{T.t('settings_number_invigilator_assignment_invigilator_faculties')}</h6>
                                      <div className="col-md-12 list-error">
                                        {
                                          InvigilatorSettingsByFacultyAddButtonIsActive ?
                                            <span>{T.t('gen_entered_value_already_exists_list')}</span>
                                            : InvigilatorSettingsByFacultyFielsIsEmpty ?
                                              <span>{T.t('gen_info_cannot_leave_empty')}</span>
                                              : null
                                        }
                                      </div>
                                      <table className="aplan-table table">
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ 'width': '30%' }}>
                                              <div className="add-custom-tag">
                                                <div className="react-select-container">
                                                  <label>{T.t('gen_faculty')}</label>
                                                  <Select
                                                    id='select_faculty'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={
                                                      this.props.selectOptions && this.props.selectOptions.faculties_code
                                                        ? this.props.selectOptions.faculties_code
                                                        : []
                                                    }
                                                    placeholder={T.t('gen_select_faculty')}
                                                    value={values.faculty}
                                                    onChange={(option: any) => {
                                                      props.setFieldValue('faculty', option);
                                                      props.setFieldValue('faculty_code', option && option.value);
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                                  />
                                                </div>
                                              </div>
                                            </th>
                                            <th scope="col" style={{ 'width': '30%' }}>
                                              <div className="add-custom-tag">
                                                <div className="react-select-container">
                                                  <label>{T.t('settings_classroom_invigilator_count_settings')}</label>
                                                  <Select
                                                    id='select_classroom_invigilator_count_settings'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={ClassroomInvigilatorCountSettings(T)}
                                                    placeholder={T.t('settings_classroom_invigilator_count_setting_select')}
                                                    value={
                                                      values.classroom_invigilator_count != null
                                                        ? ClassroomInvigilatorCountSettings(T).find((item: Types.ISelectOption) => item.value === values.classroom_invigilator_count)
                                                        : null
                                                    }
                                                    onChange={(option: any) => props.setFieldValue('classroom_invigilator_count', option.value)}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_classroom_invigilator_count')}
                                                  />
                                                </div>
                                                {values.classroom_invigilator_count === undefined && values.faculty && (
                                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                                )}
                                              </div>
                                            </th>
                                            <th scope="col" style={{ 'width': '30%' }}>
                                              <div className="add-custom-tag">
                                                <div className="react-select-container">
                                                  <label>{T.t('settings_invigilator_assign_type')}</label>
                                                  <Select
                                                    id='select_settings_invigilator_assign_type'
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={InvigilatorAssignTypes(T)}
                                                    placeholder={T.t('settings_invigilator_assign_type_select')}
                                                    value={
                                                      values.invigilator_assign_type != null
                                                        ? InvigilatorAssignTypes(T).find((item: Types.ISelectOption) => item.value === values.invigilator_assign_type)
                                                        : null
                                                    }
                                                    onChange={(option: any) => props.setFieldValue('invigilator_assign_type', option.value)}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_invigilator_assign_type')}
                                                  />
                                                </div>
                                                {values.invigilator_assign_type === undefined && values.faculty && (
                                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                                )}
                                              </div>
                                            </th>
                                            <th scope="col" style={{ 'width': '10%' }}>
                                              <div className="pb-3">
                                                <button
                                                  id='button_add_invigilator_settings_by_faculty'
                                                  className="mss-table-add-button"
                                                  disabled={(values.classroom_invigilator_count === undefined || values.invigilator_assign_type === undefined) && values.faculty ? true : false}
                                                  onClick={() => this.onAddInvigilatorSettingsByFacultyRow(values.faculty, values.classroom_invigilator_count, values.invigilator_assign_type)}
                                                >
                                                  <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                                </button>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {InvigilatorSettingsByFaculty.map((item: any, _index: number) => {
                                            let ClassroomInvigilatorCountObject = ClassroomInvigilatorCountSettings(T).find(
                                              (object) => object.value == item.invigilator_assign_type
                                            );

                                            let InvigilatorAssignTypeObject = InvigilatorAssignTypes(T).find(
                                              (object) => object.value == item.invigilator_assign_type
                                            );
                                            return (
                                              <tr key={_index}>
                                                <td className="form-input" style={{ 'width': '30%' }}>
                                                  <input
                                                    id='faculty'
                                                    name={'faculty-' + _index}
                                                    value={item.faculty_name}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '30%' }}>
                                                  <input
                                                    id='classroom_invigilator_count'
                                                    name={'classroom_invigilator_count-' + _index}
                                                    value={ClassroomInvigilatorCountObject && ClassroomInvigilatorCountObject.label ? ClassroomInvigilatorCountObject && ClassroomInvigilatorCountObject.label : '-'}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '30%' }}>
                                                  <input
                                                    id='invigilator_assign_type'
                                                    name={'invigilator_assign_type-' + _index}
                                                    value={InvigilatorAssignTypeObject && InvigilatorAssignTypeObject.label ? InvigilatorAssignTypeObject && InvigilatorAssignTypeObject.label : '-'}
                                                    type="text"
                                                    required
                                                  />
                                                </td>
                                                <td className="form-input" style={{ 'width': '10%' }}>
                                                  <button
                                                    id='button_delete_invigilator_settings_by_faculty'
                                                    className="ms-table-delete-button"
                                                    onClick={() => this.onDeleteInvigilatorSettingsByFacultyRow(_index)}
                                                  >
                                                    <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <button
                                    id='save'
                                    type="button"
                                    className="btn btn-green float-right"
                                    onClick={
                                      validationInvigilatorByStudent ?
                                        () => { } :
                                        () => { props.handleSubmit() }
                                    }
                                  >
                                    <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane fade" id="calendar" role="tabpanel" aria-labelledby="calendar">
                              <CalendarSettings otherSettings={this.state} />
                            </div>
                            <div className="tab-pane fade" id="emailservice" role="tabpanel" aria-labelledby="emailservice">
                              <div className="row mb-4">
                                <div className="col-6 col-md-6 col-sm-6">
                                  <div className="react-select-container">
                                    <label>{T.t('settings_mail_service')}</label>
                                    <Select
                                      id='select_service'
                                      className="react-select"
                                      isSearchable={false}
                                      placeholder={T.t('gen_select_service')}
                                      options={EmailServiceSelectOptions(T)}
                                      value={EmailServiceSelectOptions(T).find((item) => item.value == this.state.service)}
                                      onChange={(item: any) => {
                                        values.service = item.value;
                                        this.setState(this.state);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 form-input form-group with-icon mt-3">
                                  <input
                                    name="email-sender"
                                    id="email-sender"
                                    type="mail"
                                    required
                                    value={values.sending_adress}
                                    onChange={(e: any) => {
                                      values.sending_adress = e.currentTarget.value;
                                      this.setState(this.state);
                                    }}
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="email-sender">{T.t('settings_mail_sending_mail_address')}</label>
                                </div>
                                <div className="col-6 col-md-6 col-sm-6">
                                  <div className="react-switch-container">
                                    <label>{T.t('settings_mail_validation')}</label>
                                    <Switch
                                      id="send_email"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.allow_validation = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.allow_validation}
                                    />
                                  </div>
                                  <div className="react-switch-container">
                                    <label>{T.t('settings_mail_send_mail_on_user_created')}</label>
                                    <Switch
                                      id="send_email_on_user_created"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.send_email_on_user_created = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.send_email_on_user_created}
                                    />
                                  </div>
                                  <div className="react-switch-container">
                                    <label>{T.t('settings_mail_send_solution_is_changed')}</label>
                                    <Switch
                                      id="send_email_solution_is_changed"
                                      className="react-switch"
                                      onChange={(checked: boolean) => {
                                        values.send_email_solution_is_changed = checked;
                                        this.setState(this.state);
                                      }}
                                      checked={values.send_email_solution_is_changed}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <button id='save_button' type="button" className="btn btn-green" onClick={() => props.handleSubmit()}>
                                    <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
      </MainLayout >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    results: store.state.building_page && store.state.building_page.results,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    term_type: store.state.term_type,
    settings: store.state.settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(APlanSettings);

export default container;
