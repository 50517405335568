import { constants } from '../constants/classroom-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const classroomReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.CLASSROOM_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          classroom_page: {
            ...state.classroom_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : []
          }
        };
      }
    }
    case constants.CLASSROOM_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          classroom_page: {
            ...state.classroom_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.CLASSROOM_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            classroomPage: result.content
          }
        });
      }
      return state;
    }
    case constants.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            buildings_related_campuses: result.content.buildings_related_campuses
          }
        });
      }
      return state;
    }
    case constants.CLASSROOM_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          classroom_import_result: result.content
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default classroomReducer;
