import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paginate from '../../../components/table/paginate';
import SortedColumn from '../../../components/table/sorted-column';
import Announce from '../../../components/templates/announce';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import ImportModal, { ExcelImportKeys } from '../../../components/excel-imports/import-modal';
import { StudentSearchInitialValues } from '../../../store/constants/exam-period-const';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import SyncStudentModal from './students-sync-modal';
import DownloadButton from '../../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../../store/constants/enums';
import Translator from '../../../services/translate-factory';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';

const T = Translator.create();
const L = Log.create('StudentAddModal');

class StudentAddModal extends Component<Types.IStudentPageProps, Types.IStudentPageState> {
  state: Types.IStudentPageState = {
    filters: StudentSearchInitialValues,
    integrationModalIsOpen: false,
    filterIsOpen: false,
    student_list: [],
    selected_ids: [],
    all_ids: []
  };

  componentDidMount() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.getProgramsByFacultiesAtStudents([]);
  }

  getStudents() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_STUDENTS, this.state.filters, 'ep-student-list')
    );
  }

  getStudentSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_GET_SELECT_OPTIONS, 'student-list-spin'));
  }

  getProgramsByFacultiesAtStudents = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'student-list-spin'));
  }

  onStudentImported = () => {
    this.getStudents();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getStudents();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getStudents();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, StudentSearchInitialValues);
    this.setState(this.state);
    this.getStudents();
  };

  onSelectStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringId: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringId, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        // this.setState({
        //   ...this.state,
        //   filters: {
        //     ...this.state.filters,
        //     select_all: false
        //   }
        // });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_STUDENTS,
            this.state.filters,
            'ep-course-list',
            (response: any) =>
              this.setState({
                ...this.state,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
    // if (e && e.currentTarget) {
    //   if (e.currentTarget.checked) {
    //     let all_ids: Array<number> = [];
    //     this.state.student_list && this.state.student_list.map((item: any) => all_ids.push(item.student_id));
    //     this.setState({
    //       ...this.state,
    //       selected_ids: [...this.state.selected_ids, ...all_ids]
    //     });
    //   } else {
    //     this.setState({
    //       ...this.state,
    //       selected_ids: []
    //     });
    //   }
    // }
  };

  checker = (arr: Array<number>, target: Array<number>): boolean => {
    if (arr.length && target.length) {
      return target.every((item: number) => arr.includes(item));
    } else return false;
  };

  deleteStudents = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_selected_students_delete_question'),
          name: 'examPeriod_instructors_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setState(
                  {
                    ...this.state,
                    filters: {
                      ...this.state.filters,
                      delete_list: []
                    }
                  },
                  () => {
                    this.getStudents();
                  }
                );
                this.state.filters.delete_list = [];
                this.state.selected_ids = [];
                this.setState(this.state);
                this.getStudents();
              }
            };
            /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
            let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
            var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
            let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
            this.state.filters.term_id = id;
            this.state.filters.delete_list = this.state.selected_ids;

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_STUDENTS_FROM_PERIOD,
                this.state.filters,
                'ep-instructor-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  deleteAllStudents = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_all_students_delete_question'),
          name: 'examPeriod_instructors_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setState(
                  {
                    ...this.state,
                    filters: {
                      ...this.state.filters,
                      delete_list: []
                    }
                  },
                  () => {
                    this.getStudents();
                  }
                );
                this.state.filters.delete_list = [];
                this.state.filters.delete_all = false;
                this.state.selected_ids = [];
                this.setState(this.state);
                this.getStudents();
              }
            };

            this.state.filters.delete_all = true;

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_ALL_STUDENTS_FROM_PERIOD,
                this.state.filters,
                'ep-instructor-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onFilterStudent(model: Types.IFilterStudent, FormActions: FormikActions<Types.IFilterStudent>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getStudents();
    FormActions.setSubmitting(false);
  }

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  showInfoModalCheckStudents = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_students_check_data_title'),
          body: T.t('gen_students_check_data_body'),
          name: 'check students',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setState(
                  {
                    ...this.state,
                    filters: {
                      ...this.state.filters,
                      delete_list: []
                    }
                  },
                  () => {
                    this.getStudents();
                  }
                );
                this.state.filters.delete_list = [];
                this.state.selected_ids = [];
                this.setState(this.state);
                this.getStudents();
              }
            };
            const model = {
              termId: this.state.filters.term_id
            };
            this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_CHECK, model, '', resultCallback));
            this.props.dispatch(Actions.Notification('gen_please_wait_extended', 'gen_warning', 'warning'));
          }
        })
      );
    }
  };

  showInfoModalUpdateCourses = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_students_update_courses_title'),
          body: T.t('gen_students_update_courses_body'),
          name: 'student update courses',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setState(
                  {
                    ...this.state,
                    filters: {
                      ...this.state.filters,
                      delete_list: []
                    }
                  },
                  () => {
                    this.getStudents();
                  }
                );
                this.state.filters.delete_list = [];
                this.state.selected_ids = [];
                this.setState(this.state);
                this.getStudents();
              }
            };
            const model = {
              termId: this.state.filters.term_id
            };
            this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_UPDATE_COURSES, model, '', resultCallback));
            this.props.dispatch(Actions.Notification('gen_please_wait_extended', 'gen_warning', 'warning'));
          }
        })
      );
    }
  };

  static getDerivedStateFromProps(props: Types.IStudentPageProps, state: Types.IStudentPageState) {
    let hasNewState: boolean = false;
    if (props.studentList) {
      hasNewState = true;
      state.student_list = props.studentList;
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let studentList = this.props.studentList && this.props.studentList.length ? this.props.studentList : [];

    let all_ids_on_list: Array<number> = [];
    let a =
      studentList && studentList.length
        ? studentList.map((item: Types.IStudentItem) => {
          all_ids_on_list.push(item && item.student_id ? item.student_id : -1);
        })
        : null;

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            {this.state.selected_ids && this.state.selected_ids.length ? (
              <React.Fragment>
                <button
                  id='button_close'
                  className="category-tag-square tag-gray float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      selected_ids: []
                    });
                  }}
                >
                  <i className="material-icons mr-2">close</i>
                  <span>
                    {T.t('gen_cancel_selection')}
                    <b>&nbsp;({this.state.selected_ids.length})</b>
                  </span>
                </button>
                <button
                  id='button_delete_outline'
                  className="category-tag-square tag-red float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={this.deleteStudents}
                >
                  <i className="material-icons mr-2">delete_outline</i>
                  <span>{T.t('gen_delete_selected')}</span>
                </button>
                <button
                  id='button_delete'
                  className="category-tag-square tag-red float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={this.deleteAllStudents}
                >
                  <i className="material-icons mr-2">delete_outline</i>
                  <span>
                    {T.t('gen_delete_all')}
                    <b>&nbsp;({this.props.filters && this.props.filters.total})</b>
                  </span>
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ImportModal
                  title={T.t('gen_add_with_excel')}
                  componentKey={ExcelImportKeys.Students}
                  dispatch={this.props.dispatch}
                  onImport={this.onStudentImported}
                />
                <button id='playlist_add' className="category-tag-square tag-green ml-1" onClick={this.switchIntegrationModalStatus}>
                  <i className="material-icons mr-2">playlist_add</i>
                  {T.t('gen_list_sync_jobs')}
                </button>
                <button id='student_check_data' className="category-tag-square tag-orange ml-1" onClick={this.showInfoModalCheckStudents}>
                  <i className="material-icons mr-2">done_all</i>
                  <span>{T.t('gen_check_data')}</span>
                </button>
                <button id='student_update_course' className="category-tag-square tag-orange ml-1" onClick={this.showInfoModalUpdateCourses}>
                  <i className="material-icons mr-2">cached</i>
                  <span>{T.t('gen_update_courses')}</span>
                </button>
                <DownloadButtonSyncResult title={'SyncStudentsResult'} sectionType={SectionTypes.SyncStudentsResult} />
                <DownloadButton title={'Öğrenciler'} sectionType={SectionTypes.Students} />
              </React.Fragment>
            )}
            <button
              id='button_filter'
              className="category-tag-square tag-glass float-right ml-3 mr-3"
              style={{ margin: '5px' }}
              onClick={() => {
                this.state.filterIsOpen = !this.state.filterIsOpen;
                this.setState(this.state);
              }}
            >
              <i className="material-icons mr-2">filter_list</i>
              <span>{T.t('gen_filter')}</span>
            </button>
          </div>
        </div>
        <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={StudentSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                values.term_id = this.state.filters.term_id;
                this.onFilterStudent(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterStudent>) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-2">
                        <div className="form-input form-group date-picker">
                          <input
                            id="person_unique_id"
                            name="person_unique_id"
                            value={props.values.person_unique_id}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="person_unique_id">{T.t('gen_student_id')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-input form-group date-picker">
                          <input
                            id="student_number"
                            name="student_number"
                            value={props.values.student_number}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="student_number">{T.t('gen_student_number')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-input form-group date-picker">
                          <input
                            id="name"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="name">{T.t('gen_name_surname')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-input form-group date-picker">
                          <input
                            id="class"
                            name="class"
                            value={props.values.class}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="class">{T.t('gen_grade_course')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-input form-group date-picker">
                          <input
                            id="group"
                            name="group"
                            value={props.values.group}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="group">{T.t('gen_section')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-input form-group date-picker">
                          <input
                            id="course_code"
                            name="course_code"
                            value={props.values.course_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="course_code">{T.t('gen_course_code')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-input form-group date-picker">
                          <input
                            id="course_name"
                            name="course_name"
                            value={props.values.course_name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="course_name">{T.t('gen_course_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_faculty')}</label>
                            <Select
                              id='select_faculty'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.faculties
                                  ? this.props.selectOptions.faculties
                                  : []
                              }
                              placeholder={T.t('gen_select_faculty')}
                              value={props.values.course_faculties ? props.values.course_faculties : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_faculties', list);
                                props.setFieldValue(
                                  'course_faculty_codes',
                                  list.map((item) => item.value)
                                );
                                this.getProgramsByFacultiesAtStudents(list.map(item => item.value))
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_registered_program')}</label>
                            <Select
                              id='select_program'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={this.props.enrolled_programs_related_faculty}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.enrolled_programs}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('enrolled_programs', list);
                                props.setFieldValue(
                                  'enrolled_program_codes',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_program')}</label>
                            <Select
                              id='select_programs'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={this.props.course_programs_related_faculty}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.course_programs}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_programs', list);
                                props.setFieldValue(
                                  'course_program_codes',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upward'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>
              <tr>
                <th data-cell="select">
                  <div className="tick-radio position-relative">
                    <input
                      id='select_all'
                      type="checkbox"
                      className="form-radio"
                      checked={this.checkAllSelected()}
                      onChange={this.onSelectAll}
                    />
                  </div>
                </th>
                <SortedColumn
                  datacell="status"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_status')}
                  sortkey="status"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="person_unique_id"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_student_id')}
                  sortkey="person_unique_id"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="student_number"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_student_number')}
                  sortkey="student_number"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="name"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_name_surname')}
                  sortkey="name"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="enrolled_program"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_registered_program')}
                  sortkey="enrolled_program"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="course_code"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_course_code')}
                  sortkey="course_code"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="course_name"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_course_name')}
                  sortkey="course_name"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="class"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_grade_course')}
                  sortkey="class"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="group"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_section')}
                  sortkey="group"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="course_program"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_course_program')}
                  sortkey="course_program_code"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="faculty"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_course_faculty')}
                  sortkey="faculty_code"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <th scope="col" className="text-center">
                  {T.t('gen_description')}
                </th>
              </tr>
            </thead>
            <tbody>
              {studentList && studentList.length ? (
                studentList.map((item: Types.IStudentItem) => {
                  let studentID = item.student_id ? item.student_id : -1;
                  return (
                    <tr key={'student-' + studentID} data-title={item.name}>
                      <td data-cell="select">
                        <div className="tick-radio position-relative">
                          <input
                            id='select_student'
                            type="checkbox"
                            className="form-radio"
                            checked={this.state.selected_ids.includes(studentID)}
                            data-id={studentID}
                            // data-name={
                            //   item.courseInfo &&
                            //   item.courseInfo.course_code +
                            //     " - " +
                            //     item.courseInfo.name
                            // }
                            data-item={JSON.stringify(item)}
                            onChange={this.onSelectStudent}
                          />
                        </div>
                      </td>
                      <td scope="row" data-label={T.t('gen_status')} className="text-center">
                        <div className="tags ml-1 mr-4">
                          <button
                            id='button_status'
                            className={`small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)}
                          >
                            {GT.GetActiveStatus(item.status)}
                          </button>
                        </div>
                      </td>
                      <td data-label={T.t('gen_student_id')} className="text-center">
                        {item.person_unique_id}
                      </td>
                      <td data-label={T.t('gen_student_number')} className="text-center">
                        {item.student_number}
                      </td>
                      <td data-label={T.t('gen_name_surname')} className="text-center">
                        {item.name}
                      </td>
                      <td data-label={T.t('gen_registered_program')} className="text-center">
                        {item.enrolled_program_name}
                      </td>
                      <td data-label={T.t('gen_course_code')} className="text-center">
                        {item.course_code}
                      </td>
                      <td data-label={T.t('gen_course_name')} className="text-center">
                        {item.course_name}
                      </td>
                      <td data-label={T.t('gen_grade_course')} className="text-center">
                        {item.class}
                      </td>
                      <td data-label="Grup" className="text-center">
                        {item.group}
                      </td>
                      <td data-label={T.t('gen_course_program_name')} className="text-center">
                        {item.course_program_name}
                      </td>
                      <td data-label={T.t('gen_course_faculty_name')} className="text-center">
                        {item.course_faculty_name}
                      </td>
                      <td data-label={T.t('gen_description')} className="text-center">
                        {item && item.description ? item.description : '-'}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.props.studentList && this.props.studentList.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
              ) : null}
            </div>
          </div>
        </div>
        {this.state.integrationModalIsOpen ? (
          <SyncStudentModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.getStudents()}
          />
        ) : null}
      </div>
    );

    return (
      <div className="white-container mt-4">
        <div className="row">
          <div className="col-xl-12 col-lg-8 col-md-12 courses">
            {/* {studentList && studentList.length ? Table : placeHolder} */}
            {Table}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IStudentPageProps): Types.IStudentPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IStudentPageProps = Object.assign({}, ownProps, {
    studentList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.students &&
      store.state.examPeriodModal.students.student_list,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.students &&
      store.state.examPeriodModal.students.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.studentPage,
    enrolled_programs_related_faculty: store.state.select_options && store.state.select_options.studentPage && store.state.select_options.studentPage.enrolled_programs_related_faculty,
    course_programs_related_faculty: store.state.select_options && store.state.select_options.studentPage && store.state.select_options.studentPage.course_programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.students,
        next.state.examPeriodModal && next.state.examPeriodModal.students
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.filters,
        next.state.examPeriodModal && next.state.examPeriodModal.filters
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.studentPage && prev.state.select_options.studentPage.course_programs_related_faculty,
        next.state.select_options && next.state.select_options.studentPage && next.state.select_options.studentPage.course_programs_related_faculty
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.studentPage && prev.state.select_options.studentPage.enrolled_programs_related_faculty,
        next.state.select_options && next.state.select_options.studentPage && next.state.select_options.studentPage.enrolled_programs_related_faculty
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.studentPage,
        next.state.select_options && next.state.select_options.studentPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(StudentAddModal);

export default container;
