import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../../store/types';
import Translator from '../../../services/translate-factory';
import {
  EducationTypeSelectOptions,
  ActivityTypesLabel,
  CourseTypesLabel
} from '../../../store/constants/activity-const';

const T = Translator.create();

function getInitialState(): any {
  const initialValues: any = {};
  return Object.assign({}, initialValues);
}

class SolutionActivityAddDetailTable extends Component<any, any> {
  state: {} = getInitialState();

  render() {
    let activity = this.props.detail;

    return (
      <div className="col-12 table-scroller">
        <div className="col-12">
          <h6>{T.t('gen_solution-activity-will-be-added')}</h6>
        </div>
        <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
          <thead>
            <tr>
              <th scope="col" className="text-center">{T.t('gen_activity_no')}</th>
              <th scope="col" className="text-center">{T.t('gen_code')}</th>
              <th scope="col" className="text-center">{T.t('gen_name')}</th>
              <th scope="col" className="text-center">{T.t('gen_activity')}</th>
              <th scope="col" className="text-center">{T.t('gen_lesson_count')}</th>
              <th scope="col" className="text-center">{T.t('gen_lesson_location')}</th>
              <th scope="col" className="text-center">{T.t('gen_education_type')}</th>
              <th scope="col" className="text-center">{T.t('gen_faculty')}</th>
              <th scope="col" className="text-center">{T.t('gen_program')}</th>
              <th scope="col" className="text-center">{T.t('gen_grade')}</th>
              <th scope="col" className="text-center">{T.t('gen_section')}</th>
              <th scope="col" className="text-center">{T.t('gen_instructors')}</th>
              <th scope="col" className="text-center">{T.t('gen_student_count')}</th>
              <th scope="col" className="text-center">{T.t('gen_week')}</th>
              <th scope="col" className="text-center">{T.t('gen_course_type')}</th>
              <th scope="col" className="text-center">{T.t('gen_description')}</th>
            </tr>
          </thead>
          <tbody>
            <tr key={'activity-' + activity.activity_no} data-title={activity.name}>
              <td scope="row" data-label={T.t('gen_activity_no')}>{activity.activity_no}</td>
              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                <div className="table-scrollable-td">{activity.course_code ? activity.course_code.split(';').join(', ') : '-'}</div>
              </td>
              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                <div className="table-scrollable-td">{activity.course_name ? activity.course_name.split(';').join(', ') : '-'}</div>
              </td>
              <td data-label={T.t('gen_activity')} className="text-center">{ActivityTypesLabel(activity.activity_type, T)}</td>
              <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{activity.lesson_count}</td>
              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                {activity.lecture_location && activity.lecture_location.label}
              </td>
              <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                {activity.education_type === EducationTypeSelectOptions(T)[0].value ?
                  EducationTypeSelectOptions(T)[0].label :
                  activity.education_type === EducationTypeSelectOptions(T)[1].value ?
                    EducationTypeSelectOptions(T)[1].label :
                    activity.education_type === EducationTypeSelectOptions(T)[2].value ?
                      EducationTypeSelectOptions(T)[2].label : activity.education_type
                }
              </td>
              <td className="text-center">
                {
                  activity && activity.faculties && activity.faculties.length > 0 ?
                    <div className="table-scrollable-td">{activity.faculties && activity.faculties.map((i: any, index: any) => (index == activity!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  activity && activity.programs && activity.programs.length > 0 ?
                    <div className="table-scrollable-td">{activity.programs && activity.programs.map((i: any, index: any) => (index == activity!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  activity && activity.classes && activity.classes.length > 0 ?
                    <div className="table-scrollable-td">{activity.classes && activity.classes.map((i: any, index: any) => (index == activity!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  activity && activity.sections && activity.sections.length > 0 ?
                    <div className="table-scrollable-td">{activity.sections && activity.sections.map((i: any, index: any) => (index == activity!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td className="text-center">
                {
                  activity && activity.instructors && activity.instructors.length > 0 ?
                    <div className="table-scrollable-td">{activity.instructors && activity.instructors.map((i: any, index: any) => (index == activity!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                {activity.student_count}
              </td>
              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                <div className="table-scrollable-td">{activity.week ? activity.week.split(',').join(', ') : '-'}</div>
              </td>
              <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                {CourseTypesLabel(activity.course_type, T)}
              </td>
              <td data-label={T.t('gen_description')} className="text-center">
                {activity && activity.description ? activity.description : '-'}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row-options justify-content-end">
          <div
            className="page-sorting d-flex align-items-center justify-content-center"
            style={{ marginTop: '5px' }}
          ></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionActivityAddDetailTable);

export default container;
