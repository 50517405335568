import React from 'react';
import { Log } from 'ng2-logger';
import PlainLoyout from '../layouts/plain-layout';
import LoaderPage from '../../components/templates/loader-page';

const L = Log.create('WelcomePage');

export default class WelcomePage extends React.Component<any> {
  componentDidMount() {
  }
  render() {
    return (
      <PlainLoyout>
        <LoaderPage>
          <p>Education is the most powerful weapon which you can use to change the world.</p>
          <span>— Nelson Mandela</span>
        </LoaderPage>
      </PlainLoyout>
    );
  }
}
