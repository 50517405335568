import React from 'react';
import { Log } from 'ng2-logger';
import { IHtmlEditorProps } from '../../store/types';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import Translator from '../../services/translate-factory';

const L = Log.create('ImageViewer');
const T = Translator.create();
let revision = Date.now();

export default class ImageViewer extends React.Component<any, any> {

  _index: number = 0;
  _image: HTMLImageElement | undefined = undefined;
  _mounted: boolean = false;

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  updateImage = () => {
    if (this._mounted) {
      revision = Date.now();
      this.forceUpdate();
    }
  }

  onError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e && e.currentTarget) {
      e.currentTarget.style.display = 'none';
      const index = e.currentTarget.dataset.index;
      if (index) {
        this._index = parseInt(index);
        const images: Array<any> = (this.props.srcs || []).filter((s: any) => s );
        if (images.length >= this._index + 1) {
          this._index++;
          // this.handleImage(e.currentTarget);
          this.forceUpdate();
        }
      }
    }
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this);
    }
  }

  onLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (e && e.currentTarget) {
      e.currentTarget.style.display = '';
      if (this.props.onLoad) {
        this.props.onLoad(this);
      }
    }
  }


  handleImage = (img: HTMLImageElement) => {
    if (img) {
      this._image = img;
      const images: Array<any> = (this.props.srcs || []).filter((s: any) => s );

      if (images.length > 0) {
        let src: any = images[this._index];
        this._image.src = src;
      }

    }
  }

  render() {
    const images: Array<any> = (this.props.srcs || []).filter((s: any) => s );
    let src: any;

    if (images.length > 0) {
      src = images[this._index];
    }

    return (
      <img className={this.props.className || ''} src={src} alt={this.props.alt || ''} data-index={this._index} onClick={this.onClick} onLoad={this.onLoad} onError={this.onError} style={{ background: '#ddd', display: 'none' }} />
    );
  }
}

