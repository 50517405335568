import React from 'react';
import { Field } from 'formik';
import * as Types from '../../store/types';

const MultipleCheckbox: React.SFC<Types.IMultipleCheckboxProps> = props => {
  return (
    <Field name={props.name}>
      {({ field, form }: any) => (
        <label>
          <input
            {...props}
            type="checkbox"
            id="radio-active"
            className="form-radio"
            checked={field.value.includes(props.value)}
            onChange={() => {
              if (field.value.includes(props.value)) {
                const nextValue = field.value.filter((value: number) => value !== props.value);
                form.setFieldValue(props.name, nextValue);
              } else {
                const nextValue = field.value.concat(props.value);
                form.setFieldValue(props.name, nextValue);
              }
            }}
          />
          {props.text}
        </label>
      )}
    </Field>
  );
};

export default MultipleCheckbox;
