import { Log } from 'ng2-logger';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Navigator from '../../components/renderless/navigation';
import { routes as Routes } from '../../store/constants/routes';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import BuildingPage from '../building/building-list-page';
import DashboardPage from '../dashboard/dashboard-page';
import CampusPage from '../campus/campus-list-page';
import ClassroomPage from '../classroom/classroom-list-page';
import CoursePage from '../exam-period/course/course-list-page';
import CoursePeriodPage from '../course-period/course-period-list-page';
import ExamPeriodPage from '../exam-period/exam-period-list-page';
import ExamSolutionPage from '../exam-period/solution/solution-page';
import CourseSolutionPage from '../course-period/solution/solution-page';
import FacultyPage from '../faculty/faculty-list-page';
import InstructorPage from '../instructor/instructor-list-page';
import ForgotPwPage from '../login/forgot-pw-page';
import LoginPage from '../login/login-page';
import ProgramPage from '../program/program-list-page';
import PrivateRoute from './private-route';
import SettingsPage from '../setting/aplan-settings';
import UsersPage from '../user/user-list-page';
import CoursePeriodCoursePage from '../course-period/course/course-list-page';
import ProfilePage from '../setting/profile-page';
import ActivityRelations from '../course-period/activity-relations/main';
import BuildingSyncPage from '../../pages/building/building-sync-page';
import ProgramSyncPage from '../../pages/program/program-sync-page';
import CampusSyncPage from '../../pages/campus/campus-sync-page';
import ClassroomSyncPage from '../../pages/classroom/classroom-sync-page';
import FacultySyncPage from '../../pages/faculty/faculty-sync-page';
import InstructorSyncPage from '../../pages/instructor/instructor-sync-page';
import CourseSyncPage from '../../pages/exam-period/course/course-sync-page';
import StudentSyncPage from '../../pages/exam-period/student/student-sync-page';
import Spinner from '../../components/templates/spinner';

const L = Log.create('AppRoutes');

export class AppRoutes extends React.Component<any> {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
        <Suspense fallback={<Spinner name="content-spin" defaultOpen={true} />}>
          <Navigator />
          <Switch>
            <PrivateRoute
              exact={true}
              path={GT.Route(Routes.DASHBOARD)}
              roles={['s', 'a', 'b', 'e','d']}
              dispatch={this.props.dispatch}
              page={<DashboardPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SETTINGS)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<SettingsPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.INSTRUCTOR, '/:id?')}
              roles={['s', 'a']}
              dispatch={this.props.dispatch}
              page={<InstructorPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.CLASSROOM, '/:id?')}
              roles={['s','a']}
              dispatch={this.props.dispatch}
              page={<ClassroomPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.CAMPUS, '/:id?')}
              roles={['s','a']}
              dispatch={this.props.dispatch}
              page={<CampusPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.BUILDING, '/:id?')}
              roles={['s','a']}
              dispatch={this.props.dispatch}
              page={<BuildingPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.FACULTY, '/:id?')}
              roles={['s','a']}
              dispatch={this.props.dispatch}
              page={<FacultyPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.PROGRAM, '/:id?')}
              roles={['s','a']}
              dispatch={this.props.dispatch}
              page={<ProgramPage />}
            />
            {this.props.term_type === 0 ? (
              <PrivateRoute
                path={GT.Route(Routes.COURSE, '/:id?')}
                roles={['s','a']}
                dispatch={this.props.dispatch}
                page={<CoursePeriodCoursePage />}
              />
            ) : (
                <PrivateRoute
                  path={GT.Route(Routes.COURSE, '/:id?')}
                  roles={['s','a']}
                  dispatch={this.props.dispatch}
                  page={<CoursePage />}
                />
              )}
            {this.props.term_type === 0 ? (
              <PrivateRoute
                path={GT.Route(Routes.ACTIVITY_RELATIONS, '/:id?')}
                roles={['s','a']}
                dispatch={this.props.dispatch}
                page={<ActivityRelations />}
              />
            ) : null}
            <PrivateRoute
              path={GT.Route(Routes.EXAM_PERIODS, '/:id?')}
              roles={['s', 'a', 'b', 'e','d']}
              dispatch={this.props.dispatch}
              page={<ExamPeriodPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.COURSE_PERIODS, '/:id?')}
              roles={['s', 'a', 'b', 'e','d']}
              dispatch={this.props.dispatch}
              page={<CoursePeriodPage />}
            />
            {this.props.term_type === 0 ? (
              <PrivateRoute
                path={GT.Route(Routes.SOLUTION, '/:id?')}
                roles={['s', 'a', 'b', 'e','d']}
                dispatch={this.props.dispatch}
                page={<CourseSolutionPage />}
              />
            ) : (
                <PrivateRoute
                  path={GT.Route(Routes.SOLUTION, '/:id?')}
                  roles={['s', 'a', 'b', 'e','d']}
                  dispatch={this.props.dispatch}
                  page={<ExamSolutionPage />}
                />
              )}
            <PrivateRoute
              path={GT.Route(Routes.USER_SETTINGS)}
              roles={['s', 'a', 'b', 'e','d']}
              dispatch={this.props.dispatch}
              page={<ProfilePage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.USERS, '/:id?')}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<UsersPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_BUILDING)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<BuildingSyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_PROGRAM)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<ProgramSyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_CAMPUS)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<CampusSyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_CLASSROOM)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<ClassroomSyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_FACULTY)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<FacultySyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_INSTRUCTOR)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<InstructorSyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_COURSE)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<CourseSyncPage />}
            />
            <PrivateRoute
              path={GT.Route(Routes.SYNC_STUDENT)}
              roles={['s']}
              dispatch={this.props.dispatch}
              page={<StudentSyncPage />}
            />
            <Route path={GT.Route(Routes.LOGIN)} component={LoginPage} />
            <Route path={GT.Route(Routes.FORGOT_PW, '/:token?')} component={ForgotPwPage} />
            <Route path={GT.Route(Routes.LOGIN)} component={LoginPage} />
            <PrivateRoute path="*" dispatch={this.props.dispatch} page={<LoginPage />} />
          </Switch>
          </Suspense>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    status: store.state.status,
    user: store.state.user,
    term_type: store.state.term_type
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({
  dispatch
});

const routes = connect(mapStateToProps, dispatchProps)(AppRoutes);

export default routes;
