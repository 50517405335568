import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
// import * as actions from "../actions";
import * as Actions from '../actions/general';
import { constants, CampusSearchInitialValues } from '../constants/campus-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getCampus(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCampusData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCampus(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_campus_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCampus(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status == 200) {
    yield put(Actions.Notification('notification_campus_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteCampus(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_campus_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.CAMPUS_LIST_SEARCH, CampusSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('gen_relation_occurred_buildings', 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCampusSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CampusGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCampusDistances(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusGetDistances());
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCampusDistances(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CampusPostDistances(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_campus_distances_saved', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCampusExcelBulk(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CampusCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchCampusSearch() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_LIST_SEARCH, getCampus);
}

export function* watchCampusGetDistances() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_GET_DISTANCES, getCampusDistances);
}

export function* watchCampusUpdateDistances() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_UPDATE_DISTANCES, updateCampusDistances);
}

export function* watchCampusGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_GET_SELECT_OPTIONS, getCampusSelectOptions);
}

export function* watchCampusGetById() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_GET_BY_ID, getCampusData);
}

export function* watchCampusUpdate() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_UPDATE, updateCampus);
}

export function* watchCampusCreate() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_CREATE, createCampus);
}

export function* watchCampusDelete() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_DELETE, deleteCampus);
}

export function* watchCreateCampusExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.CAMPUS_CREATE_EXCEL_BULK, createCampusExcelBulk);
}
