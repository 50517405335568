export const routes = {
  LOGIN: '/login',
  DASHBOARD: '/',
  SESSIONS: '/sessions',
  SESSION_ATTENDEES: '/session/attendees',
  USERS: '/users',
  GROUPS: '/groups',
  SETTINGS: '/settings',
  FORGOT_PW: '/renewpass',
  CAMPUS: '/campus',
  BUILDING: '/building',
  CLASSROOM: '/classroom',
  CLASSROOM_FEATURE: '/classroom-feature',
  FACULTY: '/faculty',
  PROGRAM: '/program',
  COURSE: '/course',
  INSTRUCTOR: '/instructor',
  COURSE_PERIODS: '/course-period',
  EXAM_PERIODS: '/exam-period',
  SOLUTION: '/solution',
  HELP_GUIDE: '/help-guide',
  REPORT: '/report',
  USER_SETTINGS: '/user-settings',
  ACTIVITY_RELATIONS: '/activity-relations',
  SYNC_BUILDING: '/sync/building',
  SYNC_PROGRAM: '/sync/program',
  SYNC_CAMPUS: '/sync/campus',
  SYNC_CLASSROOM: '/sync/classroom',
  SYNC_FACULTY: '/sync/faculty',
  SYNC_INSTRUCTOR: '/sync/instructor',
  SYNC_COURSE: '/sync/course',
  SYNC_STUDENT: '/sync/student',
  SYNC: '/sync/newsync'
};
