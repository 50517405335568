import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../../store/types';
import Translator from '../../../services/translate-factory';

const T = Translator.create();

function getInitialState(): any {
  const initialValues: any = {
    classroom_ids: [],
    classrooms: [],
    invigilator_ids: [],
    invigilators: [],
    student_counts: [],
    indexes: [],
  };
  return Object.assign({}, initialValues);
}

class MultiSelectionTableAddModal extends Component<any, any> {
  state: {
    classroom_ids?: Array<any>;
    classrooms?: Array<any>;
    invigilator_ids?: Array<any>;
    invigilators?: Array<any>;
    student_counts?: Array<any>;
    indexes?: Array<any>;
  } = getInitialState();

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.classroom_ids_labels !== prevProps.classroom_ids_labels ||
      this.props.invigilator_ids_labels !== prevProps.invigilator_ids_labels) {
      this.setState({
        ...this.state,
        classroom_ids: this.props.classroom_ids_labels,
        classrooms: this.props.classrooms_labels,
        invigilator_ids: this.props.invigilator_ids_labels,
        invigilators: this.props.invigilators_labels,
        student_counts: this.props.student_count_labels,
        indexes: [...Array(this.props.classrooms_labels && this.props.classrooms_labels.length)].map(() => Math.floor(Math.random() * 100)),
      });
    }
  }

  componentDidMount() {
    if (this.props.classrooms_labels && this.props.classrooms_labels.indexOf(undefined) > -1) {
      return null
    } else {
      this.setState({
        ...this.state,
        classroom_ids: this.state.classroom_ids && this.state.classroom_ids.concat(this.props.classroom_ids_labels),
        classrooms: this.state.classrooms && this.state.classrooms.concat(this.props.classrooms_labels),
        invigilator_ids: this.state.invigilator_ids && this.state.invigilator_ids.concat(this.props.invigilator_ids_labels),
        invigilators: this.state.invigilators && this.state.invigilators.concat(this.props.invigilators_labels),
        student_counts: this.state.student_counts && this.state.student_counts.concat(this.props.student_counts_labels),
        indexes: [...Array(this.props.classrooms_labels && this.props.classrooms_labels.length)].map(() => Math.floor(Math.random() * 100)),
      });
    }
  }

  onDeleteRow = (i: any) => {
    let index = this.state.indexes && this.state.indexes.indexOf(i)
    if (index !== -1) {
      this.state.classrooms && this.state.classrooms.splice(index!, 1);
      this.state.classroom_ids && this.state.classroom_ids.splice(index!, 1);
      this.state.invigilators && this.state.invigilators.splice(index!, 1);
      this.state.invigilator_ids && this.state.invigilator_ids.splice(index!, 1);
      this.state.student_counts && this.state.student_counts.splice(index!, 1);
      this.state.indexes && this.state.indexes.splice(index!, 1);
      this.setState(this.state);
    }
  };

  render() {
    this.props.multiSelectionObjectFunction(
      this.state.classroom_ids,
      this.state.invigilator_ids,
      this.state.classrooms,
      this.state.invigilators,
      this.state.student_counts
    );
    
    return (
      <div className="col-md-12">
        <div className="add-custom-tag mb-3">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table courseformtable">
            <thead>
              <tr className="courseformtable-thead-tr">
                <th scope="col" style={{ 'width': '35%' }}>
                  <span>{T.t('gen_classroom')}</span>
                </th>
                <th scope="col" style={{ 'width': '35%' }}>
                  <span>{T.t('gen_invigilator')}</span>
                </th>
                <th scope="col" style={{ 'width': '20%' }}>
                  <span>{T.t('gen_student_count')}</span>
                </th>
                <th scope="col" style={{ 'width': '10%' }}>
                  <span></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="courseformtable-tbody-tr">
                <td className="courseformtable-td" style={{ 'width': '35%' }}>
                  <table>
                    <tbody>
                      {this.state.classrooms && this.state.classrooms.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_classroom')}>{item == undefined ? '-' : item}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '35%' }}>
                  <table>
                    <tbody>
                      {this.state.invigilators && this.state.invigilators.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_invigilator')}>{item == undefined ? '-' : item}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '20%' }}>
                  <table>
                    <tbody>
                      {this.state.student_counts && this.state.student_counts.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_number_students_classroom')}>{item == undefined ? '-' : item}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '10%' }}>
                  <table>
                    <tbody>
                      {this.state.indexes && this.state.indexes.map((index: any, i: any) =>
                        <tr className="courseformtable-td-tr" key={i}>
                          <td scope="row">
                            <button
                              className="ms-table-delete-button"
                              onClick={() => this.onDeleteRow(index)}
                            >
                              <i className="material-icons pr-1 pl-1">delete_outline</i>
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(MultiSelectionTableAddModal);

export default container;
