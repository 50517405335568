import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, FacultySearchInitialValues } from '../constants/faculty-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.FacultySearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getFacultyData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.FacultyGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateFaculty(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.FacultyUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_faculty_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createFaculty(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.FacultyCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_faculty_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteFaculty(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.FacultyDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_faculty_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.FACULTY_LIST_SEARCH, FacultySearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('gen_relation_occurred_faculties', 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getFacultySelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.FacultyGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createFacultyExcelBulk(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.FacultyCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchFacultySearch() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_LIST_SEARCH, getFaculties);
}

export function* watchFacultyGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_GET_SELECT_OPTIONS, getFacultySelectOptions);
}

export function* watchFacultyGetById() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_GET_BY_ID, getFacultyData);
}

export function* watchFacultyUpdate() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_UPDATE, updateFaculty);
}

export function* watchFacultyCreate() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_CREATE, createFaculty);
}
export function* watchFacultyDelete() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_DELETE, deleteFaculty);
}

export function* watchCreateFacultyExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.FACULTY_CREATE_EXCEL_BULK, createFacultyExcelBulk);
}
