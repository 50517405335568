import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ActivityFormInitialValues,
  EducationTypeSelectOptions,
  ActivityTermLectureLocations,
  CourseTypes,
  ActivityTypes,
  ActivityTypesLabel,
  CourseTypesLabel
} from '../../store/constants/activity-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { CombineActivityFormValidation } from './validations/combine-activities-form-val';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const Logger = Log.create('CombineExamsModal');

class CombineExamsModal extends Component<Types.ICombineModalProps, Types.ICombineActivitiesDataModel> {
  state: Types.ICombineActivitiesDataModel = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      name: '',
      course_code: '',
      activity_type: '',
      lesson_count: 0,
      faculties: [],
      faculty_ids: [],
      programs: [],
      program_ids: [],
      sections: [],
      instructors: [],
      instructor_ids: [],
      student_count: 0,
      week: '',
      section: ''
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: any, FormActions: FormikActions<Types.IActivityItem>) => {
    let combinedCoursesArray: any = this.props.courseList && this.props.courseList.map(
      (obj: any) => obj.item.course_id
    );

    this.state.combined_courses = combinedCoursesArray;

    this.state.formValues.name = model.name;
    this.state.formValues.course_code = model.course_code;
    this.state.formValues.activity_type = model.activity_type;
    this.state.formValues.week = model.week;
    this.state.formValues.course_type = model.course_type;
    this.state.formValues.instructors = model.instructors;
    this.state.formValues.instructor_ids = model.instructor_ids;
    this.state.formValues.faculties = model.faculties;
    this.state.formValues.faculty_ids = model.faculty_ids;
    this.state.formValues.programs = model.programs;
    this.state.formValues.program_ids = model.program_ids;
    this.state.formValues.education_type = model.education_type;
    this.state.formValues.lecture_location_id = model.lecture_location_id;
    this.state.formValues.sections = model.sections;
    this.state.formValues.description = model.description;

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_courses_will_be_combined'),
        body: T.t('gen_courses_combine_question'),
        name: 'examPeriod_courses_delete',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
              let errors: any = {};
              if (result.details) {
                const validations: Array<Types.IValidationResponse> = result.details;
                validations.forEach((m: Types.IValidationResponse) => {
                  errors[m.field] = m.message[0];
                });
              }
              FormActions.setErrors(errors);
            }
            if (status === 200 || status === 201) {
              this.setCloseModal();
              this.onUpdateListFromModal();
            }
          };

          this.state.activity_no = this.props.courseObject ? this.props.courseObject.course_id : -1;

          if (this.props.showCoursesOnly) {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_CREATE_COMBINE_ACTIVITES,
                this.state,
                'ep-course-list',
                resultCallback
              )
            );
          } else {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_CREATE_COMBINE_ACTIVITES,
                this.state,
                'ep-course-list',
                resultCallback
              )
            );
          }
        }
      })
    );
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.ICombineModalProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getCombinedExamsFieldsIfEqual = (): Types.ICombineActivitiesFormValues => {
    let fields: Types.ICombineActivitiesFormValues = Object.assign({}, this.state.formValues);

    if (this.props.courseList && this.props.courseList.length) {
      const faculty_ids_selected = this.props.courseList.map((course: any) => course.item.activityInfo.faculty_ids);

      let course_codes: Array<any> = [];
      let names: Array<any> = [];
      let descriptions: Array<any> = [];
      faculty_ids_selected.forEach((facultyIds: any, index_ids: any) => {
        facultyIds.forEach((facultyId: any, index_id: any) => {
          course_codes.push(this.props.courseList && this.props.courseList[index_ids].item.activityInfo.course_code.includes(';') ?
            this.props.courseList && this.props.courseList[index_ids].item.activityInfo.course_code.split(';')[index_id] :
            this.props.courseList && this.props.courseList[index_ids].item.activityInfo.course_code);

          names.push(this.props.courseList && this.props.courseList[index_ids].item.activityInfo.course_name.includes(';') ?
            this.props.courseList && this.props.courseList[index_ids].item.activityInfo.course_name.split(';')[index_id] :
            this.props.courseList && this.props.courseList[index_ids].item.activityInfo.course_name);

          if (this.props.courseList && this.props.courseList[index_ids].item.activityInfo.description != undefined) {
            descriptions.push(
              this.props.courseList && this.props.courseList[index_ids].item.activityInfo.description
            );
          }
        });
      });
      const course_code = course_codes.join(';');
      const name = names.join(';');
      const description = GT.isAllEqual(descriptions) ? descriptions[0] : GT.DistinctTextArray(descriptions).join(';');

      const _lesson_count = this.props.courseList.map((course: any) => course.item.activityInfo.lesson_count);
      const lesson_count = Math.max(..._lesson_count);

      const _student_count = this.props.courseList.map((course: any) => course.item.activityInfo.student_count);
      const student_count = _student_count.reduce((a, b) => a + b, 0);

      const _faculties = this.props.courseList.map((course: any) => course.item.activityInfo.faculties);
      const faculties = _faculties.flat();
      const faculty_ids = faculties.map((faculty: any) => faculty && faculty.value);

      const _programs = this.props.courseList.map((course: any) => course.item.activityInfo.programs);
      const programs = _programs.flat();
      const program_ids = programs.map((program: any) => program && program.value);

      const _instructors = this.props.courseList.map((course: any) => course.item.activityInfo.instructors);
      const flattedStaff = _instructors.flat();

      const instructors = flattedStaff.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
      const instructor_ids = instructors.map((staff) => staff.value);

      const weeks = this.props.courseList.map((course: any) => course.item.activityInfo.week);
      const checkAllWeeksEqual = weeks.every((type: string) => type === weeks[0]);
      const week = checkAllWeeksEqual ? weeks[0] : '';

      const sections = (this.props.courseList.map((course: any) => course.item.activityInfo.sections)).flat();

      const activity_types = this.props.courseList.map((course: any) => course.item.activityInfo.activity_type);
      const checkAllTypesEqual = activity_types.every((type: string) => type === activity_types[0]);
      const activity_type = checkAllTypesEqual ? activity_types[0] : '';

      const course_types = this.props.courseList.map((course: any) => course.item.activityInfo.course_type);
      const courseTypesEqual = course_types.every((type: string) => type === course_types[0]);
      const course_type = courseTypesEqual ? course_types[0] : '';

      const education_types = this.props.courseList.map((course: any) => course.item.activityInfo.education_type);
      const educationTypesEqual = education_types.every((type: string) => type === education_types[0]);
      const education_type = educationTypesEqual ? education_types[0] : '';

      let hasDifferentValues = (arr: any) =>
        !arr.every((item: any, i: any, ref: any) =>
          JSON.stringify([...ref].shift()).includes(JSON.stringify(item)));

      let educationTypesArray: any;
      let educationTypeObject;
      let educationTypeNew = this.props.courseList.map((i: any) => i.item.activityInfo.education_type)
      if (!educationTypeNew.includes(undefined)) {
        if (hasDifferentValues(educationTypeNew)) {
          educationTypesArray = null;
          educationTypeObject = undefined;
        } else {
          educationTypesArray = [this.props.courseList[0].item.activityInfo.education_type];
          educationTypeObject = EducationTypeSelectOptions(T).find((item: any) => educationTypesArray.includes(item.value));
        }
      }

      let lecture_location_id;
      let lecture_location;
      let courseLocationNew = this.props.courseList.map((i: any) => i.item.activityInfo.lecture_location_id)
      if (!courseLocationNew.includes(undefined)) {
        if (hasDifferentValues(courseLocationNew)) {
          lecture_location_id = null;
          lecture_location = undefined;
        } else {
          lecture_location_id = this.props.courseList[0].item.activityInfo.lecture_location_id;
          lecture_location = {
            label: this.props.courseList[0].item.activityInfo.lecture_location_name,
            value: this.props.courseList[0].item.activityInfo.lecture_location_id
          };
        }
      }

      let courseTypesArray: any;
      let courseTypeObject;
      let courseTypeNew = this.props.courseList.map((i: any) => i.item.activityInfo.course_type)
      if (!courseTypeNew.includes(undefined)) {
        if (hasDifferentValues(courseTypeNew)) {
          courseTypesArray = null;
          courseTypeObject = undefined;
        } else {
          courseTypesArray = [this.props.courseList[0].item.activityInfo.course_type];
          courseTypeObject = CourseTypes.find((item) => courseTypesArray.includes(item.value));
        }
      }

      fields = {
        name,
        course_code,
        lesson_count,
        student_count,
        faculties,
        faculty_ids,
        programs,
        program_ids,
        instructors,
        instructor_ids,
        activity_type,
        week,
        sections,
        educationType_object: educationTypeObject,
        lecture_location,
        lecture_location_id,
        education_type: educationTypeObject ? educationTypeObject.value : undefined,
        course_type: courseTypeObject ? courseTypeObject.value : undefined,
        description
      };
    }
    return fields;
  };

  render() {
    let courseList = this.props.showCoursesOnly ? this.props.combinedDataList : this.props.courseList;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions];

    let fields = this.getCombinedExamsFieldsIfEqual();
    let formInitialValues = Object.assign({}, ActivityFormInitialValues, fields);

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t("gen_course_merge")}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={
                      this.props.showCoursesOnly
                        ? this.props.coursePeriodCourseObject
                          ? this.props.coursePeriodCourseObject
                          : Object.assign({}, formInitialValues)
                        : Object.assign({}, formInitialValues)
                    }
                    enableReinitialize={true}
                    validationSchema={CombineActivityFormValidation(T)}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.IActivityItem>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      const courseLocation = allSelectOptions.find(
                        (option) => option.value == props.values.lecture_location_id
                      );
                      let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.results_term_week_dates.total_weeks);
                      const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];
                      this.state.formValues.week = values.week ? values.week : '';
                      const weekOptions = this.state.formValues.week.split(",").map((item: any) => ({ label: item, value: item }));
                      let weekOptionSelector = this.state.formValues.week.split(",")[0] === "" ? true : false;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="course_code"
                                  name="course_code"
                                  value={values.course_code}
                                  onChange={handleChange}
                                  type="text"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="course_code">{T.t('gen_code')}</label>
                                {errors && errors.course_code && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  type="text"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="name">{T.t('gen_activity_name')}</label>
                                {errors && errors.name && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_activity_type')}</label>
                                  <Select
                                    id='select_activity'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={T.t_options(ActivityTypes)}
                                    placeholder={T.t('gen_select_activity_type')}
                                    value={
                                      values.activity_type != undefined && values.activity_type != null
                                        ? T.t_options(ActivityTypes).find((option) => option.value == values.activity_type)
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('activity_type', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                  />
                                </div>
                                {errors && errors.activity_type && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 mt-3">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="lesson_count"
                                  name="lesson_count"
                                  value={values.lesson_count}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                                {errors && errors.lesson_count && props.submitCount > 0 && (
                                  <div className="error">{T.t(errors && errors.lesson_count)}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_education_type')}</label>
                                  <Select
                                    id='select_education_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={EducationTypeSelectOptions(T)}
                                    placeholder={T.t('gen_select_education_type')}
                                    value={props.values.educationType_object}
                                    onChange={(option: any) => {
                                      props.setFieldValue('education_type', option.value);
                                      props.setFieldValue('educationType_object', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                  />
                                </div>
                                {errors && errors.education_type && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3 mt-3">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="student_count"
                                  name="student_count"
                                  value={values.student_count}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                                {errors && errors.student_count && props.submitCount > 0 && (
                                  <div className="error">{T.t(errors && errors.student_count)}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_week')}</label>
                                  <Select
                                    id='select_week'
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={false}
                                    options={WeekSelectOptions}
                                    placeholder={T.t('gen_select_week')}
                                    value={weekOptionSelector ? props.values.weeks : weekOptions}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('weeks',
                                        list.map(item => item.value).includes(-1) ? Weeks : list
                                      );
                                      props.setFieldValue(
                                        'week',
                                        list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                      );
                                      weekOptionSelector = true;
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                  />
                                </div>
                                {errors && errors.week && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_lecture_location')}</label>
                                  <Select
                                    id='select_location'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={allSelectOptions}
                                    placeholder={T.t('gen_select_location')}
                                    value={courseLocation}
                                    onChange={(option: any) => {
                                      props.setFieldValue('lecture_location', option);
                                      props.setFieldValue('lecture_location_id', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                                {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_type')}</label>
                                  <Select
                                    id='select_course_type'
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                    }
                                    closeMenuOnSelect={true}
                                    options={T.t_options(CourseTypes)}
                                    placeholder={T.t('gen_select_course_type')}
                                    value={
                                      values.course_type != undefined && values.course_type != null
                                        ? T.t_options(CourseTypes).find((option) => option.value == values.course_type)
                                        : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('course_types', option);
                                      props.setFieldValue('course_type', option && option.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 form-input form-group with-icon">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                            </div>
                            <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table courseformtable ml-3 mr-3">
                              <thead>
                                <tr className="courseformtable-thead-tr">
                                  <th scope="col" className="text-center" style={{ 'width': '33%' }}>
                                    <span>{T.t('gen_faculty')}</span>
                                  </th>
                                  <th scope="col" className="text-center" style={{ 'width': '33%' }}>
                                    <span>{T.t('gen_program')}</span>
                                  </th>
                                  <th scope="col" className="text-center" style={{ 'width': '34%' }}>
                                    <span>{T.t('gen_section')}</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="courseformtable-tbody-tr">
                                  <td className="courseformtable-td text-center" style={{ 'width': '33%' }}>
                                    <table>
                                      <tbody>
                                        {values.faculties && values.faculties.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_faculty')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td text-center" style={{ 'width': '33%' }}>
                                    <table>
                                      <tbody>
                                        {values.programs && values.programs.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_program')}>{item.label}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="courseformtable-td text-center" style={{ 'width': '34%' }}>
                                    <table>
                                      <tbody>
                                        {values.sections && values.sections.map((item: any, index: any) =>
                                          <tr className="courseformtable-td-tr" key={index}>
                                            <td scope="row" data-label={T.t('gen_lecture_staff')}>{item}</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                id='button_save'
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => props.handleSubmit()}
                                disabled={isSubmitting}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <h6>{T.t("gen_merged_course")}</h6>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col">{T.t('gen_status')}</th>
                        <th scope="col">{T.t('gen_activity_no')}</th>
                        <th scope="col">{T.t('gen_code')}</th>
                        <th scope="col">{T.t('gen_name')}</th>
                        <th scope="col" className="text-center">{T.t('gen_activity')}</th>
                        <th scope="col" className="text-center">{T.t('gen_lesson_count')}</th>
                        <th scope="col" className="text-center">{T.t('gen_lesson_location')}</th>
                        <th scope="col" className="text-center">{T.t('gen_education_type')}</th>
                        <th scope="col" className="text-center">{T.t('gen_faculty')}</th>
                        <th scope="col" className="text-center">{T.t('gen_program')}</th>
                        <th scope="col" className="text-center">{T.t('gen_grade')}</th>
                        <th scope="col" className="text-center">{T.t('gen_section')}</th>
                        <th scope="col" className="text-center">{T.t('gen_instructors')}</th>
                        <th scope="col" className="text-center">{T.t('gen_student_count')}</th>
                        <th scope="col" className="text-center">{T.t('gen_week')}</th>
                        <th scope="col" className="text-center">{T.t('gen_course_type')}</th>
                        <th scope="col" className="text-center">{T.t('gen_description')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList ? (
                        courseList.length ? (
                          courseList.map((courseObject: { id: number; item: Types.IExamPeriod_ActivityItem }) => {
                            let courseItem = courseObject.item.activityInfo;
                            const courseLocation = allSelectOptions.find(
                              (option) => option.value == (courseItem && courseItem.lecture_location_id)
                            );
                            return (
                              <tr
                                key={'course-' + (courseItem && courseItem.activity_no)}
                                data-title={courseItem && courseItem.course_name}
                              >
                                <td scope="row" data-label={T.t('gen_status')}>
                                  <div className="tags ml-1 mr-4">
                                    <button
                                      id='button_course_item'
                                      className={
                                        `small-tag text-uppercase` +
                                        (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                      }
                                    >
                                      {GT.GetActiveStatus(courseItem && courseItem.status)}
                                    </button>
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_activity_no')} className="text-center">{courseItem && courseItem.activity_no}</td>
                                <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                  <div className="table-scrollable-td">{courseItem && courseItem.course_code ? courseItem.course_code.split(';').join(', ') : '-'}</div>
                                </td>
                                <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                  <div className="table-scrollable-td">{courseItem!.course_name ? courseItem && courseItem.course_name.split(';').join(', ') : '-'}</div>
                                </td>
                                <td data-label={T.t('gen_activity')} className="text-center">{courseItem && courseItem.activity_type ? ActivityTypesLabel(courseItem && courseItem.activity_type, T) : '-'}</td>
                                <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{courseItem && courseItem.lesson_count}</td>
                                <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                  {courseItem && courseItem.lecture_location && courseItem.lecture_location.label}
                                </td>
                                <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                  {courseItem && courseItem.education_type === EducationTypeSelectOptions(T)[0].value ?
                                    EducationTypeSelectOptions(T)[0].label :
                                    courseItem && courseItem.education_type === EducationTypeSelectOptions(T)[1].value ?
                                      EducationTypeSelectOptions(T)[1].label :
                                      courseItem && courseItem.education_type === EducationTypeSelectOptions(T)[2].value ?
                                        EducationTypeSelectOptions(T)[2].label : courseItem && courseItem.education_type
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.faculties && courseItem.faculties.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem && courseItem.faculties && courseItem.faculties.map((i: any, index: any) => (index == courseItem!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.programs && courseItem.programs.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem.programs && courseItem.programs.map((i: any, index: any) => (index == courseItem!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.classes && courseItem.classes.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem.classes && courseItem.classes.map((i: any, index: any) => (index == courseItem!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.sections && courseItem.sections.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem && courseItem.sections && courseItem.sections.map((i: any, index: any) => (index == courseItem!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    courseItem && courseItem.instructors && courseItem.instructors.length > 0 ?
                                      <div className="table-scrollable-td">{courseItem && courseItem.instructors && courseItem.instructors.map((i: any, index: any) => (index == courseItem!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                  {courseItem && courseItem.student_count}
                                </td>
                                <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                  <div className="table-scrollable-td">{courseItem && courseItem.week ? courseItem!.week.split(',').join(', ') : '-'}</div>
                                </td>
                                <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                                  {courseItem && courseItem.course_type ? CourseTypesLabel(courseItem && courseItem.course_type, T) : '-'}
                                </td>
                                <td data-label={T.t('gen_description')} className="text-center">
                                  {courseItem && courseItem.description ? courseItem.description : '-'}
                                </td>
                              </tr>
                            );
                          })
                        ) : null
                      ) : null}</tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICombineModalProps
): Types.ICombineModalProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICombineModalProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CombineExamsModal);

export default container;
