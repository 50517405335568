import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
    STUDENT_LIST_SEARCH: 'STUDENT:LIST_SEARCH',
    STUDENT_CREATE: 'STUDENT:CREATE',
    STUDENT_UPDATE: 'STUDENT:UPDATE',
    STUDENT_DELETE: 'STUDENT:DELETE',
    STUDENT_GET_BY_ID: 'STUDENT:GET_BY_ID',
    STUDENT_GET_SELECT_OPTIONS: 'STUDENT:GET_SELECT_OPTIONS',
    STUDENT_CREATE_EXCEL_BULK: 'STUDENT:CREATE_EXCEL_BULK'
};

export const StudentSearchInitialValues: Types.IFilterStudent = {
    order_by: '',
    name: '',
    status: [],
    select_all: false,
    page: 1,
    total: -1,
    size: 10
};

export const StudentFormInitialValues: Types.IStudentItem = {
    student_id: undefined,
    name: '',
    description: '',
    status: 1
};

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_id_number_course_code'), value: 'TCNUMBER_COURSECODE' },
  { label: T.t('gen_student_number_course_code'), value: 'STUDENTNUMBER_COURSECODE' },
  {
    label: T.t('gen_id_course_code_and_program_code'),
    value: 'TCNUMBER_COURSECODE_PROGRAMCODE'
  },
  {
    label: T.t('gen_student_number_course_code_and_program_code'),
    value: 'STUDENTNUMBER_COURSECODE_PROGRAMCODE'
  },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];