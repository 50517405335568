import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  FACULTY_LIST_SEARCH: 'FACULTY:LIST_SEARCH',
  FACULTY_CREATE: 'FACULTY:CREATE',
  FACULTY_UPDATE: 'FACULTY:UPDATE',
  FACULTY_DELETE: 'FACULTY:DELETE',
  FACULTY_GET_BY_ID: 'FACULTY:GET_BY_ID',
  FACULTY_GET_SELECT_OPTIONS: 'FACULTY:GET_SELECT_OPTIONS',
  FACULTY_CREATE_EXCEL_BULK: 'FACULTY:CREATE_EXCEL_BULK'
};

export const FacultySearchInitialValues: Types.IFilterFaculty = {
  order_by: '',
  faculty_code: '',
  name: '',
  status: [],
  buildings: [],
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const FacultyFormInitialValues: Types.IFacultyItem = {
  faculty_id: undefined,
  faculty_code: '',
  name: '',
  description: '',
  status: 1,
  buildings: [],
  programs: []
};

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_faculty_school_code'), value: 'FACULTY_CODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];