import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CourseFormInitialValues,
  ClassroomCombineTypes,
  ExamPeriodCourseTermLectureLocations,
  Grades,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import { CourseFormValidation } from '../exam-period/course/validations/course-form-val';
import MultiSelectionTable from '../exam-period/course/multi-selection-table';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const Logger = Log.create('ClassroomForm');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;

let faculty_ids_selected: Array<any> = [];
let program_ids_selected: Array<any> = [];
let class_ids_selected: Array<any> = [];
let lecture_staff_ids_selected: Array<any> = [];

let faculties_selected: Array<any> = [];
let programs_selected: Array<any> = [];
let classes_selected: Array<any> = [];
let sections_selected: Array<any> = [];
let lecture_staff_selected: Array<any> = [];

let faculty_ids_labels: Array<any> = new Array<any>();
let faculties_labels: Array<any> = new Array<any>();
let program_ids_labels: Array<any> = new Array<any>();
let programs_labels: Array<any> = new Array<any>();
let class_ids_labels: Array<any> = new Array<any>();
let classes_labels: Array<any> = new Array<any>();
let sections_labels: Array<any> = new Array<any>();
let lecture_staff_ids_labels: Array<any> = new Array<any>();
let lecture_staff_labels: Array<any> = new Array<any>();
let student_count_labels: Array<any> = new Array<any>();

function getInitialState(): Types.ICourseFormState {
  const initialValues: Types.ICourseFormState = {
    model: Object.assign({}, CourseFormInitialValues),
    isAdded: false,
    alreadyInTheList: false
  };
  return Object.assign({}, initialValues);
}

class CourseHourForm extends Component<Types.ICourseHoursFormProps, Types.ICourseFormState> {
  state: Types.ICourseFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
    this.getProgramsByFacultiesAtCourses([]);
    if (faculty_ids_labels.length == 0 || faculty_ids_labels == [] || faculty_ids_labels == undefined) {
      this.putToTable(this.createFieldValues(), false);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    faculty_ids_labels.splice(0, faculty_ids_labels.length);
    faculties_labels.splice(0, faculties_labels.length);
    program_ids_labels.splice(0, program_ids_labels.length);
    programs_labels.splice(0, programs_labels.length);
    class_ids_labels.splice(0, class_ids_labels.length);
    classes_labels.splice(0, classes_labels.length);
    sections_labels.splice(0, sections_labels.length);
    lecture_staff_ids_labels.splice(0, lecture_staff_ids_labels.length);
    lecture_staff_labels.splice(0, lecture_staff_labels.length);
    student_count_labels.splice(0, student_count_labels.length);

    faculty_ids_selected.splice(0, faculty_ids_selected.length);
    program_ids_selected.splice(0, program_ids_selected.length);
    class_ids_selected.splice(0, class_ids_selected.length);
    lecture_staff_ids_selected.splice(0, lecture_staff_ids_selected.length);
    faculties_selected.splice(0, faculties_selected.length);
    programs_selected.splice(0, programs_selected.length);
    classes_selected.splice(0, classes_selected.length);
    sections_selected.splice(0, sections_selected.length);
    lecture_staff_selected.splice(0, lecture_staff_selected.length);
  }

  getProgramsByFacultiesAtCourses = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'course-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    this.setState(this.state);
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.ICourseItem, FormActions: FormikActions<Types.ICourseItem>) => {
    if (saveButtonClicked) {

      const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
        if (result && result.code) {
          let errors: any = {};
          if (result.details) {
            const validations: Array<Types.IValidationResponse> = result.details;
            validations.forEach((m: Types.IValidationResponse) => {
              errors[m.field] = m.message[0];
            });
          }
          FormActions.setErrors(errors);
        }
        if (status === 200 || status === 201) {
          this.setClose(true);
        }
      };

      let course_programs: any;
      course_programs = faculty_ids_selected.map((faculty_id: any, index: any) => (
        {
          faculty_id: faculty_id,
          campus_id: model.campus_id,
          program_id: program_ids_selected[index],
          class: class_ids_selected[index],
          section: sections_selected[index],
          instructor_id: lecture_staff_ids_selected[index],
          student_count: student_count_labels[index]
        }));

      if (course_programs == undefined || course_programs == [] || course_programs.length == 0) {
        this.props.dispatch(Actions.Notification(T.t("gen_list_isnot_left_blank"), 'gen_warning', 'danger'))
      } else {
        this.props.course.courseInfo.assistant_staff = model.assistant_staff;
        this.props.course.courseInfo.assistant_staff_ids = model.assistant_staff_ids;
        this.props.course.courseInfo.campus_ids = Array(faculty_ids_selected.length).fill(model.campus_id);
        this.props.course.courseInfo.exam_overlap_type = model.exam_overlap_type;
        this.props.course.courseInfo.exam_environment = model.exam_environment;
        this.props.course.courseInfo.exam_type = model.exam_type;
        this.props.course.courseInfo.classroom_combine_status = model.classroom_combine_status;
        this.props.course.courseInfo.conjugate_course_code = model.conjugate_course_code;
        this.props.course.courseInfo.course_code = model.course_code;
        this.props.course.courseInfo.course_id = model.course_id;
        this.props.course.courseInfo.description = model.description;
        this.props.course.courseInfo.exam_duration = model.exam_duration;
        this.props.course.courseInfo.exam_session = model.exam_session;
        this.props.course.courseInfo.invigilator_gap_after_exam = model.invigilator_gap_after_exam;
        this.props.course.courseInfo.invigilator_gap_before_exam = model.invigilator_gap_before_exam;
        this.props.course.courseInfo.lesson_count = model.lesson_count;
        this.props.course.courseInfo.name = model.name;
        this.props.course.courseInfo.student_count = model.student_count;
        this.props.course.courseInfo.term_id = model.term_id;
        this.props.course.courseInfo.status = model.status;
        this.props.course.courseInfo.course_programs = course_programs;

        const courseHoursModel = this.props.course;
        const courseInfoModel = this.props.course.courseInfo;

        if (courseHoursModel) {
          courseHoursModel.courseInfo = courseInfoModel;
        }

        this.props.dispatch(
          Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_EDIT_COURSE, courseHoursModel, 'course-form-spin', resultCallback)
        );

        FormActions.setSubmitting(false);
        saveButtonClicked = false;
      }
    }
  };

  putToTable = (values: any, plusButtonClicked: boolean) => {
    faculty_ids_labels.length == 0 && plusButtonClicked == false ? faculty_ids_labels = values.faculty_ids : faculty_ids_labels = faculty_ids_labels;
    faculty_ids_labels = values.faculty_id != undefined && plusButtonClicked ? faculty_ids_labels.concat(values.faculty_id) : faculty_ids_labels;
    faculties_labels = values.faculty_id != undefined && plusButtonClicked ? faculties_labels.concat(values.faculties) : values.faculties;

    program_ids_labels.length == 0 && plusButtonClicked == false ? program_ids_labels = values.program_ids : program_ids_labels = program_ids_labels;
    program_ids_labels = values.program_id != undefined && plusButtonClicked ? program_ids_labels.concat(values.program_id) : program_ids_labels;
    programs_labels = values.program_id != undefined && plusButtonClicked ? programs_labels.concat(values.programs) : values.programs;

    lecture_staff_ids_labels.length == 0 && plusButtonClicked == false ? lecture_staff_ids_labels = values.instructor_ids : lecture_staff_ids_labels = lecture_staff_ids_labels;
    lecture_staff_ids_labels = values.instructor_id != undefined && plusButtonClicked ? lecture_staff_ids_labels.concat(values.instructor_id) : lecture_staff_ids_labels;
    lecture_staff_labels = values.instructor_id != undefined && plusButtonClicked ? lecture_staff_labels.concat(values.instructors) : values.instructors;

    classes_labels = classes_labels.concat(values.classes);

    if (values.classes.length == 1 && class_ids_labels.length == 0) {
      class_ids_labels = class_ids_labels.concat(values.classes.value);
    } else if (class_ids_labels.length == 0 && values.classes.length > 1) {
      class_ids_labels = values.classes && values.classes.map((item: any) => item.value);
    } else if (class_ids_labels.length == 0 && values.classes.length == 1) {
      class_ids_labels = values.classes && values.classes.value;
    }

    if (values.class_id != undefined && plusButtonClicked) {
      class_ids_labels = class_ids_labels.concat(values.class_id);
    } else {
      class_ids_labels = class_ids_labels;
    }

    if (sections_labels.length == 0 && plusButtonClicked == false) {
      sections_labels = values.sections;
    }

    if (values.section != undefined && values.section != '' && plusButtonClicked) {
      sections_labels = sections_labels.concat(values.section);
    } else if ((values.section == undefined || values.section == '') && plusButtonClicked) {
      sections_labels = sections_labels.concat('1');
      values.section = '1'
    }

    student_count_labels.length == 0 && plusButtonClicked == false ? student_count_labels = values.student_counts : student_count_labels = student_count_labels;
    student_count_labels = values.student_count != undefined && plusButtonClicked ? student_count_labels.concat(values.student_count) : student_count_labels;

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    faculty_ids: any, program_ids: any, class_ids: any, lecture_staff_ids: any,
    faculties: any, programs: any, classes: any, sections: any, lecture_staff: any, student_counts: any
  ) {
    multiSelectedRow = true;
    faculty_ids_selected = faculty_ids;
    program_ids_selected = program_ids;
    class_ids_selected = class_ids;
    lecture_staff_ids_selected = lecture_staff_ids;

    faculties_selected = faculties;
    programs_selected = programs;
    classes_selected = classes;
    sections_selected = sections;
    lecture_staff_selected = lecture_staff;

    faculty_ids_labels = faculty_ids;
    faculties_labels = faculties;
    program_ids_labels = program_ids;
    programs_labels = programs;
    class_ids_labels = class_ids;
    classes_labels = classes;
    sections_labels = sections;
    lecture_staff_ids_labels = lecture_staff_ids;
    lecture_staff_labels = lecture_staff;
    student_count_labels = student_counts;
  }

  createFieldValues = (): Types.ICourseItem => {
    let fields: Types.ICourseItem = {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_counts: [],
      instructor_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      exam_duration: undefined,
      class: undefined,
      section: '',
      sections: [],
      exam_session: undefined,
      description: '',
      instructor_id: 0,
      class_id: 0,
      program_id: 0,
      faculty_id: 0,
    };
    let courseInfo = this.props.course.courseInfo;
    fields = {
      course_code: courseInfo && courseInfo.course_code ? courseInfo.course_code : '',
      conjugate_course_code: courseInfo && courseInfo.conjugate_course_code ? courseInfo.conjugate_course_code : '',
      name: courseInfo && courseInfo.name ? courseInfo.name : '',
      campus: courseInfo && courseInfo.campuses ? courseInfo.campuses[0] : undefined,
      campus_id: courseInfo && courseInfo.campus_ids ? courseInfo.campus_ids[0] : undefined,
      faculties: courseInfo && courseInfo.faculties ? courseInfo.faculties : [],
      faculty_ids: courseInfo && courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
      student_counts: courseInfo && courseInfo.student_counts ? courseInfo.student_counts : [],
      invigilator_gap_before_exam: courseInfo && courseInfo.invigilator_gap_before_exam ? courseInfo.invigilator_gap_before_exam : 0,
      invigilator_gap_after_exam: courseInfo && courseInfo.invigilator_gap_after_exam ? courseInfo.invigilator_gap_after_exam : 0,
      instructors: courseInfo && courseInfo.instructors ? courseInfo.instructors : [],
      instructor_ids: courseInfo && courseInfo.instructor_ids ? courseInfo.instructor_ids : [],
      assistant_staff: courseInfo && courseInfo.assistant_staff ? courseInfo.assistant_staff : [],
      assistant_staff_ids: courseInfo && courseInfo.assistant_staff_ids ? courseInfo.assistant_staff_ids : [],
      programs: courseInfo && courseInfo.programs ? courseInfo.programs : [],
      program_ids: courseInfo && courseInfo.program_ids ? courseInfo.program_ids : [],
      class: courseInfo && courseInfo.class ? courseInfo.class : undefined,
      classes: courseInfo && courseInfo.classes ? courseInfo.classes : [],
      sections: courseInfo && courseInfo.sections ? courseInfo.sections : undefined,
      section: courseInfo && courseInfo.section ? courseInfo.section : '',
      exam_duration: courseInfo && courseInfo.exam_duration ? courseInfo.exam_duration : undefined,
      exam_session: courseInfo && courseInfo.exam_session ? courseInfo.exam_session : undefined,
      classroom_combine_status: courseInfo && courseInfo.classroom_combine_status,
      description: courseInfo && courseInfo.description ? courseInfo.description : undefined,
    };
    return fields;
  };

  static getDerivedStateFromProps(props: Types.ICourseHoursFormProps, state: Types.ICourseFormState) {
    let hasNewState: boolean = false;

    if (props.course && props.course.course_hour_id) {
      state.model = props.course.courseInfo;
      state.model.campus = props.course.courseInfo && props.course.courseInfo.campuses ? props.course.courseInfo.campuses[0] : undefined;
      state.model.campus_id = props.course.courseInfo && props.course.courseInfo.campus_ids ? props.course.courseInfo.campus_ids[0] : undefined;
      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  render() {
    const formInitialValues = this.createFieldValues();
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    if (faculty_ids_labels == undefined) {
      faculty_ids_labels = new Array<any>();
      faculties_labels = new Array<any>();
      program_ids_labels = new Array<any>();
      programs_labels = new Array<any>();
      class_ids_labels = new Array<any>();
      classes_labels = new Array<any>();
      sections_labels = new Array<any>();
      lecture_staff_ids_labels = new Array<any>();
      lecture_staff_labels = new Array<any>();
      student_count_labels = new Array<any>();
    }

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />

        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={CourseFormValidation(T)}
        >
          {(props: FormikProps<Types.ICourseItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;

            let table_values = faculty_ids_labels && faculty_ids_labels.map((faculty_id_label: any, index: any) => (
              {
                values:
                  faculty_ids_labels![index] + ',' +
                  program_ids_labels![index] + ',' +
                  class_ids_labels![index] + ',' +
                  sections_labels![index] + ',' +
                  lecture_staff_ids_labels![index] + ',' +
                  student_count_labels![index]
              }))

            let current_form_values = { values: values.faculty_id + ',' + values.program_id + ',' + values.class_id + ',' + values.section + ',' + values.instructor_id + ',' + values.student_count }

            if (table_values!.some(e => e.values == current_form_values.values)) {
              this.state.alreadyInTheList = true;
              this.state.isAdded = true;
            } else if (this.state.isAdded) {
              this.state.alreadyInTheList = false;
              this.state.isAdded = false;
            } else {
              this.state.alreadyInTheList = false;
              this.state.isAdded = false;
            }

            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{T.t("gen_update_course")}</span>
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="course_code"
                              name="course_code"
                              value={values.course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="course_code">{T.t('gen_code')}</label>
                            {errors && errors.course_code && (
                              <div className="error">{T.t(errors && errors.course_code)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="conjugate_course_code"
                              name="conjugate_course_code"
                              value={values.conjugate_course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.name && (
                              <div className="error">{T.t(errors && errors.name)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="exam_duration"
                              name="exam_duration"
                              value={values.exam_duration}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="exam_duration">{T.t('gen_exam_duration')} ({T.t('gen_slot_count')})</label>
                            {errors && errors.exam_duration && (
                              <div className="error">{T.t(errors && errors.exam_duration)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="invigilator_gap_before_exam"
                              name="invigilator_gap_before_exam"
                              value={values.invigilator_gap_before_exam}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')} ({T.t('gen_slot_count')})</label>
                            {errors && errors.invigilator_gap_before_exam && (
                              <div className="error">{T.t(errors && errors.invigilator_gap_before_exam)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="invigilator_gap_after_exam"
                              name="invigilator_gap_after_exam"
                              value={values.invigilator_gap_after_exam}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')} ({T.t('gen_slot_count')})</label>
                            {errors && errors.invigilator_gap_after_exam && (
                              <div className="error">{T.t(errors && errors.invigilator_gap_after_exam)}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="exam_session"
                              name="exam_session"
                              value={values.exam_session}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                            {errors && errors.exam_session && (
                              <div className="error">{T.t(errors && errors.exam_session)}</div>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_type')}</label>
                                <Select
                                  id='select_exam_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ExamTypes(T)}
                                  placeholder={T.t('gen_select_exam_type')}
                                  value={
                                    values.exam_type != undefined
                                      ? ExamTypes(T).find(
                                        (option) => option.value === values.exam_type
                                      )
                                      : null
                                  }
                                  onChange={(option: any) =>
                                    props.setFieldValue('exam_type', option.value)
                                  }
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_environment')}</label>
                                <Select
                                  id='select_exam_environmet'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ExamEnvironments(T)}
                                  placeholder={T.t('gen_select_exam_environment')}
                                  value={
                                    values.exam_environment != undefined
                                      ? ExamEnvironments(T).find(
                                        (option) => option.value === values.exam_environment
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('exam_environment', option.value)
                                    props.setFieldValue('exam_environment_select', option);
                                    if (option.value == ExamEnvironments(T)[1].value) {
                                      values.campus_id = ExamPeriodCourseTermLectureLocations(T)[0].value;
                                      values.campus = ExamPeriodCourseTermLectureLocations(T)[0];
                                    } else {
                                      if (values.campus_id == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                        values.campus_id = undefined;
                                        values.campus = undefined;
                                      }
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_classroom_combine_status')}</label>
                                <Select
                                  id='select_status'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ClassroomCombineTypes(T)}
                                  placeholder={T.t('gen_select_status')}
                                  value={
                                    values.classroom_combine_status != undefined
                                      ? ClassroomCombineTypes(T).find(
                                        (option) => option.value === values.classroom_combine_status
                                      )
                                      : null
                                  }
                                  onChange={(option: any) =>
                                    props.setFieldValue('classroom_combine_status', option.value)
                                  }
                                  noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                />
                              </div>
                              {errors && errors.classroom_combine_status && (
                                <div className="error">{T.t(errors && errors.classroom_combine_status)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_assistants')}</label>
                                <Select
                                  id='select_assistant'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.instructors
                                      ? this.props.selectOptions.instructors
                                      : []
                                  }
                                  placeholder={T.t('gen_select_assistant')}
                                  value={props.values.assistant_staff}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('assistant_staff', list);
                                    props.setFieldValue(
                                      'assistant_staff_ids',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_campus')}</label>
                                <Select
                                  id='select_campus'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={CampusSelectOptions}
                                  placeholder={T.t('gen_select_location')}
                                  value={props.values.campus ? props.values.campus : null}
                                  onChange={(option: any) => {
                                    props.setFieldValue('campus', option);
                                    props.setFieldValue('campus_id', option && option.value);
                                    if (option.value == ExamPeriodCourseTermLectureLocations(T)[0].value) {
                                      values.exam_environment = ExamEnvironments(T)[1].value;
                                      values.exam_environment_select = ExamEnvironments(T)[1];
                                    } else {
                                      if (values.exam_environment == ExamEnvironments(T)[1].value) {
                                        values.exam_environment = undefined;
                                        values.exam_environment_select = undefined;
                                      }
                                    }
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                              {errors && errors.campus_id && (
                                <div className="error">{T.t(errors && errors.campus_id)}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_exam_overlap_type')}</label>
                                <Select
                                  id='select_exam_overlap_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={ExamOverlapTypes(T)}
                                  placeholder={T.t('gen_select_exam_overlap_type')}
                                  value={
                                    values.exam_overlap_type != undefined
                                      ? ExamOverlapTypes(T).find(
                                        (option) => option.value === values.exam_overlap_type
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('exam_overlap_type', option.value)
                                    props.setFieldValue('exam_overlap_type_select', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_faculty_college_institute')}</label>
                                <Select
                                  id='select_faculty'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.faculties
                                      ? this.props.selectOptions.faculties
                                      : []
                                  }
                                  placeholder={T.t('gen_select_faculty_college_institute')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('faculty_id', option && option.value);
                                    props.setFieldValue('faculties', option);
                                    this.getProgramsByFacultiesAtCourses([Number(option && option.value)])
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_program_department')}</label>
                                <Select
                                  id='select_program'
                                  className="react-select"
                                  isDisabled={values.faculty_id == undefined || values.faculty_id == 0 ? true : false}
                                  isMulti={false}
                                  isClearable={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.programs_related_faculty}
                                  placeholder={T.t('gen_select_program_department')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('program_id', option && option.value);
                                    props.setFieldValue('programs', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_grade')}</label>
                                <Select
                                  id='select_grade'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  isDisabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined ? true : false}
                                  closeMenuOnSelect={true}
                                  options={Grades}
                                  placeholder={T.t('gen_select_grade')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('class_id', option && option.value);
                                    props.setFieldValue('classes', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                />
                              </div>
                              {errors && errors.class_ids && props.submitCount > 0 && (
                                <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon mt-3">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <input
                                  id="section"
                                  name="section"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == 0 || values.class_id == undefined ? true : false}
                                  type="text"
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="gen_section">{T.t('gen_section')}</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_course_instructors')}</label>
                                <Select
                                  id='select_instructor'
                                  className="react-select"
                                  isMulti={false}
                                  isClearable={true}
                                  isDisabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == undefined ? true : false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.instructors
                                      ? this.props.selectOptions.instructors
                                      : []
                                  }
                                  placeholder={T.t('gen_select_instructor')}
                                  onChange={(option: any) => {
                                    props.setFieldValue('instructor_id', option && option.value);
                                    props.setFieldValue('instructors', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                />
                              </div>
                              {errors && errors.instructor_ids && props.submitCount > 0 && (
                                <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <input
                              id="student_count"
                              name="student_count"
                              value={values.student_count}
                              disabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == undefined || values.instructor_id == 0 || values.instructor_id == undefined ? true : false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="student_count">{T.t('gen_number_students_will_take_exam')}</label>
                            {(values.instructor_id != 0 && values.instructor_id != undefined && values.student_count != undefined && values.student_count <= 0) ? (
                              <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                            ) : null}
                          </div>
                          <div className="col-md text-md-right mb-3">
                            <button
                              id='button_add_circle_outline'
                              type="button"
                              disabled={
                                ((values.student_count != undefined && values.student_count != 0) ? false : true)
                                || (this.state.alreadyInTheList ? true : false)
                              }
                              data-dismiss="modal"
                              onClick={
                                this.state.alreadyInTheList ?
                                  () => { } :
                                  () => { this.putToTable(values, true) }
                              }
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">add_circle_outline</i>
                            </button>
                          </div>
                          <div className="col-md-12 list-error">
                            {
                              /*this.props.course.courseInfo.course_programs == undefined || this.props.course.courseInfo.course_programs == [] ?
                                <span>Liste boş bırakılamaz. Lütfen alanları eksiksiz doldurun.</span>
                                : null*/
                            }
                          </div>
                          <MultiSelectionTable
                            faculty_ids_labels={faculty_ids_labels}
                            faculties_labels={faculties_labels}
                            program_ids_labels={program_ids_labels}
                            programs_labels={programs_labels}
                            class_ids_labels={class_ids_labels}
                            classes_labels={classes_labels}
                            sections_labels={sections_labels}
                            lecture_staff_ids_labels={lecture_staff_ids_labels}
                            lecture_staff_labels={lecture_staff_labels}
                            student_count_labels={student_count_labels}
                            multiSelectionObjectFunction={this.multiSelecteds}
                          />
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={() => {
                                props.handleSubmit()
                                saveButtonClicked = true
                              }}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICourseHoursFormProps
): Types.ICourseHoursFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICourseHoursFormProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseHourForm);

export default container;