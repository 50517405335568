import React from 'react';
import { Log } from 'ng2-logger';
import { IPageProps } from '../../store/types';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import imgLogo from '../../assets_aplan/images/main-logo.svg';
import APlanLogo from '../../assets_aplan/images/APLAN-LOGO.png';
import iconMenu from '../../assets/images/hamburger.svg';
import iconUser from '../../assets/images/sample-user-image.png';
import * as Actions from '../../store/actions/general';

import * as Constants from '../../store/constants/all';
import SyncJobModal from './sync-job-modal';
import * as Types from '../../store/types';

import { MdLanguage, MdPersonOutline, MdMenu, MdWarning } from 'react-icons/md';
import Translator from '../../services/translate-factory';
import { Link } from 'react-router-dom';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  UncontrolledCollapse
} from 'reactstrap';
import AppFactory from '../../services/app-factory';
import { connect } from 'react-redux';
import { ShowModal } from '../../store/actions/general';
import TermSelector from '../term/term-selector';

const T = Translator.create();
const AF = AppFactory.create();
const L = Log.create('App');

class Header extends React.Component<IPageProps, any> {
  state = {
    userMenuIsOpen: false,
    syncModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  userMenuToggle = () => {
    this.state.userMenuIsOpen = !this.state.userMenuIsOpen;
    this.forceUpdate();
  };

  onLogOut = () => {
    AF.logOff();
  };

  switchSyncModalStatus = () => {
    this.setState({
      ...this.state,
      syncModalIsOpen: !this.state.syncModalIsOpen
    });
  };

  render() {
    const languages: Array<Types.ISelectOption> = GT.Languages().map((m) => {
      return { label: T.t(m.label), value: m.value };
    });
    return (
      <header>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-1 col-md-3">
              <Link to={GT.Route(Routes.DASHBOARD)}>
                <img src={APlanLogo} alt="APlan" style={{ width: '100px', height: 'auto' }} />
              </Link>
              <button
                className="btn menu-button d-block d-md-none"
                type="button"
                data-toggle="collapse"
                data-target="#mNav"
              >
                Menu <img src={iconMenu} alt="Menu" className="ml-2" />
              </button>
            </div>
            <div className="col-lg-11 col-md-9">
              <div
                className="d-md-flex justify-content-md-between align-items-md-center mobile-menu collapse"
                id="mNav"
              >
                <button
                  type="button"
                  className="d-md-none close"
                  data-toggle="collapse"
                  data-target="#mNav"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                  <span>CLOSE</span>
                </button>
                <ul className="menu">
                  {this.props.user && this.props.user.role === 's' ? (
                    <li>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {T.t('gen_synchronization')}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a onClick={this.switchSyncModalStatus} className="dropdown-item">
                          <i className="material-icons mr-2">sync</i>
                          {T.t('gen_new_sync')}
                        </a>
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {T.t('gen_existing_sync_jobs')}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                          <Link to={GT.Route(Routes.SYNC_CAMPUS)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_campuses')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_BUILDING)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_buildings')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_CLASSROOM)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_classrooms')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_FACULTY)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_faculties')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_PROGRAM)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_programs')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_INSTRUCTOR)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_instructors')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_COURSE)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_courses')}
                          </Link>
                          {
                            this.props.term_type === 1 ?
                              <Link to={GT.Route(Routes.SYNC_STUDENT)} className="dropdown-item">
                                <i className="material-icons mr-2">sync</i>{T.t('gen_students')}
                              </Link>
                              : null
                          }
                        </div>
                      </div>
                    </li>
                  ) : null}
                  <li>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {T.t('gen_terms')}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link to={GT.Route(Routes.COURSE_PERIODS)} className="dropdown-item">
                        <i className="material-icons mr-2">event_note</i>
                        {T.t('gen_course_terms')}
                      </Link>
                      <Link to={GT.Route(Routes.EXAM_PERIODS)} className="dropdown-item">
                        <i className="material-icons mr-2">event_note</i>
                        {T.t('gen_exam_terms')}
                      </Link>
                    </div>
                  </li>
                  {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                    <li>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {T.t('gen_definitions')}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                          <Link to={GT.Route(Routes.CAMPUS)} className="dropdown-item">
                            <i className="material-icons mr-2">account_balance</i>
                            {T.t('gen_campuses')}
                          </Link>
                        ) : null}
                        {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                          <Link to={GT.Route(Routes.BUILDING)} className="dropdown-item">
                            <i className="material-icons mr-2">domain</i>{T.t('gen_buildings')}
                          </Link>
                        ) : null}
                        {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                          <Link to={GT.Route(Routes.CLASSROOM)} className="dropdown-item">
                            <i className="material-icons mr-2">class</i>
                            {T.t('gen_classrooms')}
                          </Link>
                        ) : null}
                        {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                          <Link to={GT.Route(Routes.FACULTY)} className="dropdown-item">
                            <i className="material-icons mr-2">location_city</i>
                            {T.t('gen_faculties')}
                          </Link>
                        ) : null}
                        {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                          <Link to={GT.Route(Routes.PROGRAM)} className="dropdown-item">
                            <i className="material-icons mr-2">assignment</i>
                            {T.t('gen_programs')}
                          </Link>
                        ) : null}
                        <Link to={GT.Route(Routes.INSTRUCTOR)} className="dropdown-item">
                          <i className="material-icons mr-2">person</i>
                          {T.t('gen_instructors')}
                        </Link>
                        <Link to={GT.Route(Routes.COURSE)} className="dropdown-item">
                          <i className="material-icons mr-2">school</i>{T.t('gen_courses')}
                        </Link>
                        {this.props.term_type === 0 ? (
                          <Link to={GT.Route(Routes.ACTIVITY_RELATIONS)} className="dropdown-item">
                            <i className="material-icons mr-2">account_tree</i>{T.t('gen_activity_relations')}
                          </Link>
                        ) : null}
                      </div>
                    </li>
                  ) : null}
                  <li>
                    <Link
                      to={GT.Route(
                        this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
                        '/calendar/' + this.props.term_id
                      )}
                    >
                      {T.t('gen_planning')}
                    </Link>
                  </li>
                  {this.props.user && this.props.user.role === 's' ? (
                    <li>
                      <Link to={GT.Route(Routes.USERS)}>{T.t('gen_users')}</Link>
                    </li>
                  ) : null}
                  <li>
                    <Link to={GT.Route(Routes.SOLUTION, '/solution/' + this.props.term_id)}>{T.t('gen_solution')}</Link>
                  </li>
                  <li>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {T.t('gen_help')}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link to={GT.Route(Routes.HELP_GUIDE)} className="dropdown-item">
                        <i className="material-icons mr-2">help</i>
                        {T.t('gen_help_guide')}
                      </Link>
                      <Link to={GT.Route(Routes.REPORT)} className="dropdown-item">
                        <i className="material-icons mr-2">report_problem</i>
                        {T.t('gen_report_issue')}
                      </Link>
                    </div>
                  </li>
                  {this.props.user && this.props.user.role === 's' ? (
                    <li>
                      <Link to={GT.Route(Routes.SETTINGS)}>{T.t('gen_settings')}</Link>
                    </li>
                  ) : null}
                </ul>
                <TermSelector onLogOut={this.onLogOut} />
                <div className="header-item menu-lang"></div>
                <div className="header-options">
                  <UncontrolledButtonDropdown title={T.t('gen_header_change_lang')}>
                    <DropdownToggle className="" color="transparent" tag="div">
                      {/* <MdLanguage color="white" size="24" />
                       */}
                      <i className="material-icons" style={{ fontSize: 'xx-large' }}>
                        language
                      </i>
                    </DropdownToggle>
                    <DropdownMenu>
                      {languages.map((m, i) => {
                        return (
                          <DropdownItem key={'lang-' + i} onClick={() => T.changeLanguage(m.value, true)}>
                            {m.label}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <ButtonDropdown isOpen={this.state.userMenuIsOpen} toggle={this.userMenuToggle}>
                    <DropdownToggle className="" color="transparent" tag="div">
                      <div className="plus-dropdown">
                        <i className="material-icons" style={{ fontSize: 'xx-large' }}>
                          account_circle
                        </i>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link title="Ayarlar" to={GT.Route(Routes.USER_SETTINGS)}>
                        <DropdownItem>{T.t('gen_settings')}</DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <DropdownItem onClick={this.onLogOut}>{T.t('gen_sign_out')}</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.syncModalIsOpen ? (
          <SyncJobModal
            modalIsOpen={this.state.syncModalIsOpen}
            onClose={this.switchSyncModalStatus}
          />
        ) : null}
      </header>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(Header);

export default container;
