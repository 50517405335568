import * as Types from '../types';
import { Navigation } from '../../components/renderless/navigation';
import * as Consts from '../constants/all';

import { Log } from 'ng2-logger';
const L = Log.create('NavigationMiddleware');
const NavigationMiddleware = (store: any) => (next: any) => (action: Types.IAction) => {
  let result = next(action);
  if (action.type === Consts.gen.CORE_NAVIGATION) {
    const navigator = Navigation.getInstance();
    if (navigator) {
      navigator.setNavigate(<Types.INavigationAction>action);
    }
  }
  return result;
};

export default NavigationMiddleware;
