import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log, Logger } from 'ng2-logger';
import { Modal } from 'reactstrap';
import Paginate from '../../../components/table/paginate';
import SortedColumn from '../../../components/table/sorted-column';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import {
  ActivitySearchInitialValues,
  ActivityHoursSearchInitialValues,
  ActivityTermLectureLocations,
  EducationTypeSelectOptions,
  ActivityTypesLabel,
  CourseTypesLabel
} from '../../../store/constants/activity-const';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import Translator from '../../../services/translate-factory';

const T = Translator.create();
const L = Log.create('ActivityDeleteModal');

function getInitialState(): Types.IActivityPageState {
  const initialValues: Types.IActivityPageState = {
    filters: Object.assign({}, ActivityHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    activityFormIsOpen: false,
    activityNo: undefined,
    package_name: '',
    description: ''
  };
  return Object.assign({}, initialValues);
}

class ActivityDeleteModal extends Component<Types.IActivityPageProps, Types.IActivityPageState> {
  state = getInitialState();

  componentDidMount() {}

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidUpdate() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    if (id && !this.state.filters.term_id) {
      this.state.filters.term_id = id;
      this.setState(this.state);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  removeActivitiesFromRelation = () => {
    const model = {
      activity_relation_id: this.props.selectedActivityNo,
      term_id: this.props.term_id,
      related_activity_ids: this.state.selected_ids ? GT.removeElementsFromArray(this.props.selectedActivityRelation.related_activity_ids, this.state.selected_ids) : [],
      activity_relation_type: this.props.activityRelationType,
    };

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_activity_remove_from_relation'),
        body: T.t('gen_selected_activities_relation_delete_question'),
        name: 'activity_relations',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              if(model.related_activity_ids.length == 0){
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.course_period.DELETE_ACTIVITY_RELATION,
                    model.activity_relation_id,
                    'activity-relation-delete-spinner',
                  )
                );
              }
              this.setState({
                ...this.state,
                selected_ids: []
              });
              this.setClose();
              this.onUpdateListFromModal();
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(
              Constants.course_period.ACTIVITY_DELETE_FROM_ACTIVITY_RELATION,
              model,
              'activity-relations-spinner',
              resultCallback
            )
          );
        }
      })
    );
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
  };

  static getDerivedStateFromProps(props: Types.IActivityPageProps, state: Types.IActivityPageState) {
    let hasNewState: boolean = false;

    if (props.modalIsOpen && !state.activityFormIsOpen) {
      hasNewState = true;
      props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, state.filters, 'activity-list-spin'));
      state.activityFormIsOpen = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let activityList = this.props.selectedActivityRelation && this.props.selectedActivityRelation.related_activities;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions];
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_activity_remove_from_relation')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square tag-red float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.removeActivitiesFromRelation}
                        >
                          <i className="material-icons mr-2">delete</i>
                          <span>{T.t('gen_delete_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">delete</i>
                          <span>{T.t('gen_delete_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th ></th>
                        <SortedColumn
                          datacell="status"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_no"
                          title={T.t('gen_activity_no')}
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          sortkey="activity_no"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_code')}
                          sortkey="course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_activity')}
                          sortkey="activity_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lesson_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_count')}
                          sortkey="lesson_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_location"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_location')}
                          sortkey="lecture_location"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="education_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_education_type')}
                          sortkey="education_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="faculty"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_faculty')}
                          sortkey="faculty"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="program"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_connected_programs')}
                          sortkey="program"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="class"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_grade')}
                          sortkey="class"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="section"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_section')}
                          sortkey="section"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="instructor"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_instructors')}
                          sortkey="instructor"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="student_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_count')}
                          sortkey="student_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_week')}
                        </th>
                        <SortedColumn
                          datacell="course_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_type')}
                          sortkey="course_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityList && activityList.length
                        ? activityList.map((item: any) => {
                          const courseLocation = allSelectOptions.find(
                            (option) => option.value == item.lecture_location_id
                          );
                          return (
                            <tr key={'activity-' + item.activity_no} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.activity_no ? item.activity_no : -1) > -1
                                    }
                                    data-id={item.activity_no}
                                    onChange={this.onSelectActivity}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_activity_no')} className="text-center">{item.activity_no}</td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{item.course_code ? item.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{item.course_name ? item.course_name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td data-label={T.t('gen_activity')} className="text-center">{ActivityTypesLabel(item.activity_type, T)}</td>
                              <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{item.lesson_count}</td>
                              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                {item.lecture_location && item.lecture_location.label}
                              </td>
                              <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                  EducationTypeSelectOptions(T)[0].label :
                                  item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                    EducationTypeSelectOptions(T)[1].label :
                                    item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                      EducationTypeSelectOptions(T)[2].label : item.education_type
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.faculties && item.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{item.faculties && item.faculties.map((i: any, index: any) => (index == item!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.programs && item.programs.length > 0 ?
                                    <div className="table-scrollable-td">{item.programs && item.programs.map((i: any, index: any) => (index == item!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.classes && item.classes.length > 0 ?
                                    <div className="table-scrollable-td">{item.classes && item.classes.map((i: any, index: any) => (index == item!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.sections && item.sections.length > 0 ?
                                    <div className="table-scrollable-td">{item.sections && item.sections.map((i: any, index: any) => (index == item!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.instructors && item.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{item.instructors && item.instructors.map((i: any, index: any) => (index == item!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {item.student_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                <div className="table-scrollable-td">{item.week ? item.week.split(',').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                                {CourseTypesLabel(item.course_type, T)}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item && item.description ? item.description : '-'}
                              </td>
                            </tr>
                          )
                        })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.activity_page && store.state.activity_page.filters,
    all_ids: store.state.activity_page && store.state.activity_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.all_ids,
        next.state.activity_page && next.state.activity_page.all_ids
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.coursePage,
        next.state.select_options && next.state.select_options.coursePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ActivityDeleteModal);

export default container;
